<head>
<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
</head>
<template>
  <!--一開始進入畫面 無物流-->
  <div class="payment grey-bg g-b" v-loading="mainLoading">
    <!--白色區域 -->
    <el-form ref="dataForm" :model="dataForm" :rules="rules" label-position="top">
      <div class="container box w-b" style="border-bottom-left-radius:0;border-bottom-right-radius:0;">
        <div class="top-logo">
          <el-image style="width: 80%; max-width:400px;  height: auto;" :src="logoImgUrl"></el-image>
        </div>
        <!-- 訂單資訊 -->
        <div class="or-information">
          <div style="display: flex;">
            <div class="title-24">訂單資訊</div>
            <div class="title-16">Order Information</div>
          </div>
          <el-image class="bk-img" :src="rectangleOiUrl"></el-image>

          <div v-if="slideImgList.length>0" class="slide-box">
            <imgSlide :dataList="slideImgList" ref="slideImg"></imgSlide>
          </div>

          <div style="position: relative; height: 320px;">
            <!-- 商品背景圖 -->
            <el-image :src="commodityBgImgUrl" style="height: 100%; width: 100%;"></el-image>
            <!-- 商品文字敘述區 -->
            <div class="order-txt">
              <div class="order-txt-center">
                <div class="mer-name-l"><span class="order-amount-r">商店名稱</span> Merchant Name：</div>
                <div class="mer-name-r" v-if="payOrderInfo.merchantDataDto.merchantName">{{ payOrderInfo.merchantDataDto.merchantName }}</div>
                <div class="order-name">{{ payOrderInfo.masterAndResourceDto.goodsName }}</div>
                <div class="mer-name-l" style="margin-top: 10px; width: 100%"><span class="order-amount-r">商品介紹</span> Product Info</div>
                <div class="mer-name-l goods-desc" style="width: 80%; margin-top: 10px; margin-bottom: 10px;" v-html="payOrderInfo.masterAndResourceDto.goodsDesc"></div>
                <el-row class="order-amount-l"><span class="order-amount-r">交易金額 </span>Payment Amount</el-row>
                <!-- 金額輸入區 -->
                <el-row class="pay-amount">NT $
                  <!-- 固定金額區 -->
                  <span v-format="'#,##0'" v-if="tradeAmountFlg != '0'">{{ payOrderInfo.merchantOrderDataDto.tradeAmount }}</span>
                  <!-- 自訂金額區 -->
                  <el-form-item
                      v-else
                      class="priceInput"
                      style="width: 200px;"
                      label=""
                      prop="transactionAmount"
                      :rules="[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }]">
                    <div style="display: flex;">
                      <!-- 金額輸入 -->
                      <el-input
                          v-model="dataForm.transactionAmount"
                          type="text"
                          @input="changeTradeAmount($event,'transactionAmount') , checkAmount()"
                          size="small"
                      />
                    </div>
                    <span slot="error">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">{{ transactionAmountMsg }}</span>
                          </div>
                        </span>
                  </el-form-item>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <!-- 輸入金額後才會顯示的部分 -->
        <transition name="fade">
          <div v-if="paymentModeShow || tradeAmountFlg=='1'">
            <!-- 付款方式 -->
            <div v-if="payForm.payMethods =='DOMESTIC_CREDIT_CARD'||(payForm.payMethods =='SUPER_MARKET_CODE' || payForm.payMethods =='SUPER_MARKET_BAR' || payForm.payMethods =='VIRTUAL_ACCOUNT' || payForm.payMethods =='UNION_CARD'|| payForm.payMethods =='SUPER_PAY_PICKUP') && needPayer=='1'">
              <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 1 of 4</div>
              <div class="tit-no" v-else-if="matchingLogistics!=1 && !isExistIdentity">Step. 1 of 2</div>
              <div class="tit-no" v-else>Step. 1 of 3</div>
            </div>
            <div v-else>
              <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 1 of 3</div>
              <div class="tit-no" v-else-if="matchingLogistics!=1 && !isExistIdentity">Step. 1 of 1</div>
              <div class="tit-no" v-else>Step. 1 of 2</div>
            </div>

            <!-- 選擇付款方式 -->
            <div class="pay-method">
              <div class="tit-area">
                <div class="tit-cn">選擇付款方式</div>
                <div class="tit-en">Payment Method</div>
              </div>
              <el-image class="tit-bk" :src="rectangleOiUrl"></el-image>
              <div class="pay-form-radio">
                <el-radio-group v-model="payForm.payMethods" @change="changePayMethods(),changeLogisticsMode()" style="width: 100%;">
                  <!-- 左侧付款方式 -->
                  <div class="paymentMethod">
                    <el-row v-for="(item, index) in modeCodeList" :key="index">
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'DOMESTIC_CREDIT_CARD'">
                        信用卡 Credit Card
                        <el-image class="cre-card-img-1" :src="masterImgUrl"></el-image>
                        <el-image class="cre-card-img-2" :src="visaImgUrl"></el-image>
                        <el-image class="cre-card-img-3" :src="jcbImgUrl"></el-image>
                      </el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'UNION_CARD'">
                        銀聯卡 Union Pay
                        <el-image class="un-card" :src="unionPayImgUrl"></el-image>
                      </el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt " v-if="item.modeCode == 'GOOGLE_PAY'">
                        <el-image class="gg-img" :src="googlePayImgUrl"></el-image>
                      </el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt " v-if="item.modeCode == 'APPLE_PAY'">
                        <el-image class="ap-img" :src="applePayImgUrl"></el-image>
                      </el-radio>
                    </el-row>
                  </div>
                  <!-- 右侧付款方式 -->
                  <div class="paymentMethod">
                    <el-row v-for="(item, index) in modeCodeList" :key="index">
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'SUPER_MARKET_CODE'">超商代碼 Billing Code</el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'SUPER_MARKET_BAR'">超商條碼 Barcode</el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'VIRTUAL_ACCOUNT'">虛擬帳號 ATM Transfer</el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'SUPER_PAY_PICKUP'">超商取貨付款 Pay At Pickup</el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'JKO_PAY'">
                        <el-image class="jko-img" :src="jkoPayImgUrl"></el-image>
                        JKOPAY
                      </el-radio>
                    </el-row>
                  </div>
                  <!-- </el-row> -->
                </el-radio-group>
              </div>
            </div>
            <!-- 付款資訊 -->
            <div v-if="needPayer=='1'">
              <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity" style="margin-top: 30px;">Step. 2 of 4</div>
              <div class="tit-no" v-else-if="matchingLogistics!=1 && !isExistIdentity" style="margin-top: 30px;">Step. 2 of 2</div>
              <div class="tit-no" v-else style="margin-top: 30px;">Step. 2 of 3</div>
            </div>
            <div class="pay-information" v-if="payForm.payMethods">

              <div class="tit-area" v-if="needPayer=='1'">
                <div class="tit-cn">付款資訊</div>
                <div class="tit-en">Payment Information</div>

                <el-image class="tit-bg" :src="rectangleOiUrl"></el-image>
              </div>
              <div style="margin-top: 10px;">
                <div v-if="payForm.payMethods == 'DOMESTIC_CREDIT_CARD'">
                  <div class="paymentFormItem">
                    <!-- 信用卡卡號 -->
                    <el-row>
                      <el-form-item
                          class="" style="font-weight: 400;" label="信用卡卡號 Card Number" prop="creditCardNumber"
                          :rules="[
                            { required: true, message: '必填欄位不得為空白X Field required', trigger: 'blur' },
                            { validator: validateCreditCardNumber}
                          ]"
                      >
                        <div style="display: flex; align-items: center;">
                          <el-input
                              v-model="creditCardNumber1"
                              type="text"
                              ref="creditCardNumber1"
                              @input="changecreditCardNumber($event,'creditCardNumber1')"
                              style="margin-right: 5px;"
                              size="small"
                          />
                          -
                          <el-input
                              v-model="creditCardNumber2"
                              maxlength="4"
                              :type="eyeShow ? 'text' : 'password'"
                              ref="creditCardNumber2"
                              @input="changecreditCardNumber($event,'creditCardNumber2')"
                              style="margin: 0px 5px;" size="small"
                          />
                          -
                          <el-input
                              v-model="creditCardNumber3"
                              maxlength="4"
                              :type="eyeShow ? 'text' : 'password'"
                              ref="creditCardNumber3"
                              @input="changecreditCardNumber($event,'creditCardNumber3')"
                              style="margin: 0px 5px;" size="small"
                          />
                          -
                          <el-input
                              v-model="creditCardNumber4"
                              maxlength="4" type="text"
                              ref="creditCardNumber4"
                              @input="changecreditCardNumber($event,'creditCardNumber4')"
                              style="margin-left: 5px;"
                              size="small"
                          />
                          <!-- 眼睛睜眼圖 -->
                          <el-image v-show="eyeShow" @click="changeEyeShow" :src="eye" class="card-image"/>
                          <!-- 眼睛閉眼圖 -->
                          <el-image v-show="!eyeShow" @click="changeEyeShow" :src="eyeSlash" class="card-image"/>
                          <!--                      <el-image :src="cardRImgUrl" class="card-image"/>-->
                        </div>
                        <!-- 錯誤訊息區 -->
                        <span slot="error">
                          <div class="error-msg">
                            <!-- 驚嘆號圖片 -->
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <!-- 錯誤訊息 -->
                            <span class="txt">{{ creditCardNumberMsg }}</span>
                          </div>
                        </span>
                      </el-form-item>
                    </el-row><!-- termsList.length > 0 -->
                    <!-- 分期期數 -->
                    <el-row v-if="dataForm.installmentsList.length>0">
                      <el-form-item
                          style="font-weight: 400;"
                          label="分期期數 Number of Installments"
                          prop="creditCardInstallment"
                          :rules="payForm.payMethods == 'DOMESTIC_CREDIT_CARD' && dataForm.installmentsList.length>0?[
					                  { validator: validateInstallment},
                          ]:''"
                      >
                        <div style="display: flex;">
                          <el-select class="right-image-input selet-drop" size="small" v-model="dataForm.creditCardInstallment"
                                     style="width:100%" placeholder="" @change="changeInstallments()" clearable>
                            <el-option label="不分期" value=""></el-option>
                            <el-option v-for="(item,index) in dataForm.installmentsList" :key="item.installments"
                                       :label="item.installmentsName"
                                       :value="item.installments"></el-option>
                          </el-select>
                          <el-image :src="arrowDownImgUrl" class="right-image"/>
                        </div>
                        <span slot="error">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ validateInstallmentErr }}</span>
                        </div>
                      </span>
                      </el-form-item>
                    </el-row>
                    <!-- 有效月年/末三碼 -->
                    <el-row :gutter="30">
                      <!-- 有效月年 -->
                      <el-col :sm="12" :xs="24">
                        <el-form-item
                            style="font-weight: 400;"
                            label="有效月年 Expiry Date"
                            prop="effectiveDate"
                            :rules="[
                              { required: true, message: EffectiveDateErMsg, trigger: 'blur' },
                              { validator: validateEffectiveDate}
                            ]"
                        >
                          <!-- <div style="display: flex;">
                              <el-cascader
                                placeholder=""
                                class="right-image-input"
                                v-model="dataForm.effectiveDate"
                                :options="optMYList"
                                @change="handleChange"></el-cascader>
                              <el-image :src="calendarTickImgUrl" class="right-image"/>
                          </div> -->
                          <div style="display: flex;">
                            <el-input
                                placeholder="mm / yy"
                                v-model="dataForm.effectiveDate"
                                maxlength="5"
                                @input="effectiveDateCheck"
                                size="small"
                                class="right-image-input"/>
                            <!--                        <el-image :src="dataForm.effectiveDate ? calendarTickImgUrl : calendarTickRImgUrl" class="right-image"/>-->
                          </div>

                          <span slot="error">
                      <div class="error-msg">
                        <el-image :src="annotationAlertImgUrl" class="img"/>
                        <span class="txt">{{ EffectiveDateErMsg }}</span>
                      </div>
                    </span>
                        </el-form-item>
                      </el-col>
                      <!-- 末三碼 -->
                      <el-col :sm="12" :xs="24">
                        <el-form-item
                            style="font-weight: 400;"
                            label="末三碼 CVV/CVC"
                            prop="creditCardSecurityCode"
                            :rules="[
                              { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                            ]">
                          <div style="display: flex;">
                            <el-input
                                v-model="dataForm.creditCardSecurityCode"
                                maxlength="3"
                                @input="(v) => (dataForm.creditCardSecurityCode = v.replace(/[^\d]/g,''))"
                                class="right-image-input"
                                size="small"
                            />
                          </div>
                          <span slot="error">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">{{ formErrorMsg }}</span>
                          </div>
                        </span>
                        </el-form-item>
                        <el-col :span="24" class="box-payForm-card" v-if="dataForm.merchantSaveCard == '1'">
                          <el-checkbox v-model="dataForm.saveCard" false-label="0" true-label="1" tabindex="0">
                            <div class="txt">同意記住此結帳資訊，以利下次支付</div>
                            <el-image class="img" :src="rememberTxtImgUrl"></el-image>
                          </el-checkbox>
                        </el-col>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- 付款人資訊-信用卡專用 -->
                  <div class="paymentFormItem" v-if="needPayer=='1'">
                    <!-- 付款人姓名 -->
                    <el-row>
                      <el-form-item
                          style="font-weight: 400;"
                          label="付款人姓名 Payer Name"
                          prop="creditCardUserName"
                          :rules="[
                        { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                      ]">
                        <div style="display: flex;">
                          <el-input v-model="dataForm.creditCardUserName" size="small" class="right-image-input"/>
                          <!--                      <el-image :src="dataForm.creditCardUserName ? profileTickImgUrl : profileTickRImgUrl" class="right-image"/>-->
                        </div>
                        <span slot="error">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                      </el-form-item>
                    </el-row>
                    <!-- 行動電話 -->
                    <el-row>
                      <el-form-item
                          style="font-weight: 400;"
                          label="行動電話 Mobile Number"
                          prop="creditCardPhone"
                      >
                        <div style="display: flex;">
                          <el-input
                              @input="(v) => (dataForm.creditCardPhone = v.replace(/[^\d]/g,''))"
                              v-model="dataForm.creditCardPhone"
                              @change="validatePhoneNumber()"
                              maxlength="10"
                              class="right-image-input"
                              size="small"
                          >
                            <!--							<template slot="prepend">+886</template>-->
                          </el-input>
                          <!--                      <el-image :src="dataForm.creditCardPhone ? componentImgUrl : componentRImgUrl" class="right-image"/>-->

                        </div>
                        <span v-if="phoneError">
					    <div class="error-msg">
					      <el-image :src="annotationAlertImgUrl" class="img"/>
					      <span class="txt">請輸入正確的電話號碼</span>
					    </div>
					  </span>
                      </el-form-item>
                    </el-row>
                    <!-- 信箱 -->
                    <el-row>
                      <el-form-item
                          style="font-weight: 400;"
                          label="信箱 Email"
                          prop="creditCardEmail"
                          :rules="[
                        { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                        { type: 'email', message: '請輸入正確的信箱', trigger: ['blur', 'change'] }
                      ]">
                        <div style="display: flex;">
                          <el-input v-model="dataForm.creditCardEmail" size="small" class="right-image-input"/>
                          <!--                      <el-image :src="dataForm.creditCardEmail ? mailImgUrl : mailRImgUrl" class="right-image"/>-->
                        </div>
                        <!-- 提示訊息 -->
                        <div style="line-height: 14px; font-size: 12px; margin: 5px 0">
                          <div style="display: flex; flex-direction: row;">
                            <el-image style="width: 16px; height: 16px;" :src="annotationCheckImgUrl"/>
                            <div style="color: #1DB145; font-weight: 400; margin-left: 2px; ">
                              紅陽科技將會寄發交易結果通知信至此Email
                            </div>
                          </div>
                          <div style="color: #1DB145; font-weight: 400; margin-left: 18px;">SunPay will
                            send a transaction notification to this email address.
                          </div>
                        </div>
                        <!-- 錯誤訊息 -->
                        <span slot="error" v-if="!dataForm.creditCardEmail">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">{{ formErrorMsg }}</span>
                          </div>
                        </span>
                        <span slot="error" v-else>
                          <div class="error-msg">
                           <el-image :src="annotationAlertImgUrl" class="img"/>
                           <span class="txt">請輸入正確的信箱</span>
                          </div>
                        </span>
                      </el-form-item>
                    </el-row>
                  </div>
                </div>
                <!-- 信用卡付款人資訊-其餘付款方式- -->
                <div v-if=" payForm.payMethods != 'DOMESTIC_CREDIT_CARD' && needPayer=='1'">
                  <div class="paymentFormItem" style="width: 98%">
                    <!-- 付款人姓名 -->
                    <el-row>
                      <el-form-item
                          style="font-weight: 400;"
                          label="付款人姓名 Payer Name"
                          prop="creditCardUserName"
                          :rules="[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]">
                        <div style="display: flex;">
                          <el-input v-model="dataForm.creditCardUserName" size="small" class="right-image-input"/>
                        </div>
                        <span slot="error">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">{{ formErrorMsg }}</span>
                          </div>
                        </span>
                      </el-form-item>
                    </el-row>
                    <!-- 行動電話 -->
                    <el-row style="margin-top: 20px">
                      <el-form-item
                          style="font-weight: 400;"
                          label="行動電話 Mobile Number  "
                          prop="creditCardPhone"
                      >
                        <div style="display: flex;">
                          <el-input
                              @input="(v) => (dataForm.creditCardPhone = v.replace(/[^\d]/g,''))"
                              @change="validatePhoneNumber()"
                              v-model="dataForm.creditCardPhone"
                              maxlength="10"
                              class="right-image-input"
                              size="small"
                          >
                            <!--							<template slot="prepend">+886</template>-->
                          </el-input>
                          <!--                      <el-image :src="dataForm.creditCardPhone ? componentImgUrl : componentRImgUrl" class="right-image"/>-->
                        </div>
                        <span v-if="phoneError">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">請輸入正確的電話號碼</span>
                          </div>
                        </span>
                      </el-form-item>
                    </el-row>
                    <!-- 信箱 -->
                    <el-row style="margin-top: 20px">
                      <el-form-item
                          style="font-weight: 400;"
                          label="信箱 Email"
                          prop="creditCardEmail"
                          :rules="[
                          { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                          { type: 'email', message: '請輸入正確的信箱', trigger: ['blur', 'change'] }
                       ]">
                        <!--                        <div style="position: absolute; top: -25px; right: 0px;">-->
                        <!--                          <div style="display: flex; flex-direction: row; line-height: 12px;">-->
                        <!--                            <el-image style="width: 14px; height: 14px;" :src="annotationCheckImgUrl"/>-->
                        <!--                            &lt;!&ndash; <el-image style="width: 230px;" :src="emailTxtCNImgUrl"/> &ndash;&gt;-->
                        <!--                            <div style="color: #1DB145; font-size: 0.6rem; font-weight: 400; margin-left: 2px;">紅陽科技將會寄發交易結果通知信至此Email</div>-->
                        <!--                          </div>-->
                        <!--                          <div style="line-height: 8px; text-align: end;">-->
                        <!--                            <el-image style="width: 230px;" :src="emailTxtImgUrl"/>-->
                        <!--                          </div>-->
                        <!--                        </div>-->
                        <div style="display: flex;">
                          <el-input v-model="dataForm.creditCardEmail" size="small" class="right-image-input"/>
                          <!--                      <el-image :src="dataForm.creditCardEmail ? mailImgUrl : mailRImgUrl" class="right-image"/>-->
                        </div>
                        <!-- 提示訊息 -->
                        <div style="line-height: 14px; font-size: 12px; margin: 5px 0">
                          <div style="display: flex; flex-direction: row;">
                            <el-image style="width: 16px; height: 16px;" :src="annotationCheckImgUrl"/>
                            <div style="color: #1DB145; font-weight: 400; margin-left: 2px; ">
                              紅陽科技將會寄發交易結果通知信至此Email
                            </div>
                          </div>
                          <div style="color: #1DB145; font-weight: 400; margin-left: 18px;">SunPay will
                            send a transaction notification to this email address.
                          </div>
                        </div>
                        <!-- 錯誤訊息 -->
                        <span slot="error" v-if="!dataForm.creditCardEmail">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">{{ formErrorMsg }}</span>
                          </div>
                        </span>
                        <span slot="error" v-else>
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">請輸入正確的信箱</span>
                          </div>
                        </span>
                      </el-form-item>
                    </el-row>
                  </div>
                </div>

                <!-- 分割綫 -->
                <!-- invoiceShowFlg  0隐藏 1显示舊版 2顯示新版-->
                <el-row v-if="dataForm.invoiceShowFlg == 1 || dataForm.invoiceShowFlg == 2" :gutter="50" style="margin-top: 30px; margin-left: -25px;">
                  <el-col :span="24">
                    <el-image :src="dividerImgUrl"></el-image>
                  </el-col>
                </el-row>
                <!-- 1显示舊版發票 -->
                <div v-if="dataForm.invoiceShowFlg == 1">
                  <el-row :gutter="50" style="top: -10px;">
                    <el-col :span="24">
                      <el-form-item
                          style="font-weight: 400;"
                          label="發票 Invoice"
                          prop="payMethods"
                      >
                        <div class="pay-form-radio">
                          <el-radio-group style="width: 100%;" v-model="payForm.invoice" @change="changeInvoiceStatus">
                            <el-radio label="0" :checked="payForm.invoice == 0" class="pay-rad-txt ">個人發票</el-radio>
                            <!-- <el-radio label="1" class="pay-rad-txt ">會員載具</el-radio> -->
                            <el-radio label="2" :checked="payForm.invoice == 2" class="pay-rad-txt ">手機條碼</el-radio>
                            <el-radio label="3" :checked="payForm.invoice == 3" class="pay-rad-txt ">公司統編</el-radio>
                            <el-radio label="4" :checked="payForm.invoice == 4" class="pay-rad-txt ">捐贈</el-radio>
                          </el-radio-group>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <div v-if="payForm.invoice">
                    <div style="margin-top: 0px;" v-if="payForm.invoice == 3">
                      <el-form-item style="font-weight: 400;" label="紅陽科技將寄發電子發票開立通知至您的Mail，可點擊信件内明細連結執行發票列印">
                      </el-form-item>
                      <div style="display: flex; flex-direction: row;">
                        <el-form-item style="font-weight: 400; width: 140px;" label="" prop="payMethods" label-position="left">
                          <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>發票抬頭</div>
                        </el-form-item>
                        <el-form-item prop="invoiceHead" style="width:420px;margin-top: 20px!important;">
                          <el-input class="ol-el-input" style="max-width: 420px" maxlength="60" v-model="dataForm.invoiceHead"></el-input>
                          <span slot="error" v-if="!dataForm.invoiceHead">
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                              </div>
                            </span>
                        </el-form-item>
                      </div>
                      <div style="display: flex; flex-direction: row;">
                        <el-form-item style="font-weight: 400; width: 140px;" label="" prop="payMethods" label-position="left">
                          <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>買受人統編</div>
                        </el-form-item>
                        <el-form-item prop="buyerIdentifier" style="width:420px;margin-top: 20px!important;">
                          <el-input class="ol-el-input" style="max-width: 420px" maxlength="8" v-model="dataForm.buyerIdentifier"></el-input>
                          <span slot="error" v-if="!dataForm.buyerIdentifier">
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                              </div>
                            </span>
                          <span slot="error" v-else>
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">請輸入正確買受人統編格式</span>
                              </div>
                            </span>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 2顯示新版發票 -->
                <div v-if="dataForm.invoiceShowFlg == 2">
                  <el-row :gutter="50" style="top: -10px;">
                    <el-col :span="24">
                      <el-form-item style="font-weight: 400;" label="發票 Invoice" prop="payMethods">
                        <div class="pay-form-radio">
                          <el-radio-group style="width: 100%;" v-model="payForm.invoice" @change="changeInvoiceStatus">
                            <el-radio label="0" :checked="payForm.invoice == 0" class="pay-rad-txt ">個人發票</el-radio>
                            <el-radio label="2" :checked="payForm.invoice == 2" class="pay-rad-txt ">手機條碼</el-radio>
                            <el-radio label="1" :checked="payForm.invoice == 1" class="pay-rad-txt ">自然人憑證條碼</el-radio>
                            <el-radio label="3" :checked="payForm.invoice == 3" class="pay-rad-txt ">公司統編</el-radio>
                            <el-radio label="4" :checked="payForm.invoice == 4" class="pay-rad-txt ">捐贈</el-radio>
                          </el-radio-group>
                        </div>
                        <div v-if="payForm.invoice == 0 || payForm.invoice == 3" class="pay-form-check">
                          <el-form-item label="" v-if="invoicePaperShow == '1'">
                            <el-checkbox v-model="invoicePaperChecked" @change="changePaperCheck()" style="font-weight: 400;">索取紙本發票</el-checkbox>
                          </el-form-item>
                          <div style="margin-top: -10px;" v-if="payForm.invoice == 0 && dataForm.invoiceShowFlg == 2">
                            <el-form-item style="font-weight: 400;" label="若無勾選索取紙本發票，個人發票預設存入會員載具，若中獎將另行通知">
                            </el-form-item>
                          </div>

                          <div style="margin-top: 0px;" v-if="payForm.invoice == 3">
                            <el-form-item style="font-weight: 400;" label="紅陽科技將寄發電子發票開立通知至您的Mail，可點擊信件内明細連結執行發票列印">
                            </el-form-item>
                            <div style="display: flex; flex-direction: row;">
                              <el-form-item style="font-weight: 400; width: 140px;" label="" prop="payMethods" label-position="left">
                                <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>發票抬頭</div>
                              </el-form-item>
                              <el-form-item prop="invoiceHead" style="width:420px;margin-top: 20px!important;">
                                <el-input class="ol-el-input" style="max-width: 420px" maxlength="60" v-model="dataForm.invoiceHead"></el-input>
                                <span slot="error" v-if="!dataForm.invoiceHead">
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                              </div>
                            </span>
                              </el-form-item>
                            </div>
                            <div style="display: flex; flex-direction: row;">
                              <el-form-item style="font-weight: 400; width: 140px;" label="" prop="payMethods" label-position="left">
                                <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>買受人統編</div>
                              </el-form-item>
                              <el-form-item prop="buyerIdentifier" style="width:420px;margin-top: 20px!important;">
                                <el-input class="ol-el-input" style="max-width: 420px" maxlength="8" v-model="dataForm.buyerIdentifier"></el-input>
                                <span slot="error" v-if="!dataForm.buyerIdentifier">
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                              </div>
                            </span>
                                <span slot="error" v-else>
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">請輸入正確買受人統編格式</span>
                              </div>
                            </span>
                              </el-form-item>
                            </div>
                          </div>

                          <div style="display: flex; flex-direction: row;" v-if="invoicePaperChecked && invoicePaperShow == '1'">
                            <el-form-item style="font-weight: 400; width: 140px; margin-top: 12px;" label="" label-position="left">
                              <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>發票地址</div>
                              <span v-if="!dataForm.city || !dataForm.area || !dataForm.address">
                              <div class="error-msg">
                                <!-- <el-image :src="annotationAlertImgUrl" class="img"/> -->
                                <span class="txt">必填欄位不能為空</span>
                              </div>
                            </span>
                            </el-form-item>
                            <div style="width: 100%; max-width: 420px;margin-top: 20px;">
                              <div class="invoiceItem">
                                <el-select v-model="dataForm.city" clearable placeholder="選擇城市" filterable @clear="clearSelect('dataForm','area')" @change="choseCity($event)">
                                  <el-option v-for="(item,index) in cityList" :key="item.cityId" :label="item.cityName" :value="item.cityId"></el-option>
                                </el-select>
                              </div>
                              <div class="invoiceItem" style="margin-left: 10px;">
                                <el-select v-model="dataForm.area" clearable filterable placeholder="選擇地區">
                                  <el-option v-for="(item,index) in areaList" :key="item.areaId" :label="item.areaName" :value="item.areaId"></el-option>
                                </el-select>
                              </div>
                              <el-input v-model="dataForm.address" type="text" style="margin-top: 20px;"></el-input>
                            </div>
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>


                <!-- 載具號碼 -->
                <div v-if="payForm.invoice">
                  <div style="margin-top: 0px;" v-if="payForm.invoice == 1 || payForm.invoice == 2">
                    <!-- <el-col :span="12"> -->
                    <div style="display: flex; flex-direction: row;">
                      <el-form-item style="font-weight: 400; width: 140px;" label="" prop="payMethods" label-position="left">
                        <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>載具號碼</div>
                      </el-form-item>
                      <el-form-item prop="carrierId" style="margin-top: 0!important;width:420px;">
                        <el-input class="ol-el-input" style="max-width: 420px;" :maxlength="payForm.invoice == 1?16:8" v-model="dataForm.carrierId"></el-input>
                        <span slot="error" v-if="!dataForm.carrierId">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                        <span slot="error" v-else>
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">請輸入正確載具號碼格式</span>
                        </div>
                      </span>
                      </el-form-item>
                    </div>
                  </div>

                  <div style="margin-top: 0px;" v-if="payForm.invoice == 4">
                    <div style="display: flex; flex-direction: row;">
                      <el-form-item style="font-weight: 400; width: 140px;" label="" label-position="left">
                        <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>捐贈號碼</div>
                      </el-form-item>
                      <el-form-item prop="poban" style="width: 420px;">
                        <el-select clearable filterable v-model="dataForm.poban" style="width:420px;">
                          <el-option :key="item.poban" :label='item.poban+"-"+item.pobanName'
                                     :value=item.poban
                                     v-for="(item,index) in pobanList"></el-option>
                        </el-select>
                        <span slot="error">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <!-- 分割綫 -->
                <div v-if="false">
                  <el-row :gutter="50" style="margin-top: 30px; margin-left: -25px;">
                    <el-col :span="24">
                      <el-image :src="dividerImgUrl"></el-image>
                    </el-col>
                  </el-row>
                </div>

                <!-- 寄送方式 -->
                <div v-if="false">
                  <el-row :gutter="50">
                    <el-col :span="24">
                      <el-form-item style="font-weight: 400;" label="寄送方式 Delivery" prop="payMethods">
                        <div class="pay-form-radio">
                          <el-radio-group style="width: 100%;">
                            <el-radio label="1" class="pay-rad-txt ">超商取貨 In-store Pickup</el-radio>
                            <el-radio label="1" class="pay-rad-txt ">宅配物流 Home Delivery</el-radio>
                          </el-radio-group>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <!-- 備註 Memo -->
                <div style="margin-top: 20px;">
                  <div style="display: flex; flex-direction: row;">
                    <el-form-item style="font-weight: 400; width: 140px; margin-top: 12px;" label="備註 Memo" prop="payMethods" label-position="left">
                    </el-form-item>
                    <el-input class="le-el-input" type="textarea" :rows="2" style="text-align: left; max-width: calc(100% - 14.5%);" v-model="dataForm.desc"></el-input>
                  </div>
                </div>
                <!-- 備註 Memo -->

              </div>
            </div>

            <!-- 收件人咨询 -->
            <div v-if="matchingLogistics=='1'">
              <div>
                <el-row :gutter="50" style="margin-top: 30px; margin-left: -25px;">
                  <el-col :span="24">
                    <el-image :src="dividerImgUrl"></el-image>
                  </el-col>
                </el-row>
              </div>
              <!-- 寄送方式 Delivery -->
              <div class="pay-information">
                <div class="tit-area">
                  <div class="tit-cn">寄送方式</div>
                  <div class="tit-en">Delivery</div>
                  <el-image class="tit-bg" :src="rectangleOiUrl"></el-image>
                </div>
                <el-row :gutter="50">
                  <el-col :span="24">
                    <el-form-item style="font-weight: 400;" prop="shippingMethod">
                      <div class="pay-form-radio">
                        <el-radio-group v-model="dataForm.shippingMethod" style="width: 100%;">
                          <el-radio label="10" class="pay-rad-txt ">超商取貨 In-store Pickup</el-radio>
                          <!-- <el-radio label="20" class="pay-rad-txt ">宅配物流 Home Delivery</el-radio> -->
                        </el-radio-group>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>


              <div style="display: flex;flex-direction: column; margin-top: 20px;">
                <div v-if="payForm.payMethods =='DOMESTIC_CREDIT_CARD'||(payForm.payMethods =='SUPER_MARKET_CODE' || payForm.payMethods =='SUPER_MARKET_BAR' ||payForm.payMethods =='VIRTUAL_ACCOUNT' || payForm.payMethods =='UNION_CARD'|| payForm.payMethods =='SUPER_PAY_PICKUP') && needPayer=='1'">
                  <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 3 of 4</div>
                  <div class="tit-no" v-else>Step. 3 of 3</div>
                </div>
                <div v-else>
                  <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 2 of 3</div>
                  <div class="tit-no" v-else>Step. 2 of 2</div>
                </div>
                <div class="pay-information">
                  <div class="tit-area">
                    <div class="tit-cn">收件人資訊</div>
                    <div class="tit-en">Receiver Information</div>
                    <el-image class="tit-bg" :src="rectangleOiUrl"></el-image>
                  </div>
                  <div style="margin-top: 10px;">
                    <div>
                      <div class="paymentFormItem">
                        <el-row>
                          <div class="pay-form-radio">
                            <div class="convenienceStoreRadio" style="margin-top: 28px">
                              <el-radio-group @change="logisticsModeCodeChange" v-model="dataForm.logisticsModeCode" vertical style="width: 100%;">
                                <el-radio :label="item.modeCode" v-for="item in logisticsModeList" class="pay-rad-txt " style="font-weight: 400;width: 100%;">{{ item.modeName }}</el-radio>
                              </el-radio-group>
                            </div>
                          </div>
                        </el-row>
                        <el-row>
                          <div>
                            <el-form-item style="font-weight: 400;" label="取貨門市 Convenience Store" prop="pickUpStorePosition" :rules="matchingLogistics=='1'?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]:[]">
                              <div style="display: flex;">
                                <el-input v-model="dataForm.pickUpStorePosition" size="small" disabled class="right-image-input"/>
                                <!-- icon -->
                                <!--                                <el-image :src="dataForm.pickUpStorePosition ? carImgUrl : carRImgUrl" style="margin-top: 8px" class="right-image"/>-->
                              </div>
                              <span slot="error">
										  <div class="error-msg">
											<el-image :src="annotationAlertImgUrl" class="img"/>
											<span class="txt">{{ formErrorMsg }}</span>
										  </div>
										</span>
                            </el-form-item>
                          </div>
                          <div>
                            <el-button type="danger" style="width: 100%; padding: 15px; background: #8F0304; margin: 20px 0;" @click="handleOpen">選擇取貨門市 Choose Store</el-button>
                            <div class="error-msg" v-if="modeCodeisEmpty">
                              <el-image :src="annotationAlertImgUrl" class="img"/>
                              <span class="txt">請選擇取貨超商Please choose store</span>
                            </div>
                          </div>
                        </el-row>
                      </div>
                      <div class="paymentFormItem">
                        <el-row>
                          <div class="recipientInfoChecked2">
                            <div class="pay-form-check" style="text-align: right;">
                              <el-checkbox v-model="recipientInfoChecked" @change="recipientInfoCheckChange">
                                <div class="pay-form-check2" style="text-align: left;">
                                  <span class="pay-form-check-txt">同付款人資訊</span><br>
                                  <span class="pay-form-check-txt">Same as payer information.</span>
                                </div>
                              </el-checkbox>
                            </div>
                          </div>
                        </el-row>
                        <el-row>
                          <div>
                            <el-form-item style="font-weight: 400;" label="收件人姓名 Receiver Name" prop="recipientName" :rules="matchingLogistics=='1'?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]:[]">
                              <div style="display: flex;">
                                <el-input v-model="dataForm.recipientName" size="small" class="right-image-input"/>
                                <!-- icon -->
                                <!--                                <el-image :src="dataForm.recipientName ? profileTickImgUrl : profileTickRImgUrl" class="right-image"/>-->
                              </div>
                              <span slot="error">
									  <div class="error-msg">
										<el-image :src="annotationAlertImgUrl" class="img"/>
										<span class="txt">{{ formErrorMsg }}</span>
									  </div>
									</span>
                            </el-form-item>
                          </div>
                        </el-row>
                        <el-row>
                          <div>
                            <el-form-item style="font-weight: 400;" label="收件人手機 Mobile Number" prop="recipientPhoneNumber" :rules="matchingLogistics=='1'?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }]:[]">
                              <div style="display: flex;">
                                <el-input v-model="dataForm.recipientPhoneNumber" size="small" maxlength="10" minlength="10" @input="(v) => (dataForm.recipientPhoneNumber = v.replace(/[^\d]/g,''))" @change="checkRecipientPhone" class="right-image-input">
                                  <!--						  <template slot="prepend">+886</template>-->
                                </el-input>
                                <!-- icon -->
                                <!--                                <el-image :src="dataForm.recipientPhoneNumber ? componentImgUrl : componentRImgUrl" class="right-image"/>-->
                              </div>
                              <span slot="error" v-if="!dataForm.recipientPhoneNumber">
                            <div class="error-msg">
                              <el-image :src="annotationAlertImgUrl" class="img"/>
                              <span class="txt">{{ recipientPhoneMsg }}</span>
                            </div>
                          </span>
                              <span v-if="recipientPhoneErr">
									  <div class="error-msg">
										<el-image :src="annotationAlertImgUrl" class="img"/>
										<span class="txt">請輸入正確的電話號碼！</span>
									  </div>
									</span>
                            </el-form-item>
                          </div>
                        </el-row>
                        <el-row>
                          <div>
                            <el-form-item style="font-weight: 400;" label="信箱 Email" prop="recipientEmail" :rules="matchingLogistics=='1'?[{ type: 'email', message: '請輸入正確的信箱', trigger: ['blur', 'change'] }]:[]">
                              <div style="display: flex;">
                                <el-input v-model="dataForm.recipientEmail" size="small" class="right-image-input"/>
                                <!-- icon -->
                                <!--                                <el-image :src="dataForm.recipientEmail ? mailImgUrl : mailRImgUrl" class="right-image"/>-->
                              </div>
                              <span slot="error">
									  <div class="error-msg">
										<el-image :src="annotationAlertImgUrl" class="img"/>
										<span class="txt">請輸入正確的信箱</span>
									  </div>
									</span>
                            </el-form-item>
                          </div>
                        </el-row>
                        <el-row>
                          <div class="recipientInfoChecked" style="color: #1DB145; font-size: 0.8rem; font-weight: 400; line-height: 14px; margin-top: 20px; padding: 3px;">
                            <div style="display: flex; flex-direction: row;">
                              <el-image style="width: 16px; height: 16px;display: inline-block; vertical-align: top;" :src="annotationCheckImgUrl"/>
                              <div style="margin-left: 2px">請務必正確輸入收件人資訊確保商品正確送達</div>
                            </div>
                            <div style="margin-left: 18px">Please make sure to enter accurate recipient information to ensure successful delivery.</div>
                          </div>
                        </el-row>
                      </div>
                    </div>
                  </div>
                </div>


                <div>
                  <!-- <el-form :model="testObj"  label-position="top"> -->

                  <div v-if="false">
                    <el-row :gutter="50">
                      <el-col :span="12">
                        <el-form-item style="font-weight: 400;" label="收件人姓名 Receiver Name" prop="payMethods" :rules="[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]">
                          <div style="display: flex;">
                            <el-input v-model="payMethods" class="right-image-input"/>
                            <el-image :src="payMethods ? profileTickImgUrl : profileTickRImgUrl" class="right-image"/>
                          </div>
                          <span slot="error">
						<div class="error-msg">
						  <el-image :src="annotationAlertImgUrl" class="img"/>
						  <span class="txt">{{ formErrorMsg }}</span>
						</div>
					  </span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item style="font-weight: 400;" label="收件人手機 Mobile Number" prop="payMethods" :rules="[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]">
                          <div style="display: flex;">
                            <el-input v-model="payMethods" class="right-image-input" maxlength="10" minlength="10" @change="checkRecipientPhone()" @input="(v) => (dataForm.recipientPhoneNumber = v.replace(/[^\d]/g,''))">
                              <!--						  <template slot="prepend">+886</template>-->
                            </el-input>
                            <el-image :src="dataForm.payMethods ? componentImgUrl : componentRImgUrl" class="right-image"/>
                          </div>
                          <span slot="error">
						<div class="error-msg">
						  <el-image :src="annotationAlertImgUrl" class="img"/>
						  <span class="txt">{{ formErrorMsg }}</span>
						</div>
					  </span>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="50">
                      <el-col :span="12">
                        <el-form-item style="font-weight: 400;" label="宅配地址 Receiver Addressr" prop="payMethods" :rules="[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]">
                          <div style="display: flex;">
                            <el-input v-model="payMethods" class="right-image-input"/>
                            <el-image :src="payMethods ? mapImgUrl : mapRImgUrl" class="right-image"/>
                          </div>
                          <span slot="error">
						<div class="error-msg">
						  <el-image :src="annotationAlertImgUrl" class="img"/>
						  <span class="txt">{{ formErrorMsg }}</span>
						</div>
					  </span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item style="font-weight: 400;" label="信箱 Email" prop="creditCardEmail" :rules="[{ type: 'email', message: '請輸入正確的信箱', trigger: ['blur', 'change'] }]">
                          <div style="display: flex;">
                            <el-input v-model="dataForm.creditCardEmail" class="right-image-input"/>
                            <el-image :src="dataForm.creditCardEmail ? mailImgUrl : mailRImgUrl" class="right-image"/>
                          </div>
                          <span slot="error">
						<div class="error-msg">
						  <el-image :src="annotationAlertImgUrl" class="img"/>
						  <span class="txt">請輸入正確的信箱</span>
						</div>
					  </span>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- </el-form> -->
                </div>
              </div>
            </div>

            <div v-if="isExistIdentity">
              <el-row :gutter="50" style="margin-top: 30px; margin-left: -25px;">
                <el-col :span="24">
                  <el-image :src="dividerImgUrl"></el-image>
                </el-col>
              </el-row>
            </div>
            <!-- 身分驗證 -->
            <div v-if="isExistIdentity" style="display: flex;flex-direction: column; margin-top: 30px;">
              <div v-if="payForm.payMethods =='DOMESTIC_CREDIT_CARD'||(payForm.payMethods =='SUPER_MARKET_CODE' || payForm.payMethods =='SUPER_MARKET_BAR' ||payForm.payMethods =='VIRTUAL_ACCOUNT' || payForm.payMethods =='UNION_CARD'|| payForm.payMethods =='SUPER_PAY_PICKUP') && needPayer=='1'">
                <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 4 of 4</div>
                <div class="tit-no" v-else>Step. 3 of 3</div>
              </div>
              <div v-else>
                <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 3 of 3</div>
                <div class="tit-no" v-else>Step. 2 of 2</div>
              </div>
              <div class="pay-method">
                <div class="tit-area" style="display: flex;">
                  <div style="font-size: 24px; font-weight: 600; color: #03328D;">身分驗證資訊</div>
                  <div style="font-size: 16px; font-weight: 600; color: #03328D; line-height: 42px; margin-left: 10px;">Identity Verification</div>
                </div>
                <el-image style="width: 296px; height: 24px; margin-top: 16px; margin-left: 12px; position: absolute;" :src="rectangleOiUrl"></el-image>
                <div>
                  <div class="identity-tips">依「第三方支付服務業防制洗錢及打擊資恐辦法」之規定，訂單已達法定金額，請完成身分驗證 In accordance with the “Regulations Governing Anti-Money Laundering and Countering the Financing of Terrorism for the Third-Party Payment Enterprises", the order has reached the legal amount, please complete the identity verification.</div>
                  <div class="identity-tips">您的身分證資料僅用於內政部戶役司之查驗作業，不會用於其他目的或提供給第三方 Your ID information will only be used for verification purposes by the Ministry of the Interior, Household Registration Office, and will not be used for any other purposes or provided to third parties.</div>
                  <div class="identity-title">手機號碼驗證(OTP)　Cellphone Number Verification</div>
                  <div style="margin-bottom: 20px;" class="paymentFormItem">
                    <el-row>
                      <el-form-item style="font-weight: 400;" label="持卡人手機 Cardholder Cellphone Number" prop="cellphoneNumber" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },{ validator: validateCellphoneNumber}]:''">
                        <div style="display: flex;">
                          <el-input v-model="dataForm.cellphoneNumber" @input="changeCellphoneNumber($event)" maxlength="10" class="right-image-input"/>
                          <el-image :src="dataForm.cellphoneNumber.length==10 ? componentImgUrl : componentRImgUrl" class="right-image"/>
                        </div>
                        <span slot="error">
                         <div class="error-msg">
                           <el-image :src="annotationAlertImgUrl" class="img"/>
                           <span class="txt">{{ cellphoneNumberMsg }}</span>
                         </div>
                       </span>
                      </el-form-item>
                      <div style="text-align: end;" v-if="verificationType == 1">
                        <!-- sendCodeStatus:0默認可點 1xx秒後不可點  2重發-可點 3次數已過-->
                        <el-button style="background: #8F0304;padding: 14px 10px;border-radius: 10px;color:white;" @click="getVerificationCode()" v-if="sendCodeStatus!='3'" :disabled="dataForm.cellphoneNumber==''|| checkPhone(dataForm.cellphoneNumber) || sendCodeStatus=='1' || cellphoneNumberErr">{{ sendCodeStatus == '0' ? '發送驗證碼 Send Verification Code' : sendCodeStatus == '1' ? count + '秒後重新發送 Resend in ' + count + ' Sec' : '重新發送驗證碼 Resend verification Code' }}</el-button>
                        <el-button v-else style="color: #8F0304;background: transparent;padding: 14px 10px;border: 0px;font-weight: 600;" disabled>發送次數已滿 Verification limit reached</el-button>
                      </div>
                    </el-row>
                  </div>
                  <template v-if="verificationType == 1">
                    <div style="margin-bottom: 20px;" class="paymentFormItem">
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="簡訊驗證碼 SMS Verification Code" prop="verificationCode" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },{ validator: validateVerificationCode}]:''">
                          <div style="display: flex;">
                            <el-input @input="verificationCodeCheck($event)" maxlength="6" v-model="dataForm.verificationCode" class="right-image-input"/>
                            <el-image :src="dataForm.verificationCode.length==6 ? smsVerificationImgUrl : smsNoVerificationImgUrl" class="right-image"/>
                          </div>
                          <span v-if="verificationCodeErr">
                             <div class="error-msg">
                               <el-image :src="annotationAlertImgUrl" class="img"/>
                               <span class="txt">驗證碼錯誤{{ errCount }}次，累計錯誤3次此筆交易將無效</br>
                                 Verification failed once, if there are 3 times in total, the transaction will be invalid.</span>
                             </div>
                           </span>
                          <span slot="error" v-else>
                             <div class="error-msg">
                               <el-image :src="annotationAlertImgUrl" class="img"/>
                               <span class="txt">{{ verificationCodeMsg }}</span>
                             </div>
                           </span>
                        </el-form-item>
                      </el-row>
                    </div>
                    <div class="identity-title2">身分驗證　ID Verification</div>
                    <div class="error-msg" v-if="verificationIDErr">
                      <el-image :src="annotationAlertImgUrl" class="img"/>
                      <span class="txt">身份驗證失敗，請重新確認 Identity verification failed, please reconfirm.</span>
                    </div>
                    <div class="paymentFormItem2">
                      <el-row>
                        <el-form-item class="" style="font-weight: 400;" label="身分證字號 ID Card Number" prop="idCardNumber" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },{ validator: validateIdCardNumber}]:''">
                          <div style="display: flex;">
                            <el-input v-model="dataForm.idCardNumber" maxlength="10" @input="idCardNumberCheck($event)" class="right-image-input"/>
                            <el-image :src="dataForm.idCardNumber.length==10 ? cardImgUrl : cardRImgUrl" class="right-image"/>
                          </div>
                          <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ idCardNumberMsg }}</span>
                           </div>
                         </span>
                        </el-form-item>
                      </el-row>
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="發證日期 Issuance Date" prop="issuanceDate" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }]:''">
                          <div style="display: flex;">
                            <el-select v-model="selectedYear" placeholder="年Year" @change="changeYear()">
                              <el-option v-for="(item,index) in yearOptions" :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                            <span>/</span>
                            <el-select v-model="selectedMonth" clearable placeholder="月Month" @change="mothChange(dataForm.selectedMonth)">
                              <el-option v-for="(item,index) in monthList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disable"></el-option>
                            </el-select>
                            <span>/</span>
                            <el-select v-model="selectedDay" clearable placeholder="日Day">
                              <el-option v-for="(item,index) in dayList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disable"></el-option>
                            </el-select>
                          </div>
                          <span v-if="issuanceDateIsEmpty==''">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                        </el-form-item>
                      </el-row>
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="身分證正反面照片 ID Card Photo" prop="idCardPhotoFrontResourceId" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }]:''">
                          <div style="font-size: 16px;font-weight: 400;letter-spacing: 0.9px;">正面 Front</div>
                          <div style="background-color: #EDEDED; border-radius: 8px; width: 100%; text-align: center;">
                            <el-image :src="idCard1" style="width: 80%; margin: 0 auto;" v-if="idCard1"></el-image>
                            <el-upload class="upload-demo" :action="$http.adornUrl('/common/resource/uploadResource')" :on-success="icCardFrontSuccess" accept=".jpg,.png,.jpeg" multiple>
                              <el-button size="small" type="danger" style="background-color: #8F0304" v-if="idCard1">變更 Change</el-button>
                              <el-button size="small" type="danger" style="background-color: #8F0304; margin: 100px 0 90px 0;" v-else>上傳 Upload</el-button>
                              <div slot="file" slot-scope="{file}"></div>
                            </el-upload>
                          </div>
                          <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                        </el-form-item>
                      </el-row>
                    </div>
                    <div class="paymentFormItem" style="margin-top: 12px;">
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="發證地點 Issuance Location" prop="issuanceLocation" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]:''">
                          <div style="display: flex;">
                            <el-select v-model="dataForm.issuanceLocation" placeholder="" style="width: 100%;">
                              <el-option
                                  v-for="item in issuanceLocationList"
                                  :key="item.code"
                                  :label="item.name"
                                  :value="item.code">
                              </el-option>
                            </el-select>
                          </div>
                          <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                        </el-form-item>
                      </el-row>
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="領補換類別 Issuance Type" prop="issuanceType" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]:''">
                          <div style="display: flex;">
                            <el-select v-model="dataForm.issuanceType" placeholder="" style="width: 100%;">
                              <el-option
                                  v-for="item in issuanceTypeList"
                                  :key="item.code"
                                  :label="item.name"
                                  :value="item.code">
                              </el-option>
                            </el-select>
                          </div>
                          <span slot="error">
                         <div class="error-msg">
                           <el-image :src="annotationAlertImgUrl" class="img"/>
                           <span class="txt">{{ formErrorMsg }}</span>
                         </div>
                       </span>
                        </el-form-item>
                      </el-row>
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="" prop="idCardPhotoBackResourceId" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }]:''">
                          <div style="font-size: 16px;font-weight: 400;letter-spacing: 0.9px; margin-top: 28px;">反面 Back</div>
                          <div style="background-color: #EDEDED; border-radius: 8px; width: 100%; text-align: center;">
                            <el-image :src="idCard2" style="width: 80%; margin: 0 auto;" v-if="idCard2"></el-image>
                            <el-upload class="upload-demo" :action="$http.adornUrl('/common/resource/uploadResource')" :on-success="icCardBackSuccess" accept=".jpg,.png,.jpeg" multiple>
                              <el-button size="small" type="danger" style="background-color: #8F0304" v-if="idCard2">變更 Change</el-button>
                              <el-button size="small" type="danger" style="background-color: #8F0304; margin: 100px 0 90px 0;" v-else>上傳 Upload</el-button>
                              <div slot="file" slot-scope="{file}"></div>
                            </el-upload>
                          </div>
                          <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                        </el-form-item>
                      </el-row>
                    </div>
                  </template>
                  <template v-if="verificationType == 2">
                    <div style="margin-bottom: 20px;" class="paymentFormItem">
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="持卡人身分證字號末三碼 Last three digits of ID" prop="idCardNumberSecurityCode" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]:''">
                          <div style="display: flex;">
                            <el-input @input="verificationCodeCheck($event)" maxlength="3" v-model="dataForm.idCardNumberSecurityCode" class="right-image-input"/>
                            <el-image :src="dataForm.creditCardNumber ? cardImgUrl : cardRImgUrl" class="right-image"/>
                          </div>
                          <span slot="error">
                             <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                             </div>
                         </span>
                          <div class="error-msg" v-if="verificationIDThreeErr">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">身分證資訊錯誤 ID information incorrect</span>
                          </div>
                        </el-form-item>
                      </el-row>
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <!-- 服務條款 -->
            <div v-if="payForm.payMethods">
              <!-- 警示語框框 -->
              <div style="border-radius:10px; background: #FAF0CA;margin: 30px 0;padding: 20px;">
                <div class="box-txt-rd">請主動求證付款資訊來源，切勿將個人資料提供給來路不明的第三方，若發現有不明管道之訊息可撥打165反詐騙諮詢專線。</div>
                <div class="box-txt-rd">Please proactively verify the source of payment information and refrain from
                  providing personal information to unknown third parties. If you suspect you could be victim of a scam,
                  protect your rights by calling the "165 Anti-Fraud Hotline".
                </div>
              </div>
              <div style="margin-bottom: 50px;">
                <div class="information-form-check">
                  <!-- 同意服務條款勾選框 -->
                  <el-checkbox v-model="informationCk">
                    <div class="box-txt-bl-b" style="color: #4F4F4F;">本人理解且同意<a
                        href="#" @click.prevent="showChineseDetail('privacy-law')" class="link">個人資料使用</a>及<a
                        href="#" @click.prevent="showChineseDetail('service-terms')" class="link">第三方支付服務契約</a>之內容。
                    </div>
                    <div class="box-txt-bl-b" style="color: #4F4F4F;">I agree to <a
                        href="#" @click.prevent="showEnglishDetail('privacy-law')" class="link">the Consent for Use of Personal Data</a> and <a
                        href="#" @click.prevent="showEnglishDetail('service-terms')" class="link">the Payment Service Agreement</a>.
                    </div>
                  </el-checkbox>

                  <!-- 服務條款浮動視窗 -->
                  <el-dialog :visible.sync="showPopup">
                    <!-- 標籤、標題 -->
                    <div class="fixed-tabs">
                      <el-tabs v-model="activeTab" type="card">
                        <!-- 個人資料提供同意書 -->
                        <el-tab-pane :label="getTabLabel('privacy-law')" name="privacy-law">
                          <h2 class="title">{{ getTitle('privacy-law') }}</h2>
                          <el-button @click="switchLanguage" class="language-btn" type="info">
                            {{ currentLanguage === 'chinese' ? 'English' : '中文' }}
                          </el-button>
                        </el-tab-pane>
                        <!-- 紅陽科技網路代理收付服務契約 -->
                        <el-tab-pane :label="getTabLabel('service-terms')" name="service-terms">
                          <h2 class="title">{{ getTitle('service-terms') }}</h2>
                          <el-button @click="switchLanguage" class="language-btn" type="info">
                            {{ currentLanguage === 'chinese' ? 'English' : '中文' }}
                          </el-button>
                        </el-tab-pane>
                        <!-- 消費者個資移轉商店使用同意書 -->
                        <!--                  <el-tab-pane :label="getTabLabel('data-transfer')" name="data-transfer">-->
                        <!--                    <h2 class="title">{{ getTitle('data-transfer') }}</h2>-->
                        <!--                    <el-button @click="switchLanguage" class="language-btn" type="info">-->
                        <!--                      {{ currentLanguage === 'chinese' ? 'English' : '中文' }}-->
                        <!--                    </el-button>-->
                        <!--                  </el-tab-pane>-->
                      </el-tabs>
                    </div>
                    <!-- 服務條款詳細內容 -->
                    <div class="popup-content">
                      <!-- 個人資料提供同意書 -->
                      <div v-if="activeTab === 'privacy-law'" class="details">
                        <div v-if="currentLanguage === 'chinese'">
                          <div>
                            本同意書說明紅陽科技股份有限公司（以下簡稱本公司）就賣家申請使用金流系統服務時，將如何處理所蒐集到的個人資料。當買（賣）家簽署本契約書時，表示買（賣）家已閱讀、瞭解並同意接受本同意書之所有內容及其後修改變更規定。。若買（賣）家未滿十八歲，將不受理使用本服務，但若買（賣）家已接受本服務，視為買（賣）家同意，並遵守以下所有規範。為善盡買（賣）家的個人資料之保護，本公司謹依個人資料保護法第8條第1項規定向使用者進行下列事項之告知：
                          </div>
                          <br>
                          <div>
                            <span style="font-weight: bolder">一、蒐集個人資料之目的</span><br>
                            本公司蒐集買（賣）家個人資料之目的係為確認身分及建立使用者資料檔案，並完成本公司帳戶開戶、委託處理與其他使用者間交易款項之代收代付服務及其他經主管機關核准之服務，同時向買（賣）家提供本公司完成交易、爭議處理等服務之相關訊息。法定特定目的為：「059金融服務業依法令規定及金融監理需要，所為之蒐集處理及利用」、「060金融爭議處理」、「063非公務機關依法定義務所進行個人資料之蒐集處理及利用」、「067信用卡、現金卡、轉帳卡或電子票證業務」、「069契約、類似契約或其他法律關係事務」、「090消費者、客戶管理與服務」、「091消費者保護」、「148網路購物及其他電子商務服務」、「157調查、統計與研究分析」、「181其他經營合於營業登記項目或組織章程所定之業務」。
                          </div>
                          <br>
                          <div>
                            <span style="font-weight: bolder">二、蒐集之資料類別</span><br>
                            姓名、地址、電話、電子郵遞地址、網路平台申請之帳號、申辦查詢服務之紀錄、身分證明文件影本(如駕照、健保卡等)(C001)、金融機構帳戶及其影本、信用卡相關資料、徵信報告、財力證明(C002)、身分證統一編號、護照號碼(C003)、年齡、性別、國籍、出生年月日(C011)、會員註冊時間、IP位址、瀏覽器種類、網頁瀏覽軌跡紀錄、cookies(C132)。
                          </div>
                          <br>
                          <div>
                            <span style="font-weight: bolder">三、利用期間、地區、對象及方式</span><br>
                            本公司利用買（賣）家的個人資料期間為即日起至賣家或本公司終止本服務日止，但法令另有規定或依法需保存之資料者，依其規定。利用地區為台灣地區與提供服務之地區。對象為本公司或與本公司因業務需要訂有契約關係或業務往來之機構(包括「各金融機構」、「台灣Pay」、「Google Pay」、「Soft Space」、「TapPay」及「Apple Pay」等)；金融監理或依法有權機關或金融監理機關。
                          </div>
                          <br>
                          <div>
                            <span style="font-weight: bolder">四、個人資料得行使權利之方式</span><br>
                            買（賣）家可依照個人資料保護法第三條行使以下權利：<br>
                          </div>
                          <div class="cn-numbered-list">
                            1. 查詢或請求閱覽。<br>
                          </div>
                          <div class="cn-numbered-list">
                            2. 請求製給複製本。<br>
                          </div>
                          <div class="cn-numbered-list">
                            3. 請求補充或更正。<br>
                          </div>
                          <div class="cn-numbered-list">
                            4. 請求停止蒐集、處理或利用。<br>
                          </div>
                          <div class="cn-numbered-list">
                            5. 請求刪除。<br>
                          </div>
                          <div>
                            買（賣）家欲行使上述相關權利時，請洽本公司客服信箱：<a href="mailto:service@esafe.com.tw" style="color: #106EBE;">service@esafe.com.tw</a>
                          </div>
                          <br>
                          <div>
                            <span style="font-weight: bolder">五、不提供個人資料所致權益之影響</span><br>
                            買（賣）家可自行選擇是否提供資料及所提供資料的完整性，但某些網站功能、服務或活動必須根據買（賣）家所提供的資料方得使用時，買（賣）家若不提供完整而正確的個人資料，部分網站功能、服務或活動可能無法使用，屆時本公司將無法受理買（賣）家使用者資格、相關金流業務資格之申請，尚祈見諒。
                          </div>
                        </div>
                        <div v-else>
                          <div>
                            This consent form describes how Sun Tech Co., Ltd. (hereinafter referred to as the Company) will handle the personal information collected when the seller applies for the payment service. By signing this agreement, the buyer (seller) indicates that he/she has read, understood and agreed to accept all the contents of this agreement and its subsequent modifications and changes. If the buyer/seller is under 18 years of age, he/she will not be allowed to use the Service. However, if the buyer/seller has accepted the Service, it is deemed that the buyer/seller agrees to and complies with all of the following regulations. In order to maximize the protection of the buyer's (seller's) personal information, the Company informs the user of the following matters in accordance with Article 8, Paragraph 1 of the Personal Information Protection Act:
                          </div>
                          <br>
                          <div>
                            <span style="font-weight: bolder">I.	Purpose of Collecting Personal Information</span><br>
                            The Company collects personal information from buyers (sellers) for the purpose of confirming their identity and creating a user profile, as well as completing the opening of accounts with the Company, commissioning collection and payment services for transactions with other users, and other services approved by the competent authorities, and at the same time, providing buyers (sellers) with relevant information about the Company's services for completing transactions and handling disputes. Statutory Specific Purposes: “059 Collection, Processing and Utilization of Personal Information by Financial Services Industry as Required by Laws and Regulations and Financial Supervision Needs”, “060 Financial Dispute Processing”, “063 Collection, Processing and Utilization of Personal Information by Non-Public Sector Authorities as Defined by Laws and Regulations”, “067
                            Credit Card, Cash Card, Transit Card, or Electronic Ticket Business”, “069 Contracts, Similar Contracts, or Other Legal Relationships”, “090 Consumer, Client, and Customer Services”, and “090 Consumer, Client, and Customer Relationship Services”. 090 Consumer and customer management and services, 091 Consumer protection, 148 Internet shopping and other e-commerce services, 157 Surveys, statistics and research analyses, and 181 Other business operations in accordance with the items of the business registration or the articles of association.
                          </div>
                          <br>
                          <div>
                            <span style="font-weight: bolder">II.	Types of Data Collected</span><br>
                            Name, address, telephone number, e-mail address, account number applied for by online platforms, records of application for inquiry services, copies of identification documents (e.g., driver's license, health insurance card, etc.) (C001), accounts with financial institutions and copies thereof, credit card-related information, credit reports, proof of financial strength (C002), unified ID card number, passport number (C003), age, gender, nationality, date of birth (C011), time of member registration, IP address, type of browser, cookie, web browser tracking records, and other information collected by this website. C011), member registration time, IP address, browser type, web browser track record, cookies (C132).
                          </div>
                          <br>
                          <div>
                            <span style="font-weight: bolder">III.	Period, Area, Target and Method of Utilization</span><br>
                            The Company utilizes the buyer's (seller's) personal information from the immediate date to the date of termination of the service by the seller or the Company, but if there are other provisions in the laws and regulations or if it is necessary to keep the information according to the law, it shall be according to the provisions. The region of use is Taiwan and the region where the service is provided. Targets are the Company or organizations with which the Company has contractual relationships or business dealings for business purposes (including “financial institutions”, “Taiwan Pay”, “Google Pay”, “Soft Space”, “TapPay”, and “Apple Pay”, etc.); financial supervisory authorities, or authorities authorized by law, or financial supervisory authorities.
                          </div>
                          <br>
                          <div>
                            <span style="font-weight: bolder">IV.	The way of exercising the right to personal information</span><br>
                            Buyers (sellers) may exercise the following rights in accordance with Article 3 of the Personal Information Protection Law:
                          </div>
                          <div class="numbered-list">
                            1. Inquire or request to read.
                          </div>
                          <div class="numbered-list">
                            2. Request to make a copy.
                          </div>
                          <div class="numbered-list">
                            3. Request for supplement or correction.
                          </div>
                          <div class="numbered-list">
                            4. Request to stop the collection, processing, or utilization of personal data.
                          </div>
                          <div class="numbered-list">
                            5. Request deletion.
                          </div>
                          <div>
                            If you wish to exercise the above rights, please contact the Company's customer service at <a href="mailto:service@esafe.com.tw" style="color: #106EBE;">service@esafe.com.tw</a>.
                          </div>
                          <br>
                          <div>
                            <span style="font-weight: bolder">V. Impact on Rights and Interests due to Non-provision of Personal Data</span><br>
                            The buyer (seller) can choose whether to provide information and the completeness of the information provided. However, when certain website functions, services or activities can only be utilized based on the information provided by the buyer (seller), if the buyer (seller) fails to provide complete and accurate personal information, some of the website functions, services or activities may not be able to be utilized, and the Company will not be able to accept applications for user qualifications and relevant payment service qualifications. We apologize for any inconvenience this may cause.
                          </div>
                        </div>
                      </div>
                      <!-- 第三方支付服務契約書 -->
                      <div v-if="activeTab === 'service-terms'" class="details">
                        <div v-if="currentLanguage === 'chinese'">
                          <div>
                            <span style="font-weight: bolder">第一條、 關於紅陽科技</span><br><br>
                            一、本第三方支付服務係由紅陽科技股份有限公司（以下簡稱本公司）向買（賣）家提供的系統服務
                            平台暨代收轉付的服務（以下簡稱本服務）。為了保障買（賣）家的權益，請於註冊（如有）及使用本服務前，
                            詳細閱讀了本契約所有內容。當使用本服務時，即表示買（賣）家已閱讀、理解並同意接受本服務所定之所有內容。
                            若買（賣）家未滿十八歲，並應於買（賣）家的法定代理人閱讀、瞭解且同意本契約之所有內容後，方得使用本服務。
                            但若買（賣）家已接受本服務，即視為已取得法定代理人之同意，並應遵守以下所有規範。<br><br>
                            二、如買（賣）家所屬的國家或地區排除本契約內容之全部或部分，則應立即停止使用本服務。<br><br>
                            三、公司負責人：呂寶麟<br><br>
                            四、客服網址：<a href="mailto:service@esafe.com.tw" style="color: #106EBE;">service@esafe.com.tw</a><br><br>
                            五、公司電話：(02)2502-6969<br><br>
                            六、公司網址：<a href="https://www.sunpay.com.tw/" target="_blank" style="color: #106EBE;">https://www.sunpay.com.tw/</a><br><br>
                            七、客服服務時間：週一～週五 09:00~20:30<br><br>
                            八、營業地址：台北市大安區敦化南路2段65號13樓<br>
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第二條、 以電子文件為表示方法</span><br><br>
                            本公司與買（賣）家間以電子文件為表示方法時，依本契約交換之電子文件，如其內容可完整呈現且可於日後取出供查驗者，其效力與書面文件相同。但依法令或行政機關之公告排除適用者，不在此限。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第三條、 買家帳號、密碼及安全</span><br><br>
                            買（賣）家瞭解並同意，確保密碼及帳號的機密安全是自身的責任。買（賣）家將對利用該密碼、帳號所進行的一切行為及言論，負適當的義務，並同意以下事項：<br><br>
                            一、買（賣）家同意妥善保管帳號、密碼及或任何個人資料。在買（賣）家完成購物或購物等程序後，應採取登出帳戶的動作，尤其是買（賣）家是與他人共用電腦或使用公共電腦，務必要關閉瀏覽器視窗，以防止他人讀取買（賣）家信件或其他個人資料。<br><br>
                            二、買（賣）家同意於發現有第三人冒用或盜用帳號及密碼，或其他任何未經合法授權之情形，應即刻通知本公司，同意暫停其使用本服務並採取防範之措施。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第四條、 守法義務及承諾</span><br><br>
                            一、買（賣）家承諾絕不為任何非法目的或以任何非法方式使用本服務，也不將本服務用於禁止或限制物品的交易，並承諾遵守中華民國相關法律、法規及一切使用網際網路之國際慣例。買（賣）家若係中華民國以外之使用者，買（賣）家同意同時遵守所屬國家或地域之法令。<br><br>
                            二、買（賣）家同意並保證不得利用本服務從事侵害他人權益或違法之行為，若有違反者應負所有法律責任，包含但不限於：<br>
                            （一） 侵害他人名譽、隱私權、商業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。<br>
                            （二） 違反依法律或條款所應負之保密義務。<br>
                            （三） 冒用他人名義使用本服務。<br>
                            （四） 從事任何不法交易行為，如洗錢等。<br>
                            （五） 提供賭博資訊或以任何方式引誘他人參與賭博。<br>
                            （六） 使用無效信用卡號碼，或非使用者本人的信用卡號碼進行交易。<br>
                            （七） 使用他人銀行帳號或無效銀行帳號交易。<br>
                            （八） 利用本服務進行無真實交易背景的虛假交易。<br>
                            （九） 從事任何可能含有電腦病毒或是可能侵害本服務系統、資料之行為。<br>
                            （十） 其他本公司有正當理由認為不適當之行為。<br><br>
                            三、賣家經由本服務接受其交易相對人以信用卡線上刷卡方式支付交易款項時，賣家與其交易相對人間之整筆交易金額，須一次取得授權及請款，賣家不得將其交易攤分作兩筆或多筆金額進行分刷
                            交易或個別取得交易授權號碼，且賣家擔保不得利用本服務從事任何非法交易、非實際消費之簽帳、融資性墊付現款（俗稱調現）或其他變相之融資等交易、或涉及無實質交易基礎之資金傳輸，
                            亦不得從事代其交易相對人先行墊款繳交相關費用再透過本公司向發卡機構請款之行為。賣家違反本項約定者，本公司得保留該等交易款項，如該等交易款項已撥付予賣家，賣家應返還予本公司，
                            本公司並得自賣家於本服務之帳戶內扣還相當於該等交易金額之款項。<br><br>
                            四、信用卡交易收付完成後，若賣家允許買家退貨或取消、終止服務、變更貨品及其價格時，應使用本服務辦理退款，買（賣）家不得以現金方式辦理，以免有刷卡換現金之嫌疑。<br><br>
                            五、賣家(含賣家負責人)及賣家連帶保證人同意本公司或本公司之收單機構或相關服務提供商(包括但不限於台灣PAY及Apple Pay等)，得因合作業務之需要對賣家公司之營業相關資料及
                            其負責人及賣家連帶保證人個人資料，於法令許可範圍內進行蒐集、處理、利用、傳輸及徵信或本服務之定期查核等必要措施以及依規定報送財政部財政資訊中心，及報送財團法人金融聯合
                            徵信中心特約商簽約、解約及風險提報等資料。<br><br>
                            六、本契約於「簽約時」或「有效存續期間內」，賣家承諾絕不會將本契約之權利義務或相關支付工具授與他人或多人使用。賣家並保證營業場所或任一分支據點「將」關閉、遷移或處分前，
                            將無條件立即通知本公司辦理債權債務之清理。<br><br>
                            七、賣家應依據稅法相關規定辦理統一發票開立或報稅事宜。<br><br>
                            八、賣家承諾所營事業性質絕非從事「虛擬資產服務提供者」(即主管機關規定之「虛擬通貨平台及交易業務事業」，例如「網路賭博、股票、期貨、虛擬貨幣、選擇權等」)。買家復同意應依規定與本公司提供之必要教育訓練與法令宣導。<br><br>
                            九、買（賣）家締結之契約不得與本服務為相同類型之金流契約。<br><br>
                            十、如申請本服務刷卡機之實體商店，應於營業現場明顯處揭露交易支付服務係由本服務所提供。<br><br>
                            十一、賣家應將信用卡交易記錄（例如電子交易日誌，付款單，簽單，退款記錄）或相關文件保留五年或更長時間，本公司可以在上述保留期內隨時要求檢查或訪問任何交易的記錄或文件。賣家應在本公司請求之日起三個日曆天內提供此類記錄或文件。<br><br>
                            十二、賣家如選擇使用國外卡服務或本公司要求時，賣家無條件同意配合使用3D認證（3-D Secure，縮寫3DS），確保交易安全。<br><br>
                            十三、賣家使用本服務而知悉關於買家之一切資料，除其他法律或主管機關另有規定者外，須保守秘密。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第五條、 對會員服務的使用</span><br><br>
                            一、「買（賣）家會員」服務說明<br>
                            （一） 一旦買（賣）家註冊成為本公司客戶，本公司將逐筆確認代理買家，在買家或賣家符合指定條件或狀態時，支付款項給賣家。<br>
                            （二） 買（賣）家在使用本服務過程中，畫面上出現的關於交易操作的提示是買（賣）家會員交易的規則，買（賣）家對於交易過程中任何一個頁面的確認表示對買（賣）家會員交易規則的認同。<br>
                            （三） 買家使用本服務進行交易，即表示同意並授權本公司將您經遮罩之個人資訊以及交易資訊回傳通知所指定的交易賣家。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第六條、 商標、知識產權的保護</span><br><br>
                            一、買（賣）家會員平台上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、商業秘密等。<br><br>
                            二、非經本公司或其他權利人書面同意任何人不得擅自使用、修改、複製、公開傳播、改變、散佈、發行或公開發表本網站程式或內容。<br><br>
                            三、如有違反，買（賣）家應對本公司負損害賠償責任。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第七條、 本契約解釋原則</span><br><br>
                            本服務契約條款如有疑義時，應為有利買家或賣家之解釋。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第八條、 本服務內容及費用</span><br><br>
                            一、支付工具：信用卡、金融卡、超商代收、虛擬帳號等服務。<br><br>
                            二、收費項目：系統服務費、交易手續費等。<br><br>
                            三、收費方式：撥款予賣家時內扣手續費。<br><br>
                            四、收費標準：依實際交易金額計收。<br><br>
                            五、收費金額：各項服務費之項目、計算方式及金額請參閱《收費項目》說明。<br><br>
                            六、應收費之對象：與本公司簽約之賣家。<br><br>
                            七、支付流程：<br>
                            （一）信用卡：買家至賣家網站交易→買家輸入信用卡付款資訊→取得買家發卡銀行
                            之授權碼→成功授權後賣家出貨→收單銀行撥款至本公司與信託銀行簽訂信託
                            契約所約定之信託專戶→交易完成後依約定時程由本公司指示信託銀行整批匯
                            款至賣家指定戶頭。<br>
                            （二）金融卡：買家至賣家網站交易→買家使用金融卡轉帳→收單銀行進行扣款→扣
                            款成功後賣家出貨→收單銀行撥款至本公司指定專戶→交易完成後二工作日由
                            本公司整批匯款至賣家指定戶頭。<br>
                            （三）超商代收：買家至賣家網站交易→買家選擇超商代收方式付款→買家至超商付
                            款→代收款機構通知本公司買家已付款→本公司將付款成功訊息通知賣家，賣
                            家出貨→代收款機構撥款至本公司指定專戶→交易完成後依約定時程由本公司
                            整批匯款至賣家指定戶頭。<br>
                            （四）虛擬帳號：買家至賣家網站交易→賣家配發虛擬繳款帳號→買家至各式繳款通
                            路繳款→繳款金額入本公司在銀行申設的虛擬帳戶後→銀行傳遞對帳訊息給本
                            公司並撥款至本公司指定專戶→完成後依約定時程由本公司整批匯款至賣家指
                            定戶頭。<br><br>
                            八、支付帳戶款項提領方式：依賣家與本公司之約定期間（如上述支付流程內說明），
                            由本公司主動指示信託銀行辦理匯撥。<br><br>
                            九、支付款項專用存款帳戶的存款銀行：陽信銀行、第一銀行、凱基銀行。<br><br>
                            十、閘道服務約定：<br>
                            （一） 閘道服務係指透過本服務提供買家介接銀行特約商店或電子支付服務之代理系
                            統整合，僅資料傳輸及統整，本公司不會涉入買（賣）家交易行為、支付款項撥付及
                            其會員管理政策，本服務所產生之閘道服務及處理費用概不退還。<br>
                            （二） 閘道處理費：收取方式為根據買家每月交易總額，依照約定費率，計算出該月
                            實際費用金額，於次月5日將費用總額及明細以電子郵件方式提供給賣家，並於次月10日
                            自所簽署之「自動轉帳付款授權書」中指定之存款帳戶扣款以支付本費用。當扣款成功後，
                            發票將於月底彙整後開立。如扣款失敗，此未支付款項將併入次期計算，或賣家於當月底前支付。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第九條、 匯率之計算</span><br><br>
                            一、本服務買（賣）家所有交易帳款均應以新臺幣結付，不涉及匯率換算。<br><br>
                            二、本契約之交易手續費及撥款淨額以新臺幣「元」為計算單位，交易手續費經過逐筆計算後「元」以下四捨五入。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第十條、 支付款項之保障</span><br><br>
                            本服務支付款項已經分別全部存入與陽信銀行、第一銀行、凱基銀行簽訂信託契約所約定之信託專戶，專款專用。所稱專用，指本公司為履行網路代理收付服務契約之義務所使用。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第十一條、 支付指示之再確認及事後核對</span><br><br>
                            一、本公司於支付完成前，就買家之支付指示，應顯示「確定付款嗎?」之選擇機制（含確定或取消），俾供買家得以再確認支付指示內容是否正確。<br><br>
                            二、本公司應於每筆款項支付完成後，以電子郵件或發送簡訊之方式通知買家支付明細，俾供買家事後核對。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第十二條、 支付錯誤之處理</span><br><br>
                            一、因不可歸責於買家之事由而發生支付錯誤時，本公司應協助買家更正，並提供其他必要之協助。<br><br>
                            二、因可歸責於本公司之事由而發生支付錯誤時，本公司應於知悉時，立即更正，並同時以電子郵件或雙方約定之方式通知買家及賣家。<br><br>
                            三、因可歸責於買家之事由而發生支付錯誤時，例如買家輸入錯誤之金額或輸入錯誤之收款方，經買家通知後，本公司應立即協助處理。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第十三條、 資訊安全</span><br><br>
                            一、本服務是經由信用卡國際組織規定的PCIDSS認證再加上國際認證標準SSL安全加密機制，確保買（賣）家的任何資料絕不外洩。<br><br>
                            二、本公司、買（賣）家應各自確保所使用資訊系統之安全，防止非法入侵、取得、竄改、毀損業務\紀錄或買家之個人資料。<br><br>
                            三、第三人破解本公司資訊系統之保護措施或利用資訊系統之漏洞爭議，由本公司就該事實不存在負舉證責任。<br><br>
                            四、第三人入侵網路代理收付服務資訊系統對買（賣）家所造成之損害，由本公司負擔。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第十四條、 本公司之終止契約或暫停服務</span><br><br>
                            本公司於下列情形，得終止契約或延後付款、暫停本服務：<br>
                            一、有相當事證足認買（賣）家有利用支付服務為洗錢、詐欺等犯罪行為或不法行為者。<br><br>
                            二、支付款項經法院裁定或檢察官命令扣押者。<br><br>
                            三、買（賣）家提交虛偽之身分認證資料，經查證屬實者。<br><br>
                            四、買（賣）家由於不履行本契約任一規定之義務者。<br><br>
                            五、賣家有解散、停業、歇業、重整、無力償債或宣告破產、清算、併購等情事；或賣家有受強制執行、假執行、假處分、假扣押之情事；或賣家之全部營業資產之所有權或營業經營權讓與他人者。<br><br>
                            六、本契約關係經終止或消滅時，賣家已繳交之租用費及系統服務費等不予退還，並得按各信用卡國際組織規範，從末筆消費日起算540個工作天，
                            經本公司確定無違約、違法之事由或本公司認定賣家不會有潛在被扣款責任發生之時止，賣家始得請求本公司返還應給付予乙方之餘額，且賣家不得
                            向本公司請求利息。前述540天保留款規定，於本條所揭之延後撥款約定，亦適用之。本契約之終止並不免除或影響終止日前賣家依本契約規定對所有
                            交易之一切責任或保證，亦不影響終止之時有效或終止後仍繼續有效之本契約相關條款之責任。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第十五條、 帳號密碼被冒用之處理</span><br><br>
                            一、本公司或買（賣）家於知悉帳號密碼被冒用時，應立即通知他方並暫停該帳號所指示之支付行為暨暫停該帳號後續之支付指示。<br><br>
                            二、本公司應於服務網頁明顯處載明帳號密碼被冒用時的連絡方式 service@esafe.com.tw、@sunpay、(02)2502-6969，除有不可抗力或其他重大事由，通知電話或電子郵件或通訊媒體之服務時間應為全日（24小時）全年（365日）無休。<br><br>
                            三、買（賣）家辦理帳號密碼被冒用手續完成後所發生之損失，概由本公司負擔；其辦理帳號密碼被冒用手續前所發生之損失，於有下列情事時，悉由買（賣）家負擔：<br>
                            （一）買（賣）家未妥善保管帳號密碼。<br>
                            （二）買（賣）家自行將帳號密碼提供與他人。<br>
                            （三）買（賣）家未使用本公司所提供的帳號安全機制。<br>
                            （四）因買（賣）家之故意或重大過失所致之事由。<br><br>
                            四、調查買（賣）家帳號密碼被冒用所生之費用，由本公司負擔。<br><br>
                            五、本公司基於保護買（賣）家安全之理由，於發現或接獲買（賣）家通知有本條第一項情形時，立即停止該帳號使用本服務，直至該異常狀況解除為止，本公司並得要求買（賣）家出具相關文件（包含但不限於：警政機關報案證明、切結書、身分證明文件等），以恢復該帳號於本服務之使用。<br><br>
                            六、買（賣）家同意於使用本服務時，本公司得就使用者登入資訊（包括網路IP位置與時間）、所為之行為及其他依法令應留存之紀錄予以詳實記錄。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第十六條、 買家之終止契約</span><br><br>
                            買家得依雙方約定之方式隨時終止契約。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第十七條、 契約條款變更</span><br><br>
                            一、本公司如欲變更契約內容，應於網站明顯處公告，並以電子郵件或雙方約定之方式通知買（賣）家，買（賣）家經本公司預告10日未異議者，視為同意契約內容之變更。<br><br>
                            二、本公司未依前項進行公告及通知者，其契約之變更無效。但有利於買（賣）家的變更不在此限。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第十八條、 爭議處理</span><br><br>
                            一、消費爭議之客服電話：02-25026969及電子郵件信箱 service@esafe.com.tw。<br><br>
                            二、申訴、爭議處理機制及程序：由客服受理買（賣）家爭議暨申訴→買（賣）家填寫「線上客服處理表」→依買（賣）家爭議暨申訴內容會辦相關單位→經辦單位先判斷
                            是否可歸責本公司之疏失→若是，則依本契約檢討改善並採取補救措施→回覆客戶；若否，則逕回覆客戶→填覆客服人員「線上客服處理表」→客服人員與客戶確認是否接受？
                            若接受，交行政部主管核決→客服人員結案歸檔；若不接受，則交回經辦單位重新處理。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第十九條、 買（賣）家之身分認證</span><br><br>
                            一、買（賣）家應確認提交之身分認證資料正確且真實，不得有虛偽情事，如該等資料事後有變更，應立即通知本公司。<br><br>
                            二、本服務會員身分認證機制如下：<br>
                            （一）買家：身分認證資料填妥並上傳後，本服務自動向內政部戶役政系統查證，經本服務伺服器判斷正確後即完成會員身分認證。<br>
                            （二）賣家：身分認證資料填妥後，由本公司徵信人員照會本人，經判斷為真後即完成會員身分認證。<br><br>
                            三、如有下列情形之一，本公司得要求買（賣）家再次進行身分認證程序：<br>
                            （一）買（賣）家申請變更基本身分資料。<br>
                            （二）買（賣）家提供之身分證明文件或登記證照等相關文件疑似偽造或變造。<br>
                            （三）同一行動電話號碼、電子郵件信箱等遭不同買（賣）家用於身分確認程序。<br>
                            （四）發現疑似洗錢或資助恐怖主義交易，或自洗錢或資助恐怖主義高風險國家或地區匯入款項之交易時。<br>
                            （五）對於所取得買（賣）家身分資料之真實性或妥適性有所懷疑時。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第二十條、 賠償責任</span><br><br>
                            一、賣家使用本公司金流服務時，如賣家出於任何原因與買家發生糾紛致買家向發卡銀行或收單機構請求拒付或索回其向本公司支付的全部或部分款項時，於發卡銀行拒付或對本公司強制扣款時，賣家同意無條件授權本公司依實際情形先行賠償或補償買家、發卡銀行或收單機構之損失或損害，賣家並同意於本公司為前揭賠償或補償後，應無條件賠償或補償本公司因此所生之一切損失或損害。<br><br>
                            二、賣家未依本契約約定處理帳款(例如返還退貨款等)，致本公司或收單機構或發卡銀行所受損害，賣家應負賠償之責。<br><br>
                            三、賣家經營國內法令、國際信用卡組織制定之規範及收單機構規定禁止銷售之產品或服務，包括但不限於色情、賭博性交易、煙酒、藥品、醫療用品及仿冒品等，致本公司或收單機構或發卡銀行所受損害，賣家應負賠償之責。<br><br>
                            四、賣家依本條前三項規定而應負賠償之責時，賣家無條件同意並授權本公司於當期或未來應給付賣家之貨款中逕行抵銷，不足部分並得自其他擔保品扣抵。<br><br>
                            五、本服務若因電信機線設備，因障礙、延遲、中斷無法傳遞而造成損害或錯誤時，其所生之損害，本公司不負賠償責任。<br><br>
                            六、如因颱風、地震、洪水、海嘯、停電、戰爭、火災等不可抗力之因素造成系統障礙不能執行業務者，本公司不負責善後及賠償事宜。<br><br>
                            七、如因收單銀行或發卡銀行之線路或系統問題，造成交易中斷或交易無法進行，或因本服務之配合銀行因其業務方向變更、或支付工具系統商對本服務承作意願改變、或因政府法令影響或主管機關質疑，以致中斷本服務之連線或使買（賣）家之交易無法進行，將由本公司主動查明原因，並告知買（賣）家，惟本公司不負責買（賣）家之損失。<br><br>
                            八、賣家如選擇使用本服務之實體刷卡機，賣家對於提供之設備應盡善良管理人之保管責任，不得任意拆除或遷移，如有不當使用之損害、遺失、被竊或滅失情形，賣家同意依照收費表進行賠償。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第二十一條、 通知及合作方式</span><br><br>
                            一、賣家及本公司同意以本契約所載之地址或電子郵件或傳真為相關文書之送達方式，倘賣家 (含其連帶保證人)之地址或電子郵件或傳真或其他之聯絡資訊有變更，賣家及連帶保證人應即以書面或其他約定方式通知本公司，並同意改依變更後之地址或電子郵件或傳真為送達方式；如賣家及連帶保證人未以書面或依約定方式通知變更時，本公司仍以本契約所載之地址或電子郵件或傳真為送達，於通知發出後，經通常之郵遞或傳輸期間即推定為已送達。<br><br>
                            二、除本契約書另有約定外，賣家及本公司同意當事人之一方以本契約書所載之電子郵件信箱為帳款通知者，視為已有民法所定通知之效力，但賣家及本公司經通知之電子郵件應保存壹年，以為查核。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第二十二條、 有效期限</span><br><br>
                            本服務除另有書面約定者外，本契約書之有效期間，自契約關係生效之日起一年。本契約除非買（賣）家於到期日或再續約到期日之30日前，以書面通知不再續約外，期滿且本公司收到續約相關費用後，前述法律關係將自動延續一年，其後亦同。
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">第二十三條、 準據法及管轄法院</span><br><br>
                            一、本服務之爭議應以中華民國法律為準據法。<br><br>
                            二、因本服務所生之一切爭議，立約人同意本誠信原則協議解決之；如有訴訟之必要時，除法律另有規定外，買（賣）家同意以臺灣臺北地方法院為第一審管轄法院。
                          </div>
                        </div>
                        <div v-else>
                          <div>
                            <span style="font-weight: bolder">Article 1: About Sun Tech Co., Ltd.</span><br><br>
                            1. The payment service is a system services platform and collection/disbursement service provided
                            by Sun Tech. Co., Ltd. (hereinafter referred to as the Company) to buyers (sellers) (hereinafter
                            referred to as the Service). In order to protect the buyer's (seller's) rights, please read this
                            Agreement in detail before registering (if any) and using the Service. By using the Service, the
                            buyer (Seller) has read, understood and agreed to accept all the contents of the Service. If the
                            buyer (seller) is under 18 years old, he/she should use the Service only after the buyer’s (seller’s)
                            legal representative reads, understands and agrees to all the contents of this Agreement. However,
                            if the buyer (seller) has accepted the Service, he/she is deemed to have obtained the consent of his/her
                            legal representative and shall comply with all of the following regulations.<br><br>
                            2. If the buyer (seller) belongs to a country or region that excludes all or part of the contents of
                            this contract, then you should immediately stop using the service.<br><br>
                            3. The Person in Charge of the Company: Mr. Lu Bao Lin<br><br>
                            4. Customer Service Website: <a href="mailto:service@esafe.com.tw" style="color: #106EBE;">service@esafe.com.tw</a><br><br>
                            5. Company Telephone: (02)2502-6969<br><br>
                            6. Company Website: <a href="https://www.sunpay.com.tw/" target="_blank" style="color: #106EBE;">https://www.sunpay.com.tw/</a><br><br>
                            7. Customer Service Hours：Monday～Friday 09:00~20:30<br><br>
                            8. Company Address：13F, No. 65, Sec. 2, Dunhua S. Rd., Taipei City
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 2: Representation by Electronic Documents</span><br><br>
                            When the Company and the buyer (seller) use electronic documents as a method of representation,
                            the electronic documents exchanged pursuant to this Agreement shall have the same validity as
                            written documents if their contents can be presented in full and can be retrieved for inspection
                            in the future. However, this shall not apply to the exclusion of the application of the law or the
                            announcement of the administrative agency.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 3: Buyer's Account, Password and Security</span><br><br>
                            The buyer (seller) understands and agrees that it is his/her own responsibility to ensure the
                            confidential security of the password and account. The buyer (seller) will be the use of the
                            password, account for all acts and speech, the appropriate obligations, and agree to the following
                            matters:<br><br>
                            1. The buyer (seller) agrees to keep the account, password and or any personal information in a safe
                            place. After the buyer (seller) completes the purchase or shopping process, etc., the buyer (seller)
                            should take steps to log out of the account. After the buyer (seller) has completed the shopping or
                            purchase, etc., he/she should take the action of logging out of the account, especially if the buyer
                            (seller) is sharing the computer with others or using a public computer, he/she should close the
                            browser window to prevent others from using the account. In particular, if the buyer (seller) is
                            sharing a computer with others or using a public computer, it is necessary to close the browser
                            window to prevent others from reading the buyer’s (seller’s) mail or other personal information.
                            The buyer (seller) agrees to notify the Company immediately if he/she discovers that a third party
                            has fraudulently used or stolen his/her account and password, or any other unauthorized
                            circumstances, and agrees to suspend the account.<br><br>
                            2. The buyer (seller) agrees to notify the Company immediately if he/she discovers any fraudulent
                            or unauthorized use of the account and password by a third party or any other unauthorized
                            situation, and agrees to suspend his/her use of the Service and take preventive measures.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 4: Obligations and Commitments</span><br><br>
                            1. The buyer (seller) undertakes that he/she will not use the service for any illegal purpose or
                            in any illegal way, nor will he/she use the service for the transaction of prohibited or
                            restricted items. The buyer (seller) undertakes not to use the service for any illegal purpose or
                            in any illegal way, nor to use the service for the transaction of prohibited or restricted items,
                            and undertakes to abide by the relevant laws and regulations of the Republic of China (R.O.C.) as
                            well as all the international practices on the use of the Internet. If the buyer (seller) is a
                            user outside of the Republic of China, the buyer (seller) agrees to comply with the laws and
                            regulations of the country or region to which the buyer (seller) belongs.<br><br>
                            2. The buyer (seller) agrees and warrants that he/she shall not use the Service to engage in any
                            infringement of the rights of others or illegal behavior, and shall be held legally responsible
                            for any violation of the law. The buyer (seller) agrees and guarantees that he/she shall not use
                            the Service to engage in any behavior that infringes on the rights of others or violates the law:
                            <br>
                            (a) Infringement of others' reputation, privacy, trade secrets, trademarks, copyrights, patents,
                            other intellectual property rights and other rights.<br>
                            (b) Breach of confidentiality obligations in accordance with the law or these terms and conditions.<br>
                            (c) Use the Services in the name of another person.<br>
                            (d) Engage in any illegal trading behavior, such as money laundering.<br>
                            (e) Providing gambling information or inducing others to gamble in any way.<br>
                            (f) Using an invalid credit card number, or a credit card number that is not the user's own, to
                            conduct transactions.<br>
                            (g) Using another person's bank account or invalid bank account to make transactions.<br>
                            (h) Using the Service to conduct false transactions without a real transaction background.<br>
                            (i) Engaging in any behavior that may contain computer viruses or that may infringe on the system
                            and data of the Service.<br>
                            (j) Any other behavior that the Company has reasonable grounds to believe is inappropriate.<br><br>
                            3. When a seller accepts online credit card payment from his/her counterparty through the Service,
                            the entire transaction amount between the seller and his/her counterparty shall be authorized and
                            requested at one time, and the seller shall not divide his/her transaction into two or more
                            amounts for swiping transactions or obtaining a transaction authorization number individually, and
                            the seller guarantees that he/she shall not engage in any illegal transactions or signing accounts
                            that are not based on actual consumption with the use of the Service, The seller guarantees that
                            the Service shall not be used to engage in any illegal transactions, non-actual consumption
                            transactions, cash advances (commonly known as “cash-in”) or other disguised financing
                            transactions, or transactions involving the transmission of funds without a substantive
                            transaction basis, nor shall the seller engage in the act of advancing funds on behalf of his/her
                            counterparts to pay the relevant fees and then requesting payment from the card issuer through the
                            Company. In the event that the seller violates this agreement, the Company may retain the
                            transaction funds, and if the transaction funds have been allocated to the seller, the seller
                            shall return them to the Company, and the Company shall be entitled to retain the transaction
                            funds from the seller. If the transaction amount has been paid to the seller, the seller shall
                            return it to the Company and the Company may debit the seller's account with the Service with an
                            amount equivalent to the transaction amount.<br><br>
                            4. After the credit card transaction is completed, if the seller allows the buyer to return the
                            goods or cancel, terminate the service, change the goods and their prices, the service should be
                            used to process the refund, the buyer (seller) shall not be processed in cash, so as to avoid the
                            suspicion of swiping the card for cash.<br><br>
                            5. The Service may collect, process, utilize, transmit, and conduct credit or periodic checks of
                            the seller's personal information within the scope permitted by law, as well as report such
                            information to the Financial Information Center of the Ministry of Finance, and to the Joint
                            Financial Credit Center for contract signing, termination of contracts, and risk reporting.<br><br>
                            6. Seller covenants that at the time of execution or during the term of this Agreement, seller
                            will not license the rights and obligations of this Agreement or the related payment instruments
                            to any other person or persons. The seller also undertakes to notify the Company unconditionally
                            and immediately before the closure, relocation or disposition of the business premises or any
                            branch office for the purpose of liquidation of debts and liabilities.<br><br>
                            7. Sellers should follow the relevant provisions of the tax law to handle unified invoicing or
                            tax filing matters.<br><br>
                            8. The seller undertakes that the nature of the business is not in any way engaged in “virtual
                            asset service provider” (i.e. “virtual currency platform and trading business” as stipulated by
                            the competent authorities, such as “online gambling, stocks, futures, virtual currency, options,
                            etc.”). The buyer also agrees to participate in the necessary training and legal awareness
                            programs provided by the Company.<br><br>
                            9. The contract entered into by the buyer (seller) shall not be the same type of financial
                            contract as the Service.<br><br>
                            10. If a merchant applies for a swipe machine of this service, it should disclose in a conspicuous
                            place at the business site that the transaction payment service is provided by this service.<br><br>
                            11. The seller shall retain credit card transaction records (e.g., electronic transaction logs,
                            payment orders, signatures, refund records) or related documents for a period of five (5) years or
                            longer, and the Company may at any time during the said retention period request to inspect or
                            have access to the records or documents of any transaction. The Seller shall provide such records
                            or documents within three calendar days from the date of the Company's request.<br><br>
                            12. If the seller chooses to use foreign card services or when requested by the Company, the
                            seller unconditionally agrees to cooperate with the use of 3-D Secure (abbreviated as 3DS) to
                            ensure the security of the transaction.<br><br>
                            13. The seller shall keep all information about the buyer known to the seller through the use of
                            this service confidential, except as otherwise provided by other laws or competent authorities.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 5: Use of Member Services</span><br><br>
                            1. Description of “buyer (seller) Member” Service<br>
                            (a) Once a buyer (seller) registers as a customer of the Company, the Company will confirm the
                            agent buyer one by one and pay the seller when the buyer or seller meets the specified conditions
                            or status.<br>
                            (b) The buyer (seller) in the process of using the Service, the screen appears on the trading
                            operation of the prompts is the buyer (seller) members of the rules of the transaction, the buyer
                            (seller) in the transaction process of any page of the confirmation of the buyer (seller) members
                            of the rules of the transaction of the recognition.<br>
                            (c) By using the Service to conduct transactions, the buyer agrees and authorizes the Company to
                            notify the designated seller of the transaction by sending back your masked personal information
                            and transaction information.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 6: Protection of Trademarks and Intellectual Property Rights</span><br><br>
                            1. All the contents on the platform of buyer (seller) members, including but not limited to
                            copyrights, pictures, files, information, data, website structure, website screen arrangement, web
                            design, are owned by the Company or other right holders in accordance with the law of their
                            intellectual property rights, including but not limited to trademarks, patents, copyrights, trade
                            secrets, and so on.<br><br>
                            2. Anyone shall not use, modify, copy, publicly disseminate, change, distribute, publish or
                            publicly release the program or content of this website without the written consent of the Company
                            or other right holders.<br><br>
                            3. If there is any violation, the buyer (seller) shall be liable for damages to the Company.
                          </div>
                          <br><br>
                          <div>
                      <span
                          style="font-weight: bolder">Article 7: Principles of Interpretation of This Agreement</span><br><br>
                            In case of doubt, the terms of this service contract shall be interpreted in favor of the buyer or
                            seller.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 8: Contents and Fees of the Service</span><br><br>
                            1. Payment tools: credit card, financial card, superstore collection, virtual account and other
                            services.<br><br>
                            2. Charges: System service fees, transaction fees, etc.<br><br>
                            3. Charging method: Deducting the handling fee when allocating funds to the seller.<br><br>
                            4. Fee standard: Based on the actual transaction amount.<br><br>
                            5. Amount of fees: Please refer to the description of “Fee Items” for the items, calculation
                            method and amount of each service fee.<br><br>
                            6. The target of the fee: sellers who sign a contract with the Company.<br><br>
                            7. Payment process:<br>
                            (a) Credit card: buyer transacts on the seller's website → buyer enters credit card payment
                            information → buyer obtains the authorization code of the buyer's card-issuing bank → after
                            successful authorization, the seller ships the goods → the acquiring bank transfers the funds to
                            the trust account stipulated in the trust deed signed between the Company and the trust bank →
                            after completion of the transaction, according to the stipulated timetable, the Company instructs
                            the trust bank to remit the entire amount of the funds to the seller's designated account.<br>
                            (b) Financial Card: buyer transacts on the seller's website → buyer uses financial card to
                            transfer funds → Acquiring bank debits the funds → Seller ships the goods after successful debit
                            → Acquiring bank transfers funds to the Company's designated account → Company remits the entire
                            batch of funds to the seller's designated account within two working days after the transaction
                            is completed.<br>
                            (c) Supermarket collection: The buyer goes to the seller's website to make the transaction → the
                            buyer chooses the supermarket collection method to make the payment → the buyer goes to the
                            supermarket to make the payment → the collection agency notifies the Company that the buyer has
                            made the payment → the Company notifies the seller of the successful payment and the seller ships
                            the goods → the collection agency allocates the funds to the Company's designated special account
                            → the Company remits a whole batch of funds to the seller's designated account in accordance with
                            the agreed schedule after the transaction is completed.<br>
                            (d) Virtual account: buyer transacts on the seller's website → seller allocates a virtual payment
                            account → buyer pays the money at each payment gateway → payment is credited to the Company's
                            virtual account at the bank → the bank transmits a reconciliation message to the Company and
                            allocates funds to the Company's designated special account → after completion of the transaction,
                            the Company remits the entire amount of funds to the seller's designated account in accordance
                            with the agreed time schedule.<br><br>
                            8. Payment account withdrawal method: In accordance with the agreement period between the seller
                            and the Company (as described in the payment process above), the Company will take the initiative
                            to instruct the trust bank to handle the transfer.<br><br>
                            9. Banks with dedicated deposit accounts for payment: Sunny Bank, First Bank, KGI Bank.<br><br>
                            10. Gateway Service Contract:<br>
                            (a) Gateway service refers to the integration of the agent system through this service to provide
                            buyers with access to the bank's special stores or electronic payment services, only the
                            transmission and consolidation of data, the Company will not be involved in the transaction
                            behavior of the buyer (seller), payment disbursement and its member management policies, the
                            gateway service and processing fees generated by this service are not refundable. The gateway
                            service and processing fees generated from this service are non-refundable.<br>
                            (b) Gateway Processing Fee: The method of collection is based on the total amount of monthly
                            transactions of the buyer, in accordance with the agreed rate, the actual amount of fees for the
                            month, the total amount of fees and details will be provided to the seller by email on the 5th of
                            the following month, and on the 10th of the following month, the amount of fees will be deducted
                            from the deposit account specified in the signed “Authorization for Payment by Auto-Payment” to
                            pay for this fee. When the deduction is successful, an invoice will be issued at the end of the
                            month. If the deduction fails, the unpaid amount will be rolled over to the next installment or
                            paid by the Seller before the end of the month.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 9: Calculation of Exchange Rate</span><br><br>
                            1. All transaction payments by buyers (sellers) of this service shall be settled in NTD, and no
                            exchange rate conversion is involved.<br><br>
                            2. The transaction handling fee and the net amount of funding under this contract shall be
                            calculated in NT$, and the transaction handling fee shall be rounded down to the nearest NT$ after
                            the calculation of each transaction.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 10: Protection of Payment</span><br><br>
                            The payment for the service has been deposited into the trust accounts stipulated in the trust
                            agreement with Sunny Bank, First Bank, and KGI Bank, and the funds are used exclusively for this
                            purpose. The so-called exclusive use refers to the use by the Company to fulfill its obligations
                            under the contract of the Internet agent collection and payment service.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 11: Re-Confirmation and Post-Verification of Payment Instruction</span><br><br>
                            1. The Company shall display the “Payment Confirm” option mechanism (including confirm or cancel)
                            for the buyer's payment instructions before the payment is completed.<br><br>
                            2. The Company shall notify the buyer of the payment details by email or SMS after each payment is
                            made for subsequent checking by the buyer.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 12: Handling of Payment Errors</span><br><br>
                            1. In the event of a payment error that is not attributable to the buyer, the Company shall assist
                            the buyer in making corrections and provide other necessary assistance.<br><br>
                            2. If a payment error occurs due to reasons attributable to the Company, the Company shall correct
                            the error as soon as it becomes aware of it and notify the buyer and the seller at the same time
                            by e-mail or in a manner agreed upon by both parties.<br><br>
                            3. If a payment error occurs due to something attributable to the buyer, such as the buyer
                            entering the wrong amount or the wrong payee, the Company shall immediately assist in dealing with
                            the error after the buyer notifies the Company of the error.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 13: Information Security</span><br><br>
                            1. The service is certified by the PCIDSS of the credit card international organization plus the
                            SSL security encryption mechanism of the international certification standard to ensure that any
                            information of the buyer (seller) will never be leaked.<br><br>
                            2. The Company and the buyer (seller) shall each ensure the security of the information system
                            used to prevent unauthorized intrusion, acquisition, modification, and destruction of business
                            records or buyer's personal information.<br><br>
                            3. The Company shall bear the burden of proving the non-existence of the fact that a third party
                            has cracked the protection measures of the Company's information system or utilized the loopholes
                            of the information system in disputes.<br><br>
                            4. The Company shall be liable for any damages caused to the buyer (seller) by a third party
                            hacking into the information system of the Internet proxy payment service.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 14: Termination or Suspension of Service by the Company</span><br><br>
                            The Company may terminate the contract, delay payment, or suspend the service under the following
                            circumstances:<br><br>
                            1. If there is evidence that the buyer (seller) has used the payment service for money laundering,
                            fraud, or other criminal or unlawful acts.<br><br>
                            2. If the payment has been seized by a court decision or a prosecutor's order.<br><br>
                            3. If the buyer (seller) submits false identification information and the information is verified
                            to be true.<br><br>
                            4. The buyer (seller) fails to fulfill any of the obligations stipulated in this agreement.<br><br>
                            5. The seller is dissolved, suspended, closed, reorganized, insolvent or declared bankrupt,
                            liquidated, or merged; or the seller is subject to compulsory enforcement, false enforcement,
                            false penalty, or false seizure; or the seller's ownership of all business assets or the right to
                            operate the business has been transferred to another person.<br><br>
                            6. Upon termination or extinguishment of this Agreement, the seller shall not be entitled to any
                            refund of the rental fee and system service fee paid by the seller, and shall be entitled to
                            request the Company to return the remaining balance of the payment due to the seller in accordance
                            with the regulations of the international organizations of credit cards for a period of 540 days
                            from the date of the last purchase, and shall not be entitled to claim interest from the Company
                            after the Company has determined that there is no violation of the Agreement, no violation of law,
                            or that the seller will not be liable to any potential chargeback. The seller may not claim
                            interest from us. The foregoing 540-day retention provision shall also apply to the deferred
                            payment covenant set forth in this Section. The termination of this Deed does not release or
                            affect all obligations or warranties of the seller under this Deed with respect to all
                            Transactions prior to the date of termination, nor does it affect the obligations of the relevant
                            provisions of this Deed in effect at the time of termination or continuing in effect after
                            termination.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 15: Handling of Unauthorized Use of Account and Password</span><br><br>
                            1. When the Company or the buyer (seller) becomes aware of the unauthorized use of an account and
                            password, they shall immediately notify the other party and suspend payment activities associated
                            with the account, as well as any subsequent payment instructions from that account.<br><br>
                            2. The Company shall prominently display contact information for reporting unauthorized use of an
                            account and password on its service webpage, including service@esafe.com.tw, @sunpay, and
                            (02)2502-6969. Except in cases of force majeure or other significant reasons, the service hours
                            for notification via phone, email, or communication media shall be available 24 hours a day, 365
                            days a year.<br><br>
                            3. The Company shall be responsible for any loss incurred after the completion of the procedure
                            for fraudulent use of the buyer's (seller's) account password. Any loss incurred before the
                            procedure for fraudulent use of the buyer's (seller's) account password shall be borne by the
                            buyer (seller's) in all of the following cases:<br>
                            (a) The buyer (seller) does not keep the account password properly.<br>
                            (b) The buyer (seller) provides the account number and password to others.<br>
                            (c) The buyer (seller) does not use the account security mechanism provided by the Company.<br>
                            (d) The buyer’s (seller’s) willfulness or gross negligence.<br><br>
                            4. The Company shall bear the cost of investigating the fraudulent use of the buyer's or seller's
                            account password.<br><br>
                            5. For the reason of protecting the safety of the buyer (seller), when the Company finds or
                            receives notification from the buyer (seller) that there is a situation in the first paragraph of
                            this article, it will immediately stop the account from using the Service until the abnormal
                            situation is resolved, and the Company may require the buyer (seller) to produce relevant documents
                            including but not limited to: proof of report to the police, a written statement of the cut-off,
                            and proof of identity, etc.), in order to resume the account's use of the Service.<br><br>
                            6. The buyer (seller) agrees that when using the Service, the Company shall keep a detailed record
                            of the user's login information (including network IP location and time), behavior, and other
                            records required by law to be kept.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 16: Termination of the Contract by the Buyer</span><br><br>
                            The buyer may terminate the contract at any time in the manner agreed upon by both parties.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 17: Changes to the Terms of the Contract</span><br><br>
                            1. If the Company wishes to change the content of the contract, the Company shall make a notice in
                            a prominent place on the website and notify the buyer (seller) by e-mail or in a manner agreed upon
                            by both parties. If the buyer (seller) does not object to the change after 10 days of the Company's
                            notice, the buyer (seller) shall be deemed to have agreed to the change in the content of the
                            contract.<br><br>
                            2. If the Company fails to announce and notify the buyer (seller) in accordance with the preceding
                            paragraph, the change of the contract shall be invalid. However, changes in favor of the buyer
                            (seller) are not subject to this limitation.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 18: Dispute Handling</span><br><br>
                            1. Customer service hotline for consumer disputes: 02-25026969 and e-mail: service@esafe.com.tw.<br><br>
                            2. Complaint and dispute handling mechanism and procedure: Customer service accepts buyer's
                            (seller's) dispute and complaint → buyer (seller) fills in the“Online Customer Service Handling
                            Form”→ buyer (seller) meets with relevant units according to buyer (seller's) dispute and
                            complaint →The unit in charge first determines whether or not it is attributable to the Company's
                            negligence. → If yes, review and take remedial measures in accordance with this contract → reply
                            to the customer; if not, reply to the customer directly → fill in the“Online Customer Service
                            Handling Form”of the customer service staff → customer service staff confirms with the customer
                            whether or not to accept the form. If accepted, submit to the supervisor of the Administration
                            Department for approval → Customer service personnel close the case and file it; if not accepted,
                            return it to the handling unit for re-processing.
                          </div>
                          <br><br>
                          <div>
                      <span
                          style="font-weight: bolder">Article 19: Identification of Buyers (Sellers)</span><br><br>
                            1. The buyer (seller) shall confirm that the information submitted for identification is correct
                            and true, and shall not be false, and shall immediately notify the Company of any subsequent
                            changes in such information.<br><br>
                            2. The member identification mechanism of the service is as follows:<br>
                            (a) Buyer: After the identity verification information is filled in and uploaded, the service will
                            automatically check with the Ministry of the Interior's household service system, and the member
                            identity verification will be completed after the server of the service has judged it to be correct.<br>
                            (b) Seller: After the identity verification information is filled in, the Company's credit
                            counseling staff will notify the person himself/herself, and membership verification will be
                            completed after the information is judged to be true.<br><br>
                            3. The Company may request the buyer (seller) to go through the identity verification process
                            again under any of the following circumstances:<br>
                            (a) The buyer (seller) applies for a change of basic identity information.<br>
                            (b) The buyer (seller) provides identity documents or registration certificates or other related
                            documents that are suspected to be forged or falsified.<br>
                            (c) The same mobile phone number or e-mail address is used by different buyers (sellers) in the
                            identity verification process.<br>
                            (d) When suspected money laundering or terrorist financing transactions are discovered, or when
                            money is transferred from countries or regions with high risk of money laundering or terrorist
                            financing.<br>
                            (e) When there is doubt about the authenticity or appropriateness of the identity information
                            obtained from the buyer (seller).<br>
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 20: Liability</span><br><br>
                            1. If a seller, while using the Company's payment services, engages in a dispute with a buyer for
                            any reason, resulting in the buyer requesting an chargeback or refund of all or part of the payment
                            made to the Company through the issuing bank or acquiring institution, and if the issuing bank
                            initiates a chargeback or forcibly deducts the payment from the Company, the seller agrees to
                            unconditionally authorize the Company to, based on the actual circumstances, first compensate or
                            reimburse the buyer, issuing bank, or acquiring institution for any losses or damages. Furthermore,
                            the seller agrees to unconditionally compensate or reimburse the Company for all resulting losses
                            or damages incurred by the Company after such compensation or reimbursement is made.<br><br>
                            2. If the seller fails to handle payments in accordance with the terms of this agreement
                            (e.g., refunding payments for returned goods), causing damage to the Company, the acquiring
                            institution, or the issuing bank, the seller shall be liable for compensation.<br><br>
                            3. If the seller engages in the sale of products or services prohibited by domestic laws,
                            regulations of international credit card organizations, or the rules of the acquiring institution
                            — including but not limited to pornography, gambling transactions, tobacco, alcohol,
                            pharmaceuticals, medical supplies, and counterfeit goods — resulting in damage to the Company,
                            the acquiring institution, or the issuing bank, the seller shall be liable for compensation.<br><br>
                            4. When the seller is liable for compensation according to the provisions of the previous three
                            items in this article, the seller unconditionally agrees and authorizes the Company to offset the
                            compensation from the current or future payments due to the seller. Any remaining amount may be
                            deducted from other collateral.<br><br>
                            5. The Company shall not be liable for compensation for any damages caused by obstacles, delays,
                            or interruptions in telecommunication lines and equipment that prevent the transmission of
                            services.<br><br>
                            6. In the event that system disruptions occur due to force majeure factors such as typhoons,
                            earthquakes, floods, tsunamis, power outages, wars, fires, or other uncontrollable events, the
                            Company shall not be responsible for any subsequent matters or compensation.<br><br>
                            7. If transaction interruptions or failures occur due to issues with the acquiring bank's or
                            issuing bank's systems or networks, or if the associated bank for this service changes its
                            business direction, or if the payment system provider alters its willingness to support this
                            service, or if the government’s regulations or authorities' concerns affect the continuity of
                            this service, resulting in service disconnections or transactions being unable to proceed, the
                            Company will investigate the cause and notify the buyer (seller). However, the Company shall not
                            be responsible for any losses incurred by the buyer (seller).<br><br>
                            8. If the seller chooses to use the physical credit card terminal provided by the service, the
                            seller shall be responsible for the proper management and safekeeping of the equipment as a
                            prudent manager. The seller shall not dismantle or relocate the equipment. In the event of
                            improper use, damage, loss, theft, or destruction, the seller agrees to compensate according to
                            the fee schedule.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 21: Notification and Cooperation Methods</span><br><br>
                            1. The seller and the Company agree to use the address, email, or fax number specified in this
                            contract as the delivery method for related documents. If the seller (including its joint
                            guarantors) changes their address, email, fax number, or other contact information, the seller
                            and joint guarantors shall immediately notify the Company in writing or through other agreed-upon
                            methods. They also agree to use the updated address, email, or fax number as the new delivery
                            method. If the seller and joint guarantors fail to notify the Company in writing or as agreed,
                            the Company may continue to deliver documents to the address, email, or fax number specified in
                            this contract, and the notification will be deemed delivered after the usual mailing or
                            transmission period.<br><br>
                            2. Unless otherwise agreed in this contract, the seller and the Company agree that if either
                            party uses the email address specified in this contract for payment notifications, it shall have
                            the same legal effect as a notification as defined under the Civil Code. However, the seller and
                            the Company shall retain the emails for one year for verification purposes.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 22: Effective Period</span><br><br>
                            Unless otherwise agreed in writing, this service contract shall be valid for one year from the
                            date of commencement of the contractual relationship. Unless the buyer (seller) gives written
                            notice of non-renewal 30 days prior to the expiration date or the expiration date of renewal, upon
                            expiration of the term and receipt by the Company of the renewal-related fees, the foregoing legal
                            relationship shall be automatically renewed for one year, and the same shall apply thereafter.
                          </div>
                          <br><br>
                          <div>
                            <span style="font-weight: bolder">Article 23: Governing Law and Court of Jurisdiction</span><br><br>
                            1. The disputes of the Service shall be governed by the laws of the Republic of China.<br><br>
                            2. All disputes arising out of the Service shall be resolved by the contractors in good faith; if
                            litigation is necessary, the buyer (seller) agrees that the Taipei District Court in Taiwan shall
                            be the first instance court of jurisdiction, unless otherwise provided by law. If litigation is
                            necessary, unless otherwise provided by law, the buyer (seller) agrees that the Taipei District
                            Court shall be the court of first jurisdiction.
                          </div>
                        </div>
                      </div>
                      <!-- 消費者個資移轉商店使用同意書 -->
                      <!--                <div v-if="activeTab === 'data-transfer'" class="details">-->
                      <!--                  <div v-if="currentLanguage === 'chinese'">-->
                      <!--                    <div>-->
                      <!--                      您同意紅陽科技在營運期間內為履行金流契約所需之範圍(含身分驗證及核銷等)，得於中華民國境內蒐集、處理及利用您的姓名、電話號碼及電子郵件等個資。您復同意本公司得將您個人資料移轉予合作商家分享，使該商家得以履行對您的訂單出貨、售後服務等義務。若商家涉及將您的個人資料為前述特定目的外之利用，請洽本公司客服電話：02-25026969。-->
                      <!--                    </div>-->
                      <!--                  </div>-->
                      <!--                  <div v-else>-->
                      <!--                    You agree that Sun Tech Co., Ltd (hereinafter referred to as "the Company") may collect, process and utilize your name, telephone number and email address in the Republic of China for the purpose of fulfilling the financial flow contract (including identity verification and verification, etc.) during the operation period. You further agree that we may transfer your personal information to the merchants to enable the merchants to fulfill their obligations to you with respect to orders, shipments, and after-sale services. If the merchant is involved in utilizing your personal information for purposes other than the aforementioned specific purposes, please contact our customer service number: 02-25026969.-->
                      <!--                  </div>-->
                      <!--                </div>-->
                    </div>
                  </el-dialog>
                </div>
              </div>
              <!-- 確認付款按鈕 -->
              <div style="text-align: center;">
                <el-button type="danger" :disabled="!informationCk" :class="informationCk ? 'next-btn' : 'next-btn-dis'" @click="nextStep">確認付款 Confirm</el-button>
              </div>
            </div>
          </div>

        </transition>

        <!-- 底部圖片 -->
        <div>
          <table style="width: 100%; text-align: center; margin-top: 30px;">
            <tr style="font-weight: bolder; font-size: 16px; height: 60px;">
              <td>收單銀行/ Acquiring Bank</td>
              <td>支付服務/ Payment Service</td>
              <td>SSL認證/ Secure Socket Layer</td>
            </tr>
            <tr>
              <td>
                <el-image class="footer-img" :src="selectedPayPlatformImg"></el-image>
              </td>
              <td>
                <el-image class="footer-img" :src="logoImgUrl"></el-image>
              </td>
              <td>
                <el-image class="footer-img" :src="goDaddyIcon"></el-image>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div style="width: 100%; height: 40px; background: #8F0304;"></div>

      <!--填寫驗證碼-->
      <new-ver-code-dialog ref="verCodeDialog" :modeCode="payForm.payMethods" :masterId="dataForm.masterId" :orderAmount="dataForm.transactionAmount" @goPay="goPay" @dialogClosed="dialogClosed"></new-ver-code-dialog>

    </el-form>
  </div>
</template>

<script>
import newVerCodeDialog from './compontents/new-ver-code-dialog'
import imgSlide from './compontents/img-slide'
import agreement1 from './agreement/agreement1'
import agreement2 from './agreement/agreement2'
import agreement3 from './agreement/agreement3'

import {
  getCurrentMonth,
  getCurrentYear
} from '@/api/credit'

import logoImg from '../assets/img/new-payment/logo-sunpay.png'
import rectangleOi from '../assets/img/new-payment/rectangle-oi.png'
import clockImg from '../assets/img/new-payment/clock-01.png'
import commodityBgImg from '../assets/img/new-payment/commodity-bg.png'
import dividerImg from '../assets/img/new-payment/divider.png'
import bannerImg from '../assets/img/new-payment/banner.png'
import masterImg from '../assets/img/new-payment/master.png'
import visaImg from '../assets/img/new-payment/visa.png'
import jcbImg from '../assets/img/new-payment/jcb.png'
import unionPayImg from '../assets/img/new-payment/unionPay.png'
import piluPayImg from '../assets/img/new-payment/piluPay.png'
import monthlPayImg from '../assets/img/new-payment/monthlPay.png'
import googlePayImg from '../assets/img/new-payment/google-pay.png'
import applePayImg from '../assets/img/new-payment/apple-pay.png'
import sumsungPayImg from '../assets/img/new-payment/sumsung-pay.png'

import inputProfileTickImg from '../assets/img/new-payment/input-profile-tick.png'
import annotationCheckImg from '../assets/img/new-payment/annotation-check.png'
import emailTxtImg from '../assets/img/new-payment/email-txt.png'
import emailTxtCNImg from '../assets/img/new-payment/email-txt-cn.png'
import productTxtImg from '../assets/img/new-payment/product-txt.png'
import annotationAlertImg from '../assets/img/new-payment/annotation-alert.png'

import footerImg from '../assets/img/new-payment/footer.png'
import footerFtImg from '../assets/img/new-payment/footer-first.png'
import footerKgiImg from '../assets/img/new-payment/footer-kgi.png'
import footerTsImg from '../assets/img/new-payment/footer-ts.png'
import footerUbImg from '../assets/img/new-payment/footer-ub.png'
import rememberTxtImg from '../assets/img/new-payment/remember-txt.png'

import arrowDownImg from '../assets/img/new-payment/input/arrow-down.png'
import arrowDownRImg from '../assets/img/new-payment/input/arrow-down-r.png'
import calendarTickImg from '../assets/img/new-payment/input/calendar-tick.png'
import calendarTickRImg from '../assets/img/new-payment/input/calendar-tick-r.png'
import componentImg from '../assets/img/new-payment/input/component.png'
import componentRImg from '../assets/img/new-payment/input/component-r.png'
import frameImg from '../assets/img/new-payment/input/frame.png'
import frameRImg from '../assets/img/new-payment/input/frame-r.png'
import mailImg from '../assets/img/new-payment/input/mail.png'
import mailRImg from '../assets/img/new-payment/input/mail-r.png'
import mapImg from '../assets/img/new-payment/input/map.png'
import mapRImg from '../assets/img/new-payment/input/map-r.png'
import profileTickImg from '../assets/img/new-payment/input/profile-tick.png'
import profileTickRImg from '../assets/img/new-payment/input/profile-tick-r.png'
import cardRImg from '../assets/img/new-payment/input/card-r.png'
import cardImg from '../assets/img/new-payment/input/card.png'
import carRImg from '../assets/img/new-payment/input/car-r.png'
import carImg from '../assets/img/new-payment/input/car.png'

import arrowLeftImg from '../assets/img/new-payment/arrow-left.png'
import arrowRightImg from '../assets/img/new-payment/arrow-right.png'
import refreshImg from '../assets/img/new-payment/refresh.png'

import avatar from '../assets/img/avatar.png'
import smsNoVerificationImg from '../assets/img/new-payment/input/shield-check.png'
import smsVerificationImg from '../assets/img/new-payment/input/shieldVerificatin-check.png'
// 眼睛圖示 睜眼/閉眼
import eye from '../assets/img/new-payment/eye.png'
import eyeSlash from '../assets/img/new-payment/eye_slash.png'
// 凱基銀行 P0001
import kgiBank from '../assets/img/new-payment/kgi-bank.png'
// 第一銀行 P0002
import firstBank from '../assets/img/new-payment/firstbank.png'
// 聯信 P0004
import nccBank from '../assets/img/payment/pay-icon-2.jpg'
import nccBank2 from '../assets/img/new-payment/ncc-logo.png'
// 聯邦銀行 P0005
import unionBank from '../assets/img/new-payment/union-bank.png'
// 中國信託 P0007
import ctbcBank from '../assets/img/new-payment/ctbc-bank.png'
// 台新銀行 P0009
import taishinBank from '../assets/img/new-payment/taishin-bank.png'
// SSL 認證 (GoDaddy)
import goDaddy from '../assets/img/new-payment/GoDaddy.png'
// 街口支付
import jkoPay from '../assets/img/new-payment/jko-pay.png'

export default {
  name: 'init-no-logistics',
  components: {
    newVerCodeDialog,
    imgSlide,
    agreement1,
    agreement2,
    agreement3
  },
  data() {
    var validateCreditCardNumber = (rule, value, callback) => {
      if (this.global.isEmpty(this.dataForm.cardNumber) || this.dataForm.changeCard == '1') {
        if (value == '' || value == undefined || value == null) {
          callback(new Error('此欄位為必填！'))
        } else {
          let val = value.replace(/\s*/g, '');
          // 信用卡類別為信用卡
          if (this.dataForm.cardCategory == '0' && val.length !== 16) {
            callback(new Error('請輸入16位卡號'))
          } else if (this.dataForm.cardCategory == '1' && (val.length > 19 || val.length < 16)) {
            callback(new Error('請輸入16位-19位卡號'))
          }
        }
      }
      callback()
    }
    const checkCarrierId = (rule, value, callback) => {
      if (this.payForm.invoice == 2) { //手機載具
        let rg = /^\/[0-9A-Z.\-\+]{7}$/;
        if (rg.test(value)) {
          callback()
        } else {
          callback(new Error('"請輸入正確載具號碼格式！'))
        }
      } else {
        let rg = /[A-Z]{2}[0-9]{14}$/;
        if (rg.test(value)) {
          callback()
        } else {
          callback(new Error('"請輸入正確載具號碼格式！'))
        }
      }
    }
    return {
      payPlatformId: '',
      payPlatformImgs: {
        'P0001': kgiBank,
        'P0002': firstBank,
        'P0004': nccBank2,
        'P0005': unionBank,
        'P0007': ctbcBank,
        'P0009': taishinBank
      },
      selectedPayPlatformImg: '',
      radio: 3,
      phoneError: false,
      needPayer: '',
      paymentModeShow: false, // 是否顯示付款方式
      creditCardNumber1: '',  // 信用卡號第一組
      creditCardNumber2: '',  // 信用卡號第二組
      creditCardNumber3: '',  // 信用卡號第三組
      creditCardNumber4: '',  // 信用卡號第四組
      eyeShow: '',            // 顯示眼睛閉眼或是睜眼

      //物流
      logisticsList: [],
      //是否開啓物流 搭配物流1.是 0.否
      matchingLogistics: 0,
      logisticsModeList: [],
      //超商取貨付款的超商list
      logisticsModePaymentList: [],
      //超商取貨 不付款的超商list
      logisticsModeNoList: [],
      recipientInfoChecked: false,
      //捐贈號碼列表
      pobanList: [],
      tradeAmountFlg: 1, // 是否為固定交易金額 0:否 1:是
      payOrderInfo: {
        merchantDataDto: {},
        masterAndResourceDto: {},
        merchantOrderDataDto: {},
        deliverySetupDataDto: {},
        codeDataDto: {},
        payerDataDto: {},
        invoiceDataDto: {},
        logisticsDataDto: {}
      },
      slideImgList: [],
      modeCodeList: [],
      payMethodsCode: '',
      avatar: avatar,
      // slideImgList: [arrowLeftImg,refreshImg],
      mainLoading: false,
      cardNo: ['', '', '', ''],
      payHeaderIcon: require('../assets/img/payment/pay-icon-5.png'),
      twIcon: require('../assets/img/payment/pay-icon-4.png'),
      cardIcon1: require('../assets/img/payment/pay-icon-2.jpg'),
      cardIcon2: require('../assets/img/payment/pay-icon-1.jpg'),
      goDaddyIcon: goDaddy,
      titleIcon: require('../assets/img/payment/pay-icon-3.png'),
      labelPosition: 'top',
      termsList: [], //信用卡分期設定期數
      errTerms: false,
      payForm: {
        // 付款方式
        payMethods: '', // 付款方式 例如: DOMESTIC_CREDIT_CARD 或是 UNION_CARD
        invoice: '0' // 0個人發票 1自然人憑證條碼 2手機條碼 3公司統編 4 捐贈
      },
      successAllPath: '',
      failAllPath: '',
      //身分驗證類型
      verificationType: 0,
      dataForm: {
        quickpayId: '',
        quickPayUrl: '',
        quickPayPathCode: '',
        amount: 1,
        masterId: '', //  訂單ID
        productName: '',
        productPrice: 0,
        productQuantity: 1,
        cardCategory: '',
        merchantCode: '',
        installmentsFlag: '',
        installmentsList: [],//分期list
        creditCardInstallment: '',
        // 信用卡卡號
        creditCardNumber: '',
        // 超過6位獲取卡型信息
        bank: '',
        cardType: '',
        // 有效期
        effectiveDate1: '',
        effectiveDate2: '',
        // 背面末三碼
        creditCardSecurityCode: '',
        // 持卡人姓名
        creditCardUserName: '',
        // 持卡人電話
        creditCardPhone: '',
        // 持卡人Email
        creditCardEmail: '',
        // 發卡銀行
        creditCardIssueBank: '',
        // 交易金額
        transactionAmount: '',
        mn: '',
        web: '',
        memo: '',
        orderInfo: '',
        agree: 0,
        uuid: '',// 驗證碼uuid
        captcha: '',// 驗證碼
        td: '',
        saveCard: '0',    // 是否保存卡號
        saveCardToken: '',// 信用卡token
        cardNumber: '',   // 已保存信用卡
        number: '',       // 信用卡
        merchantSaveCard: '', // 是否展示保存卡號
        changeCard: '0',      // 輸入卡號
        invoiceShowFlg: '',   // 發票顯示標識
        city: '',           // 城市
        area: '',           // 區域
        address: '',        // 地址
        buyerIdentifier: '',// 買受人統編
        carrierId: '',      // 手機條碼
        poban: '',          // 捐贈碼
        invoiceStatus: '',  // 發票狀態radio選項
        desc: '',
        invoiceHead: '',        // 發票抬頭
        invoicePaper: '0',      // 是否索取紙本發票 0否 1是
        recipientName: '',      // 收件人
        recipientPhoneNumber: '',
        recipientEmail: '',
        shippingMethod: '10',   // 寄送方式 10.超商取貨
        logisticsModeCode: '',  // 物流編碼
        pickUpStoreName: '',    // 取货地址-門市店名
        pickUpAddress: '',      // 取货地址-門市地址
        pickUpStoreCode: '',    // 取货地址-門市店號
        pickUpStorePosition: '',//??
        // 身份驗證資訊
        cellphoneNumber: '',  // 持卡人手機
        verificationCode: '', // 驗證碼
        idCardNumber: '',     // 身份證
        issuanceLocation: '', // 發證地點
        issuanceDate: '',     // 發證日期
        issuanceType: '',     // 補換類別
        verificationFlag: '',
        idCardPhotoFrontResourceId: '',
        idCardPhotoBackResourceId: '',
        idCardNumberSecurityCode: ''
      },
      issuanceDateIsEmpty: '-',
      modeCodeisEmpty: false,
      invoicePaperShow: '0',
      invoicePaperChecked: false, //紙本發票選中狀態
      rules: {
        // city:[
        //   {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'}
        // ],
        // area:[
        //   {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'}
        // ],
        // address:[
        //   {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'}
        // ],
        carrierId: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
          {validator: checkCarrierId,}
        ],
        buyerIdentifier: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
          {
            pattern: /^\d{8}$/,
            message: "請輸入正確買受人統編格式",
          }
        ],
        poban: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'}
        ],
        invoiceHead: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
          {pattern: /^[^\s]+/, message: "請輸入正確發票抬頭格式"}
        ],
        // orderInfo: [
        //   {required: true, message: '此欄位不能為空', trigger: 'blur'}
        // ],
        // creditCardIssueBank: [
        //   {required: true, message: '此欄位不能為空', trigger: 'blur'},
        // ],
        // creditCardUserName: [
        //   {required: true, message: '此欄位不能為空', trigger: 'blur'}
        // ],
        // creditCardPhone: [
        // {required: true, message: '此欄位不能為空', trigger: 'blur'}
        // ],
        // payMethods: [
        //   {required: true, message: '此欄位不能為空', trigger: 'blur'}
        // ],
        // effectiveDate1: [
        //   {required: true, message: '此欄位不能為空', trigger: 'change'}
        // ],
        // effectiveDate2: [
        //   {required: true, message: '此欄位不能為空', trigger: 'change'}
        // ],
        // creditCardSecurityCode: [
        //   {required: true, message: '此欄位不能為空', trigger: ['blur','change']},
        //   // {type: 'number', message: '驗證碼必須為數字', trigger: ['blur','change']}
        // ],
        // cardCategory: [
        //   {required: true, message: '此欄位不能為空', trigger: 'change'}
        // ],
        // creditCardNumber: [
        //   {validator: validateCreditCardNumber, trigger: 'blur'}
        // ],
      },
      preventSubmitFlag: false,
      // 信用卡號是否顯示驗證信息
      showCardNoErrorText: false,
      // 校驗是否通過
      validatePass: false,
      binCode: '',
      agreeList: [false, false, false],
      agreeComponents: ['agreement1', 'agreement2', 'agreement3'],
      addCardType: '1',
      clockImgUrl: clockImg,
      logoImgUrl: logoImg,
      rectangleOiUrl: rectangleOi,
      commodityBgImgUrl: commodityBgImg, // 商品背景圖
      dividerImgUrl: dividerImg,
      bannerImgUrl: bannerImg,
      masterImgUrl: masterImg,
      visaImgUrl: visaImg,
      jcbImgUrl: jcbImg,
      unionPayImgUrl: unionPayImg,
      piluPayImgUrl: piluPayImg,
      monthlPayImgUrl: monthlPayImg,
      googlePayImgUrl: googlePayImg,
      applePayImgUrl: applePayImg,
      sumsungPayImgUrl: sumsungPayImg,
      jkoPayImgUrl: jkoPay, // 街口支付
      idCard1: '',
      idCard2: '',

      arrowDownImgUrl: arrowDownImg,
      arrowDownRImgUrl: arrowDownRImg,
      calendarTickImgUrl: calendarTickImg,
      calendarTickRImgUrl: calendarTickRImg,
      componentImgUrl: componentImg,
      componentRImgUrl: componentRImg,
      frameImgUrl: frameImg,
      frameRImgUrl: frameRImg,
      mailImgUrl: mailImg,
      mailRImgUrl: mailRImg,
      mapImgUrl: mapImg,
      mapRImgUrl: mapRImg,
      profileTickImgUrl: profileTickImg,
      profileTickRImgUrl: profileTickRImg,
      cardRImgUrl: cardRImg,
      cardImgUrl: cardImg,
      carRImgUrl: carRImg,
      carImgUrl: carImg,
      smsNoVerificationImgUrl: smsNoVerificationImg,
      smsVerificationImgUrl: smsVerificationImg,
      eye: eye, // 眼睛圖示-睜眼
      eyeSlash: eyeSlash,// 眼睛圖示-閉眼

      // inputCardImgUrl: inputCardImg,
      // arrowDownImgUrl: arrowDownImg,
      // componentImgUrl: componentImg,
      // mailImgUrl: mailImg,
      // mapImgUrl: mapImg,
      // iconImgUrl: iconImg,
      inputProfileTickImgUrl: inputProfileTickImg,
      // calendarTickImgUrl: calendarTickImg,
      // frameImgUrl: frameImg,
      annotationCheckImgUrl: annotationCheckImg,
      emailTxtCNImgUrl: emailTxtCNImg,
      emailTxtImgUrl: emailTxtImg,
      productTxtImgUrl: productTxtImg,
      annotationAlertImgUrl: annotationAlertImg,

      footerImgUrl: footerImg,
      footerFtImgUrl: footerFtImg,
      footerKgiImgUrl: footerKgiImg,
      footerTsImgUrl: footerTsImg,
      footerUbImgUrl: footerUbImg,
      rememberTxtImgUrl: rememberTxtImg,

      formErrorMsg: '必填欄位不得為空白 Field required',
      validateInstallmentErr: '必填欄位不得為空白 Field required',

      checked: false,
      informationCk: false,
      showPopup: false,
      currentLanguage: '', // 用來判斷顯示中文或英文
      activeTab: '',    // 預設顯示的頁籤

      dueDate: '', // 付款有效時間
      merchantName: '', //商店名稱
      orderName: '', //訂單名稱

      optMYList: [],

      validateEffectiveDate: undefined,
      validateCreditCardNumber: undefined,
      validateCellphoneNumber: undefined,
      validateVerificationCode: undefined,
      validateIdCardNumber: undefined,
      validateInstallment: undefined,
      EffectiveDateErMsg: '必填欄位不得為空白 Field required',
      creditCardNumberMsg: '必填欄位不得為空白 Field required',
      transactionAmountMsg: '必填欄位不得為空白 Field required',
      recipientPhoneMsg: '必填欄位不得為空白 Field required',
      idCardNumberMsg: '必填欄位不得為空白 Field required',
      cellphoneNumberMsg: '必填欄位不得為空白 Field required',
      verificationCodeMsg: '必填欄位不得為空白 Field required',
      recipientPhoneErr: false,

      agreement1Box: false,
      agreement2Box: false,
      agreement3Box: false,

      productNameList: [],

      arrowLeftImgUrl: arrowLeftImg,
      arrowRightImgUrl: arrowRightImg,
      refreshImgUrl: refreshImg,

      cityList: [],
      areaList: [],
      //是否存在身份驗證
      isExistIdentity: false,
      selectedYear: '',
      selectedMonth: '',
      selectedDay: '',
      yearOptions: [],
      monthList: [],
      dayList: [],
      issuanceLocationList: [], // 交易身分驗證-發證地點下拉選單
      issuanceTypeList: [], // 交易身分驗證-領補換類別下拉選單
      //驗證碼倒計時
      count: '',
      //發送驗證碼的狀態 0默認可點 1xx秒後不可點  2重發-可點 3次數已過
      sendCodeStatus: '0',
      clickCount: 0,
      verificationCodeErr: false,
      verificationIDErr: false,
      verificationIDThreeErr: false,
      errCount: 0,
      cellphoneNumberErr: false,
    }
  },
  computed: {
    // 跳轉頁面
    routerName: {
      get() {
        return this.$store.state.credit.routerName
      },
      set(val) {
        this.$store.commit('credit/updateRouterName', val)
      }
    },
    payMethods: {
      get() {
        return this.$store.state.credit.payMethods
      },
      set(val) {
        this.$store.commit('credit/updatePayMethods', val)
      }
    },
    // 計算遮蔽的卡號部分
    maskedPart2() {
      return this.creditCardNumber2 ? '****' : '';
    },
  },
  mounted() {

    // this.mainLoading = true
    // 信用卡號是否顯示驗證信息
    this.showCardNoErrorText = false
    // 校驗是否通過
    this.validatePass = false

    this.dataForm.quickPayUrl = this.$route.params.code1;
    this.dataForm.quickPayPathCode = this.$route.params.code2;

    // 獲取全部支付方式
    this.getModeCode();
    //  獲取網址付全部內容
    this.getInfo();
    // 獲取城市下拉選
    this.getCityCodeName();
    // 獲取捐贈碼列表
    this.getPobanDataList();
    this.initSelectYearMonth();
    this.getLocationList();
    this.getIssuanceTypeList();
    //金額的支付方式判斷 &身份驗證判斷
    this.checkAmount();
  },
  methods: {
    // 同意書-切換語言
    switchLanguage() {
      this.currentLanguage = this.currentLanguage === 'chinese' ? 'english' : 'chinese'
    },
    // 同意書-顯示中文詳細資訊
    showChineseDetail(tabLabel) {
      this.currentLanguage = 'chinese'  // 切換到中文
      this.activeTab = tabLabel
      this.showPopup = true
    },
    // 同意書-顯示英文詳細信息
    showEnglishDetail(tabLabel) {
      this.currentLanguage = 'english'  // 切換到英文
      this.activeTab = tabLabel
      this.showPopup = true
    },
    // 同意書-取得標籤
    getTabLabel(tabName) {
      const labels = {
        'privacy-law': {
          chinese: '個人資料使用同意書',
          english: 'Consent for Use of Personal Data'
        },
        'service-terms': {
          chinese: '第三方支付服務契約書',
          english: 'Payment Service Agreement'
        },
        'data-transfer': {
          chinese: '消費者個資移轉商店使用同意書',
          english: 'Transfer of Personal Information'
        }
      }
      return labels[tabName][this.currentLanguage]
    },
    // 同意書-取得標題
    getTitle(tabName) {
      const titles = {
        'privacy-law': {
          chinese: '個人資料使用同意書',
          english: 'Consent for Use of Personal Data'
        },
        'service-terms': {
          chinese: '第三方支付服務契約書',
          english: 'Payment Service Agreement'
        },
        'data-transfer': {
          chinese: '消費者個資移轉商店使用同意書',
          english: 'Consumer Consent for Use of Personal Information Transfer Merchants'
        }
      }
      return titles[tabName][this.currentLanguage]
    },
    // 變更信用卡顯示模式
    changeEyeShow() {
      this.eyeShow = !this.eyeShow
    },
    // 切換超商
    logisticsModeCodeChange() {
      this.dataForm.pickUpStorePosition = "";
    },
    // 初始化選擇年份月份
    initSelectYearMonth() {
      let year = new Date().getFullYear();
      year = year - 1911;
      for (var i = year; i >= 0; i--) {
        if (i < 10) {
          i = '00' + i
        } else if (i < 100) {
          i = '0' + i
        }
        this.yearOptions.push(i);
      }
      for (var i = 1; i <= 12; i++) {
        if (i < 10) {
          this.monthList.push({value: '0' + i, lable: '0' + i, disable: false});
        } else {
          this.monthList.push({value: i, lable: i, disable: false});
        }
      }
    },
    // 更改年份
    changeYear() {
      this.selectedMonth = '';
      this.selectedDay = '';
      let nowYear = new Date().getFullYear();
      let nowMonth = new Date().getMonth() + 1;
      let nowDay = new Date().getDate();
      if (this.selectedYear && parseInt(this.selectedYear) == nowYear - 1911) {
        this.monthList.forEach((item) => {
          item.disable = false;
          if (parseInt(item.value) > nowMonth) {
            item.disable = true;
          } else {
            item.disable = false;
          }
        })
      } else {
        this.monthList.forEach((item) => {
          item.disable = false;
        })
      }
    },
    // 更改月份
    mothChange(moth) {
      let daysInMonth = new Date(parseInt(this.selectedYear) + 1911, this.selectedMonth, 0).getDate();
      let days = [];
      for (var i = 1; i <= daysInMonth; i++) {
        if (i < 10) {
          days.push({value: '0' + i, lable: '0' + i, disable: false});
        } else {
          days.push({value: i, lable: i, disable: false});
        }
      }
      this.selectedDay = '';
      this.dayList = days;
      let nowYear = new Date().getFullYear();
      let nowMonth = new Date().getMonth() + 1;
      let nowDay = new Date().getDate();
      if (parseInt(this.selectedYear) + 1911 == nowYear && parseInt(this.selectedMonth) == nowMonth) {
        this.dayList.forEach((item) => {
          item.disable = false;
          if (parseInt(item.value) > nowDay) {
            item.disable = true;
          } else {
            item.disable = false;
          }
        })
      } else {
        this.dayList.forEach((item) => {
          item.disable = false;
        })
      }
    },
    // 交易身分驗證-發證地點下拉選單
    getLocationList() {
      this.$http({
        url: this.$http.adornUrl('verify/issuanceLocationList'),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.issuanceLocationList = data.body;
        }
      })
    },
    // 交易身分驗證-領補換類別下拉選單
    getIssuanceTypeList() {
      this.$http({
        url: this.$http.adornUrl('verify/issuanceTypeList'),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.issuanceTypeList = data.body;
        }
      })
    },
    //獲取短信驗證碼
    getVerificationCode() {
      this.$http({
        url: this.$http.adornUrl('verify/code/phone'),
        method: 'get',
        params: this.$http.adornParams({
          account: this.dataForm.cellphoneNumber,
          merchantCode: this.dataForm.merchantCode,
          merchantOrderId: this.dataForm.orderId,
          amount: this.dataForm.transactionAmount
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.sendCodeStatus = '1';
          let TIME_COUNT = 60;
          this.clickCount++;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.count > 1 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                if (this.clickCount == 1) {
                  this.sendCodeStatus = '2';
                } else {
                  this.sendCodeStatus = '3';
                }
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      })
    },
    // 更改手機號碼
    changeCellphoneNumber(val) {
      this.dataForm.verificationCode = '';
      this.cellphoneNumberCheck(this.dataForm.cellphoneNumber);
    },
    // 取得驗證類型
    getVerificationType() {
      this.$http({
        url: this.$http.adornUrl('order/verificationInfo'),
        method: 'post',
        data: this.$http.adornData({
          phoneNumber: this.dataForm.cellphoneNumber
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.verificationType = data.body.verificationType
        }
      })
    },
    // 檢查電話
    checkPhone(phone) {
      let regex = /^09\d{8}$/;
      if (!regex.test(phone)) {
        return true
      } else {
        return false
      }
    },
    // 手機號碼檢查
    cellphoneNumberCheck(val) {
      let rg = /^09\d{8}$/;
      if (this.global.isEmpty(val)) {
        this.validateCellphoneNumber = (rule, value, callback) => {
          this.cellphoneNumberMsg = '必填欄位不得為空白 Field required';
          callback(new Error())
        }
      } else {
        this.dataForm.cellphoneNumber = val.replace(/[^\d]/g, '');
        if (!rg.test(val)) {
          this.validateCellphoneNumber = (rule, value, callback) => {
            this.cellphoneNumberMsg = '手機號碼格式錯誤 Wrong mobile number';
            this.cellphoneNumberErr = true;
            callback(new Error())
          }
        } else {
          this.validateCellphoneNumber = (rule, value, callback) => {
            this.cellphoneNumberErr = false;
            this.getVerificationType();
            callback()
          }
        }
      }
      return
    },
    // 驗證碼檢查
    verificationCodeCheck(val) {
      if (this.global.isEmpty(val)) {
        this.validateVerificationCode = (rule, value, callback) => {
          this.verificationCodeMsg = '必填欄位不得為空白 Field required';
          callback(new Error())
        }
      } else {
        this.dataForm.verificationCode = val.replace(/[^\d]/g, '');
        if (val.length != 6) {
          this.validateVerificationCode = (rule, value, callback) => {
            this.verificationCodeMsg = '請輸入完整驗證碼 Please enter correct Verification Code';
            callback(new Error())
          }
        } else {
          this.validateVerificationCode = (rule, value, callback) => {
            callback()
          }
        }
      }
      return
    },
    // 身分證號碼檢查
    idCardNumberCheck(val) {
      let reg = /^[A-Z]\d{9}$/;
      if (this.global.isEmpty(val)) {
        this.validateIdCardNumber = (rule, value, callback) => {
          this.idCardNumberMsg = '必填欄位不得為空白 Field required';
          callback(new Error())
        }
      } else {
        if (!reg.test(val)) {
          this.validateIdCardNumber = (rule, value, callback) => {
            this.idCardNumberMsg = '身分證字號錯誤 ID card number is incorrect';
            callback(new Error())
          }
        } else {
          this.validateIdCardNumber = (rule, value, callback) => {
            callback()
          }
        }
      }
    },
    //身份驗證 持卡人手機同步付款人電話
    validatePhoneNumber() {
      if (this.dataForm.creditCardPhone && this.isExistIdentity) {
        this.dataForm.cellphoneNumber = this.dataForm.creditCardPhone;
        this.dataForm.verificationCode = '';
        this.getVerificationType();
      }
      if (!this.global.isEmpty(this.dataForm.creditCardPhone)) {
        // 台灣手機號碼正則表達式
        const regex = /^09\d{8}$/;
        if (!regex.test(this.dataForm.creditCardPhone)) {
          this.phoneError = true;
        } else {
          this.phoneError = false;
        }
      } else {
        this.phoneError = false;
      }
    },
    // 點選同付款人資訊
    recipientInfoCheckChange(val) {
      if (val) {
        this.dataForm.recipientEmail = this.dataForm.creditCardEmail;
        this.dataForm.recipientName = this.dataForm.creditCardUserName;
        this.dataForm.recipientPhoneNumber = this.dataForm.creditCardPhone;
      } else {
        this.dataForm.recipientEmail = '';
        this.dataForm.recipientName = '';
        this.dataForm.recipientPhoneNumber = '';
      }
    },
    // 取得商店信用卡分期設定期數
    getMerchantInstallments() {
      this.loading = true
      this.$http({
        url: this.$http.adornUrl('/quickpay/quickpay/getMerchantInstallments'),
        method: 'get',
        params: this.$http.adornParams({
          quickPayUrl: this.dataForm.quickPayUrl,
          quickPayPathCode: this.dataForm.quickPayPathCode,
          creditCardNumber: this.binCode.replace(/\s*/g, ''),
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.termsList = data.body
        } else {
          this.$message.error(data.resultMessage)
        }
        this.loading = false
      })
    },
    // ????
    setBinCode() {
      let binCode = this.dataForm.creditCardNumber
      this.binCode = binCode.replace(/\s*/g, '').replace(/-/g, '')
      if (this.binCode.length > 10) {
        this.binCode = this.binCode.substr(0, 9)
      }
      if ((this.binCode.length == 6 || this.binCode.length == 8 || this.binCode.length == 9) && this.termsList.length == 0) {
        // 取得商店信用卡分期設定期數
        this.getMerchantInstallments()
      }
    },
    // 四碼卡號輸入時驗證
    changecreditCardNumber(number, modelKey) {
      let newNumber = number.replace(/\s/g, '').replace(/[^\d]/g, '')

      // 若在第一格貼上完整信用卡號，自動分割填入四格
      if (modelKey === 'creditCardNumber1' && newNumber.length > 4) {
        // 將 newNumber 限制為最多 16 個數字
        newNumber = newNumber.slice(0, 16)

        this.creditCardNumber1 = newNumber.slice(0, 4)
        this.creditCardNumber2 = newNumber.slice(4, 8)
        this.creditCardNumber3 = newNumber.slice(8, 12)
        this.creditCardNumber4 = newNumber.slice(12, 16)
        this.$refs.creditCardNumber4.focus()
        this.changeCreditCardNumber(newNumber)
        return
      }

      // 塞入信用卡號(creditCardNumber1~4)
      this[modelKey] = newNumber
      // 四組卡號的列表，依序查詢
      let CardNumberList = ['creditCardNumber1', 'creditCardNumber2', 'creditCardNumber3', 'creditCardNumber4']
      // 如果輸入的卡號>=4 ，開始依照順序檢查檢查其餘卡號是否輸入完成，假如未輸入完成，則跳至下一個未完成之輸入框
      if (newNumber.length >= 4) {
        CardNumberList.forEach((val, key, array) => {
          // 設定下一個key
          let selectKey = key < 3 ? (key + 1) : 0
          if (modelKey === val) {
            // 檢查下一個輸入框是否有值，假如現在的key是3，那就切換回第一個
            // 如果下一個輸入框是空值，或是輸入長度未達四位，將就將焦點切換到下一個輸入框
            if (this[CardNumberList[selectKey]] === '' || this[CardNumberList[selectKey]].length < 4) {
              this.$refs[CardNumberList[selectKey]].focus()
            }
          }
        });
      }
      // 如果四組卡號都輸入完成
      // 將卡號同步到
      this.changeCreditCardNumber(this.creditCardNumber1 + this.creditCardNumber2 + this.creditCardNumber3 + this.creditCardNumber4)
    },
    // 格式化信用卡卡號
    changeCreditCardNumber(val) {
      // 驗證卡號
      this.creditCardNumberCheck(val)
      // 1.將所有空格移除 2.去除非數字的字元 3.信用卡號超過 19 位數時加上空格。
      let cardNumber = val.replace(/\s/g, '').replace(/[^\d]/g, '').replace(/(\d{19})(?=\d)/g, '$1 ')
      // 1.移除非數字字元。 2.每 4 位數字之後插入短橫線（-）。 3.確保最後一位的短橫線不會多餘地出現。
      cardNumber = cardNumber.replace(/\D+/g, '').replace(/(\d{4})/g, '$1-').replace(/-$/, '')

      this.dataForm['creditCardNumber'] = cardNumber
      // 信用卡類別 選擇信用卡時 調接口獲取分期列表
      if (this.dataForm.cardCategory == '0') {
        this.setBinCode()
      } else {
        this.termsList = []
      }
    },
    // 切換分期數
    changeInstallments() {
      if (this.dataForm.creditCardNumber && this.termsList) {
        let list = this.global.getAry_forProp(this.termsList, 'installments'); //提取數組
        if (list.indexOf(this.dataForm.creditCardInstallment) == -1) {
          if (this.dataForm.creditCardInstallment == '') { //不分期
            this.errTerms = false;
          } else {
            this.$message.error('此信用卡不支援該分期');
            this.errTerms = true;
          }
        } else {
          this.errTerms = false;
        }
        this.validateInstallment = (rule, value, callback) => {
          if (value == null || value == undefined) {
            this.validateInstallmentErr = '必填欄位不得為空白 Field required';
            callback(new Error())
          } else if (this.errTerms) {
            this.validateInstallmentErr = '此信用卡不支援該分期';
            callback(new Error())
          }
          callback()
        }
      } else {
        this.dataForm.creditCardInstallment == ''
      }
    },
    // 金額的支付方式判斷 &身份驗證判斷
    checkAmount() {
      // 關閉顯示付款方式
      this.paymentModeShow = false;
      if (this.dataForm.transactionAmount != null && this.dataForm.transactionAmount !== '' && this.dataForm.transactionAmount > 0) {
        console.log('金額的支付方式判斷 &身份驗證判斷2')
        //取得支付方式-根據輸入的金額
        this.checkAmountPaymentMode();

      }
    },
    // 取得支付方式-根據輸入的金額
    checkAmountPaymentMode() {
      this.loading = true;
      // this.mainLoading = true;
      this.$http({
        url: this.$http.adornUrl('/quickpay/quickpay/checkAmountPaymentMode'),
        method: 'get',
        params: this.$http.adornParams({
          quickPayUrl: this.dataForm.quickPayUrl,
          quickPayPathCode: this.dataForm.quickPayPathCode,
          amount: this.dataForm.transactionAmount
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          // 獲取可用付款方式
          this.modeCodeList = data.body.modeCodeList;
          if (this.modeCodeList.find(item => item.modeCode === 'DOMESTIC_CREDIT_CARD')) {
            this.payForm.payMethods = 'DOMESTIC_CREDIT_CARD';
            this.dataForm.cardCategory = '0'
          } else if (this.modeCodeList.find(item => item.modeCode === 'SUPER_MARKET_CODE')) {
            this.payForm.payMethods = 'SUPER_MARKET_CODE'
          } else if (this.modeCodeList.find(item => item.modeCode === 'SUPER_MARKET_BAR')) {
            this.payForm.payMethods = 'SUPER_MARKET_BAR'
          } else if (this.modeCodeList.find(item => item.modeCode === 'VIRTUAL_ACCOUNT')) {
            this.payForm.payMethods = 'VIRTUAL_ACCOUNT'
          } else if (this.modeCodeList.find(item => item.modeCode === 'UNION_CARD')) {
            this.payForm.payMethods = 'UNION_CARD'
            this.dataForm.cardCategory = '1'
          } else if (this.modeCodeList.find(item => item.modeCode === 'SUPER_PAY_PICKUP')) {
            this.payForm.payMethods = 'SUPER_PAY_PICKUP'
          }

          // 顯示付款畫面
          this.paymentModeShow = true;
          // 判斷是否需要身分驗證(網址付用)
          this.checkAmountShowOtp();
        } else {
          // 關閉付款畫面
          this.paymentModeShow = false;

          // this.$router.push({name: 'orderfinish', query: {msg: data.resultMessage}})
          // this.$message.error(data.resultMessage);
          this.modeCodeList = [];
          this.$message.error(data.resultMessage);
        }
        // this.loading = false;
        // this.mainLoading = false;
      })
    },
    // 判斷是否需要身分驗證(網址付用)
    checkAmountShowOtp() {
      this.$http({
        url: this.$http.adornUrl('/verify/verificationFlag'),
        method: 'get',
        params: this.$http.adornParams({
          amount: this.dataForm.transactionAmount
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          //是否需要身分驗證【0 不需要】【1 需要】
          this.dataForm.verificationFlag = data.body.verificationFlag;
          if (this.dataForm.verificationFlag == '1') {
            this.isExistIdentity = true;
          } else {
            this.isExistIdentity = false;
          }
        }
      })
    },
    // 更改交易金額
    changeTradeAmount(val, model) {
      val = val.replace(/\s/g, '').replace(/[^\d.]/g, '');
      // 轉換為整數

      console.log("測試" + val)
      if (val) {
        val = parseInt(val, 10);
      }


      this.dataForm.transactionAmount = val;
      this.payOrderInfo.merchantOrderDataDto.tradeAmount = val;
    },
    // 輸入卡號
    inputCard(text, num) {
      if ((this.cardNo.length < 4 || this.cardNo[1].length < 4 || this.cardNo[2].length < 4 || this.cardNo[3].length < 4)) {
        this.showCardNoErrorText = true
      }
      this.$nextTick(() => {
        if (text.length == 4 && num <= 3) {
          if (this.$refs['cardNo' + (num + 1)]) {
            this.$refs['cardNo' + (num + 1)].focus()
          }
        }
        if (text.length == 0 && num !== 0) {
          if (this.$refs['cardNo' + (num - 1)]) {
            this.$refs['cardNo' + (num - 1)].focus()
          }
        }
      })
      // 取得商店信用卡分期設定期數
      this.getMerchantInstallments()
    },
    // 取得模式代碼
    getModeCode() {
      this.$http({
        url: this.$http.adornUrl('/quickPay/quickPayOrder/getModeCode'),
        method: 'get',
        params: {}
      }).then(({data}) => {
        if (data && data.resultCode == 200) {

        }
      })
    },
    // 改變物流模式
    changeLogisticsMode() {
      this.dataForm.pickUpStorePosition = "";
      if (this.payForm.payMethods == 'SUPER_PAY_PICKUP') {
        this.logisticsModeList = this.logisticsModePaymentList;
      } else {
        this.logisticsModeList = this.logisticsModeNoList;
      }
    },
    // 取得訂單檔案
    getInfo() {
      this.loading = true
      this.$http({
        url: this.$http.adornUrl('/quickpay/quickpay/allData'),
        method: 'get',
        params: this.$http.adornParams({
          quickPayUrl: this.dataForm.quickPayUrl,
          quickPayPathCode: this.dataForm.quickPayPathCode,
          userQuickPayPathCode: ''
        })
      }).then(({data}) =>  {
        if (data && data.resultCode == 200) {
          this.payOrderInfo = data.body;
          this.loading = false
          this.mainLoading = false
          this.slideImgList = data.body.masterAndResourceDto.resourceIdList; //商品圖片
          this.$nextTick(() => {
            this.$refs.slideImg.initSwiper();
          })
          //是否顯示付款咨詢
          this.needPayer = data.body.payerDataDto.needPayer;
          if (this.needPayer == '0') {
            this.dataForm.creditCardEmail = data.body.email;
          }
          // 第一順位收單行
          this.payPlatformId = data.body.payPlatformId;
          this.updateSelectedPayPlatformImg();
          //物流
          this.logisticsModePaymentList = [];
          this.logisticsModeNoList = [];
          if (data.body.logisticsDataDto != null && data.body.logisticsDataDto.logisticsModeCodeStatus != null) {
            this.logisticsList = data.body.logisticsDataDto.logisticsModeCodeStatus;
            this.logisticsList.forEach((item) => {
              if (item.shipmentType == '0') { //0 取貨付款  1取貨bu付款
                this.logisticsModePaymentList.push(item);
              } else {
                this.logisticsModeNoList.push(item);
              }
            })
          }
          this.changeLogisticsMode();
          if (data.body.logisticsDataDto != null && data.body.logisticsDataDto.matchingLogistics != null) {
            this.matchingLogistics = data.body.logisticsDataDto.matchingLogistics;
          }


          // this.logisticsModeList = this.filterRepeatList(this.logisticsList,'modeCode');
          // let count = 0;
          // let codeArray = this.repeatList(this.logisticsList,'modeCode');
          // if(this.logisticsModeList!=null && this.logisticsModeList.length>0){
          // 	for (var i = 0; i < this.logisticsModeList.length; i++) {
          // 		for (var j = 0; j < codeArray.length; j++) {
          // 			if(this.logisticsModeList[i].modeCode == codeArray[j]){
          // 				count++;
          // 			}
          // 		}
          // 		if(count>1){
          // 			this.logisticsModeList[i].shipmentType = '01';
          // 		}
          // 		count = 0;
          // 	}
          // }
          console.log(this.logisticsModeList);


          // 设置付款方式
          this.modeCodeList = data.body.codeDataDto.modeCodeList;

          if (this.modeCodeList.find(item => item.modeCode === 'DOMESTIC_CREDIT_CARD')) {
            this.payForm.payMethods = 'DOMESTIC_CREDIT_CARD';
            this.dataForm.cardCategory = '0'
          } else if (this.modeCodeList.find(item => item.modeCode === 'SUPER_MARKET_CODE')) {
            this.payForm.payMethods = 'SUPER_MARKET_CODE'
          } else if (this.modeCodeList.find(item => item.modeCode === 'SUPER_MARKET_BAR')) {
            this.payForm.payMethods = 'SUPER_MARKET_BAR'
          } else if (this.modeCodeList.find(item => item.modeCode === 'VIRTUAL_ACCOUNT')) {
            this.payForm.payMethods = 'VIRTUAL_ACCOUNT'
          } else if (this.modeCodeList.find(item => item.modeCode === 'UNION_CARD')) {
            this.payForm.payMethods = 'UNION_CARD'
            this.dataForm.cardCategory = '1'
          } else if (this.modeCodeList.find(item => item.modeCode === 'SUPER_PAY_PICKUP')) {
            this.payForm.payMethods = 'SUPER_PAY_PICKUP'
          }

          this.dataForm.orderId = data.body.merchantOrderDataDto.orderId;
          this.dataForm.productName = data.body.masterAndResourceDto.goodsName;
          this.dataForm.productPrice = data.body.merchantOrderDataDto.tradeAmount;
          this.dataForm.productQuantity = '1';
          this.dataForm.merchantCode = data.body.merchantDataDto.merchantCode;
          this.dataForm.invoiceShowFlg = data.body.invoiceDataDto.invoiceShowFlg;
          this.dataForm.installmentsFlag = data.body.codeDataDto.installmentsFlag;

          this.dataForm.installmentsList = data.body.codeDataDto.installmentsList;//分期

          this.dataForm.installmentsList.forEach((value, key) => {
            let transactionAmount = this.payOrderInfo.merchantOrderDataDto.tradeAmount // 總金額
            let installments = parseInt(value.installments)         // 分期期數數字化

            let initialAmount = 0     // 首期金額
            let amountPerPeriod = 0   // 每期金額

            // 算出是否有餘數
            let remainder = transactionAmount % installments
            if (remainder === 0) {
              initialAmount = transactionAmount / installments    // 首期金額
              amountPerPeriod = transactionAmount / installments  // 每期金額
            } else {
              initialAmount = Math.floor(transactionAmount / installments) + remainder  // 首期金額-無條件捨去
              amountPerPeriod = Math.floor(transactionAmount / installments)            // 每期金額-無條件捨去
            }
            // 設定分期標題
            this.dataForm.installmentsList[key].installmentsName = value.installments + '期 首期金額 $' + initialAmount + ' | 每期金額 $' + amountPerPeriod
          })



          this.dataForm.creditCardUserName = data.body.sna;
          //this.dataForm.invoicePaper = data.body.invoiceDataDto.invoicePaper;
          this.invoicePaperShow = data.body.invoiceDataDto.invoicePaper;
          this.dataForm.invoicePrintMode = data.body.invoiceDataDto.invoicePrintMode;
          this.dataForm.quickpayId = data.body.quickpayId
          if (this.payOrderInfo.merchantOrderDataDto.tradeAmount == 0) {
            this.tradeAmountFlg = 0
            this.dataForm.transactionAmount = '';
          } else {
            this.tradeAmountFlg = 1;
            this.dataForm.transactionAmount = data.body.merchantOrderDataDto.tradeAmount;
            //是否需要身分驗證【0 不需要】【1 需要】
            this.dataForm.verificationFlag = data.body.verificationFlag;
            if (this.dataForm.verificationFlag == '1') {
              this.isExistIdentity = true;
            } else {
              this.isExistIdentity = false;
            }
          }
          this.dataForm.orderInfo = data.body.masterAndResourceDto.goodsName;
          this.dataForm.cellphoneNumber = this.dataForm.creditCardPhone ? this.dataForm.creditCardPhone : '';
          this.dataForm.verificationCode = '';
          if (this.dataForm.cellphoneNumber) {
            this.getVerificationType();
          }
          // this.cellphoneNumberCheck(this.dataForm.cellphoneNumber);

          return false;


          if (data.body.logoResourceId) {
            this.getImageLogo(data.body.logoResourceId)
          }
          this.dataForm.mn = data.body.mn
          this.dataForm.transactionAmount = data.body.mn
          this.dataForm.creditCardUserName = data.body.sna
          this.dataForm.creditCardPhone = data.body.sdt
          this.dataForm.merchantCode = data.body.web
          this.dataForm.web = data.body.web
          // this.dueDate =  data.body.dueDate
          this.dueDate = data.body.createTime
          this.merchantName = data.body.merchantName
          this.dataForm.installmentsFlag = data.body.installmentsFlag
          this.productNameList = data.body.productNameList.map(item => {
            return item.productName
          })
          this.orderName = this.productNameList[0]
          //信用卡token
          this.dataForm.saveCardToken = data.body.saveCardToken
          //是否顯示保存選項
          this.dataForm.merchantSaveCard = data.body.merchantSaveCard
          this.dataForm.invoiceShowFlg = data.body.invoiceShowFlg
          //已保存信用卡
          this.dataForm.cardNumber = data.body.cardNumber
          if (this.global.isEmpty(this.dataForm.cardNumber)) {
            this.dataForm.saveCard = '0'
          } else {
            this.dataForm.saveCard = '1'
          }
          this.dataForm.number = data.body.number
          this.dataForm.creditCardNumber = data.body.number
          if (!this.global.isEmpty(data.body.creditCardValidFrom)) {
            this.dataForm.effectiveDate1 = data.body.creditCardValidFrom.substr(2, 2);
            this.dataForm.effectiveDate2 = data.body.creditCardValidFrom.substr(0, 2);
            this.dataForm.effectiveDate = data.body.creditCardValidFrom.substr(2, 4) + "/" + data.body.creditCardValidFrom.substr(0, 2);
          }

          // this.dataForm.orderInfo = data.body.orderInfo

        } else {
          this.$router.push({name: 'orderfinish', query: {msg: data.resultMessage}})
          // this.$message.error(data.resultMessage)
        }
        this.loading = false
        this.mainLoading = false
      })
    },
    // 更新支付平台圖片
    updateSelectedPayPlatformImg() {
      this.selectedPayPlatformImg = this.payPlatformImgs[this.payPlatformId]
    },
    // 取得圖片標誌
    getImageLogo(id) {
      this.$http({
        url: this.$http.adornUrl('/merchant/url/readPartImage/' + id),
        method: 'get',
        responseType: 'blob'
      }).then(({data}) => {
        let blob = new Blob([data]);
        const URL = window.URL || window.webkitURL;
        const fileUrl = URL.createObjectURL(blob);
        this.logoImgUrl = fileUrl
      })
    },
    // 更改發票選項
    changePaperCheck() {
      if (this.invoicePaperChecked) { //紙本發票選中
        this.dataForm.invoicePaper = '1';
      } else {
        this.dataForm.invoicePaper = '0';
        this.dataForm.city = '';
        this.dataForm.area = '';
        this.dataForm.address = '';
      }
    },
    // 下一步
    nextStep() {

      // 如果要索取紙本發票，並且發票類型為 0個人發票或是3公司統編時，需判斷是否有填寫地址
      if (this.dataForm.invoicePaper == '1' && (this.payForm.invoice == 0 || this.payForm.invoice == 3)) {
        if (!this.dataForm.city || !this.dataForm.area || !this.dataForm.address) {
          this.$message.error("地址未填寫完整")
          return
        }
      }
      //判斷付款人手機號格式
      if (this.phoneError) {
        this.$message.error("手機號格式錯誤")
        return
      }

      if (this.matchingLogistics && this.recipientPhoneErr) {
        this.$message.error("未選擇門市")
        return
      }
      // 設定為手機條碼或是捐贈時，將索取紙本發票變更為0:不索取
      if (this.payForm.invoice == 2 || this.payForm.invoice == 4) {
        this.dataForm.invoicePaper = '0';
      }

      if (this.dataForm.city != '') {
        this.dataForm.invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName
      }
      if (this.dataForm.city != '' && this.dataForm.area != '') {
        this.dataForm.invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName + this.areaList.find(item => {
          return item.areaId == this.dataForm.area
        }).areaName + this.dataForm.address
      }
      this.dataForm.invoiceStatus = this.payForm.invoice;
      //發證時間
      if (this.isExistIdentity && this.verificationType == 1) {
        if (this.selectedYear && this.selectedMonth && this.selectedDay) {
          this.dataForm.issuanceDate = this.selectedYear + this.selectedMonth + this.selectedDay;
          this.issuanceDateIsEmpty = '-';
        } else {
          this.issuanceDateIsEmpty = '';
          return
        }

      }
      //當前分期數  不可選
      if (this.errTerms) {
        return
      }
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (this.payForm.payMethods == 'DOMESTIC_CREDIT_CARD') {
            this.dataForm.cardCategory = '0'
          }
          if (this.payForm.payMethods == 'UNION_CARD') {
            this.dataForm.cardCategory = '1'
          }
          this.checkedPaymentMethod();
          //this.openConfirmDialog(this.dataForm)
        }
      })

    },
    // 已檢查付款方式
    checkedPaymentMethod() {
      this.loading = true
      this.mainLoading = true
      this.$http({
        url: this.$http.adornUrl('/quickPay/quickPayOrder/checkPaymentMethodStatus'),
        method: 'get',
        params: this.$http.adornParams({
          quickPayUrl: this.dataForm.quickPayUrl,
          modeCode: this.payForm.payMethods,
          logisticsModeCode: this.dataForm.logisticsModeCode
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          // 我也不知道為什麼要檢查金額
          if (this.payForm.payMethods != 'SUPER_PAY_PICKUP' && this.payForm.payMethods != 'JKO_PAY' ) {
            this.checkedAmount();
          } else {
            if (!this.dataForm.masterId || this.dataForm.masterId == '') {
              this.getMasterId();
            }
            // this.openConfirmDialog(this.dataForm)
            // this.loading = false
            // this.mainLoading= false
          }
        } else {
          this.$message.error(data.resultMessage);
          this.loading = false
          this.mainLoading = false
        }
      })
    },
    // 已檢查金額
    checkedAmount() {
      this.$http({
        url: this.$http.adornUrl('/quickPay/quickPayOrder/checkAmount'),
        method: 'get',
        params: this.$http.adornParams({
          quickPayUrl: this.dataForm.quickPayUrl,
          modeCode: this.payForm.payMethods,
          amount: this.dataForm.transactionAmount,
          quickPayPathCode: this.dataForm.quickPayPathCode,
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          if (!this.dataForm.masterId || this.dataForm.masterId == '') {
            this.getMasterId();
          }
          // this.openConfirmDialog(this.dataForm)
          // this.loading = false
          // this.mainLoading= false
        } else {
          this.$message.error(data.resultMessage);
          this.loading = false
          this.mainLoading = false
        }
      })
    },
    // 保存提交資訊後，送出訂單
    getMasterId() {
      let lgs_flag = '0';
      let cardType = '00';
      let storeType = ''
      let modeCode = this.payForm.payMethods
      switch (modeCode) {
        case 'DOMESTIC_CREDIT_CARD':
          cardType = '01'
          break
        case 'UNION_CARD':
          cardType = '02'
          break
        case 'SUPER_MARKET_CODE':
          cardType = '06'
          break
        case 'SUPER_MARKET_BAR':
          cardType = '07'
          break
        case 'VIRTUAL_ACCOUNT':
          cardType = '08'
          break
        case 'SUPER_PAY_PICKUP':
          cardType = '09'
          lgs_flag = '1';
          break
        case 'JKO_PAY':
          cardType = '10'
          break
      }
      if (this.dataForm.logisticsModeCode === 'OK_PU_CODE') {
        storeType = '3'
        lgs_flag = '1';
      } else if (this.dataForm.logisticsModeCode === 'LYLFU_PU_CODE') {
        storeType = '4'
        lgs_flag = '1';
      } else if (this.dataForm.logisticsModeCode === 'FAMILY_PU_CODE') {
        storeType = '2'
        lgs_flag = '1';
      } else if (this.dataForm.logisticsModeCode === '7-ELEVEN_PU_CODE') {
        storeType = '1'
        lgs_flag = '1';
      }
      let form = {
        quickpayId: this.dataForm.quickpayId,
        web: this.dataForm.merchantCode,
        mn: this.dataForm.transactionAmount,
        td: this.dataForm.orderId,
        chkValue: '123',
        sna: this.dataForm.creditCardUserName,
        sdt: this.dataForm.creditCardPhone,
        email: this.dataForm.creditCardEmail,
        note1: this.dataForm.note,
        cardType: cardType,
        term: this.dataForm.creditCardInstallment,
        orderSource: '05', // 00：收銀台 01：API 02:管理後臺 03:商戶後臺 / 04：swipy / 05：網址付
        productName: this.dataForm.productName,
        productPrice: this.dataForm.productPrice,
        productQuantity: this.dataForm.productQuantity,
        remark: this.dataForm.desc,
        orderInfo: this.dataForm.orderInfo,
        store_type: storeType,
        lgs_flag: lgs_flag,
        result: [
          {
            productName: this.dataForm.productName,
            productPrice: this.dataForm.productPrice,
            productQuantity: this.dataForm.productQuantity
          }
        ]
      }
      if (!form.note1) {
        form.note1 = this.dataForm.desc;
      }
      this.$http({
        url: this.$http.adornUrl('/order/saveOrderMaster'),
        method: 'post',
        data: this.$http.adornData(form)
      }).then(({data}) => {
        // console.log(data)
        this.dataForm.masterId = data.body.masterId

        switch (modeCode) {
          case "SUPER_MARKET_CODE":
          case "SUPER_MARKET_BAR":
          case "VIRTUAL_ACCOUNT":
          case "SUPER_PAY_PICKUP":
            this.confirmPay() // 現金類
            break
          case "JKO_PAY":
            this.payByJkoPay() // 街口支付
            break
          default:
            this.confirmPayCard()
            break
        }
      })
    },
    // 街口支付
    payByJkoPay () {
      console.log('選擇街口支付')
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.loadingBtn = true
          let form = this.global.formatObj(this.dataForm)
          this.$http({
            url: this.$http.adornUrl('order/payByJkoPay'),
            method: 'post',
            data: this.$http.adornData(form)
          }).then(({data}) => {
            this.dataSubmitButtonDisabled = false
            this.loadingBtn = false
            // 判斷是否交易成功
            if (data && data.resultCode === 200) {
              let newWin = window.open('', '_self')
              newWin.document.write(data.body.html)
              newWin.document.close()
            } else {
              this.$router.push({
                name: 'error',
                query: {
                  id: this.dataForm.masterId
                }
              })
            }
          })
        } else {
          this.dataSubmitButtonDisabled = false
        }
      })
    },
    /**
     * 現金類付款
     */
    confirmPay() {
      if (this.dataSubmitButtonDisabled) {
        return
      }
      let modeCode = this.payForm.payMethods
      this.dataSubmitButtonDisabled = true
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.loadingBtn = true
          let url
          // 超商條碼繳費
          if (modeCode === 'SUPER_MARKET_BAR') {
            url = '/order/payByMarketBarCode'
          }
          // 虛擬帳號轉帳
          if (modeCode === 'VIRTUAL_ACCOUNT') {
            url = '/order/payByVirtualAccount'
          }
          // 超商代碼
          if (modeCode === 'SUPER_MARKET_CODE') {
            url = '/order/payByMarketCode'
          }
          // 超商取貨
          if (modeCode === 'SUPER_PAY_PICKUP') {
            url = '/order/paySuperPickup'
          }
          let form = this.global.formatObj(this.dataForm)

          this.$http({
            url: this.$http.adornUrl(url),
            method: 'post',
            data: this.$http.adornData(form)
          }).then(({data}) => {
            this.dataSubmitButtonDisabled = false
            this.loadingBtn = false
            if (data && data.resultCode === 200) {
              //超商代碼交付、超商條碼繳費、虛擬帳號繳費時候，無需提示信息
              if (modeCode === 'SUPER_MARKET_CODE' || modeCode === 'SUPER_MARKET_BAR' ||
                  modeCode === 'VIRTUAL_ACCOUNT' || modeCode === 'SUPER_PAY_PICKUP') {
                this.goPay(this.dataForm)
              } else {
                this.$message({
                  message: '付款成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.goPay(this.dataForm)
                  }
                })
              }

            } else {
              this.$message.error(data.resultMessage)
            }
          })
        } else {
          this.dataSubmitButtonDisabled = false
        }
      })
    },
    /**
     * 信用卡付款
     */
    confirmPayCard() {
      this.goPay(this.dataForm)
    },
    scrollViewToError() {
      this.$nextTick(() => {
        let isError = document.getElementsByClassName('is-error')
        if (isError) {
          isError[0].scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          })
        }
      })
    },
    /**
     * 填寫驗證碼
     */
    openConfirmDialog(row) {
      this.$nextTick(() => {
        this.$refs['verCodeDialog'].init(row)
      })
    },
    dialogClosed() {
      console.log('dialogClosed')
      this.loading = false
      this.mainLoading = false
    },
    goPay(form) {
      // console.log(form)
      this.dataForm.masterId = form.masterId;
      this.routerName = 'init-no-logistics'
      // 超商代碼繳費
      if (this.payForm.payMethods == 'SUPER_MARKET_CODE') {
        this.$router.push({
          name: 'super-code-info',
          query: {
            masterId: this.dataForm.masterId,
            transactionAmount: this.dataForm.transactionAmount,
            // orderInfo: this.dataForm.orderInfo
          }
        })
      } else if (this.payForm.payMethods == 'SUPER_MARKET_BAR') {
        // 超商條碼繳費
        this.$router.push({
          name: 'super-bar-code-info',
          query: {
            masterId: this.dataForm.masterId,
            transactionAmount: this.dataForm.transactionAmount,
            // orderInfo: this.dataForm.orderInfo,
            creditCardUserName: this.dataForm.creditCardUserName
          }
        })
      } else if (this.payForm.payMethods == 'VIRTUAL_ACCOUNT') {
        // 虛擬帳號轉帳
        this.$router.push({
          name: 'virtual-account-info',
          query: {
            masterId: this.dataForm.masterId,
            transactionAmount: this.dataForm.transactionAmount,
            // orderInfo: this.dataForm.orderInfo,
            creditCardUserName: this.dataForm.creditCardUserName
          }
        })
      } else if (this.payForm.payMethods == 'SUPER_PAY_PICKUP') {
        //超商取貨
        this.$router.push({
          name: 'super-pay-pickup-info',
          query: {
            masterId: this.dataForm.masterId,
            transactionAmount: this.dataForm.transactionAmount,
            // orderInfo: this.dataForm.orderInfo
          }
        })
      } else if (this.payForm.payMethods == 'GOOGLE_PAY') {
        // google pay
        this.submitFormGooglePay()
      } else if (this.payForm.payMethods == 'APPLE_PAY') {
        // apple pay
        this.submitFormApplePay()
      } else {
        // 信用卡繳費：走saveCreditCardData接口，
        // 多傳兩個參數 captcha驗證碼  uuid驗證碼UUID
        this.dataForm.captcha = form.captcha
        this.dataForm.uuid = form.uuid
        this.submitForm()
      }
      // iphone Pay或Google Pay或台灣Pay
    },
    //applePay支付前處理相關數據
    submitFormApplePay() {
      let invoiceAddress = ''
      if (this.dataForm.city != '') {
        invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName
      }
      if (this.dataForm.city != '' && this.dataForm.area != '') {
        invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName + this.areaList.find(item => {
          return item.areaId == this.dataForm.area
        }).areaName + this.dataForm.address
      }
      let form = {
        'masterId': this.dataForm.masterId,
        'creditCardUserName': this.dataForm.creditCardUserName,
        'creditCardPhone': this.dataForm.creditCardPhone,
        'creditCardEmail': this.dataForm.creditCardEmail,
        'invoiceAddress': invoiceAddress,
        'carrierId': this.dataForm.carrierId,
        'buyerIdentifier': this.dataForm.buyerIdentifier,
        'poban': this.dataForm.poban,
        'invoiceStatus': this.payForm.invoice,
        'invoicePrintMode': this.dataForm.invoicePrintMode,
        'invoicePaper': this.dataForm.invoicePaper,
        'invoiceHead': this.dataForm.invoiceHead,
        'orderSource': '09'
      }
      this.$http({
        url: this.$http.adornUrl(`order/applepay/submitForm`),
        method: 'post',
        data: this.$http.adornData(form)
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          // 開啓收銀台apple pay支付畫面
          let newWin = window.open('', '_self')
          newWin.document.write(data.body)
          newWin.document.close()
        } else {
          console.log('[submitFormApplePay]=>fail')
        }
      })
    },
    //googlePay支付前處理相關數據
    submitFormGooglePay() {
      let invoiceAddress = ''
      if (this.dataForm.city != '') {
        invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName
      }
      if (this.dataForm.city != '' && this.dataForm.area != '') {
        invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName + this.areaList.find(item => {
          return item.areaId == this.dataForm.area
        }).areaName + this.dataForm.address
      }
      let form = {
        'masterId': this.dataForm.masterId,
        'creditCardUserName': this.dataForm.creditCardUserName,
        'creditCardPhone': this.dataForm.creditCardPhone,
        'creditCardEmail': this.dataForm.creditCardEmail,
        'invoiceAddress': invoiceAddress,
        'carrierId': this.dataForm.carrierId,
        'buyerIdentifier': this.dataForm.buyerIdentifier,
        'poban': this.dataForm.poban,
        'invoiceStatus': this.payForm.invoice,
        'invoicePrintMode': this.dataForm.invoicePrintMode,
        'invoicePaper': this.dataForm.invoicePaper,
        'invoiceHead': this.dataForm.invoiceHead,
        'orderSource': '10'
      }
      this.$http({
        url: this.$http.adornUrl(`order/googlepay/submitForm`),
        method: 'post',
        data: this.$http.adornData(form)
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          // 開啓收銀台google pay支付畫面
          let newWin = window.open('', '_self')
          newWin.document.write(data.body)
          newWin.document.close()
        } else {
          console.log('[submitFormGooglePay]=>fail')
        }
      })
    },
    submitForm() {
      let number = ''
      if (!this.global.isEmpty(this.dataForm.creditCardNumber)) {
        number = this.dataForm.creditCardNumber.replace(/\s*/g, '').replace(/-/g, '')
      }
      // console.log(this.dataForm.effectiveDate)
      debugger
      let invoiceAddress = ''
      if (this.dataForm.city != '') {
        invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName
      }
      if (this.dataForm.city != '' && this.dataForm.area != '') {
        invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName + this.areaList.find(item => {
          return item.areaId == this.dataForm.area
        }).areaName + this.dataForm.address
      }
      let creditCardValidFrom = '';
      if (this.dataForm.effectiveDate && this.dataForm.effectiveDate.length >= 5) {
        creditCardValidFrom = this.dataForm.effectiveDate.substr(3, 5) + this.dataForm.effectiveDate.substr(0, 2);
      }
      let form = {
        'masterId': this.dataForm.masterId,
        'web': this.dataForm.merchantCode,
        'mn': this.dataForm.transactionAmount,
        'creditCardNumber': number,
        'creditCardValidFrom': creditCardValidFrom,
        // 'creditCardValidFrom': this.dataForm.effectiveDate2 + this.dataForm.effectiveDate1,
        'creditCardSecurityCode': this.dataForm.creditCardSecurityCode,
        'creditCardUserName': this.dataForm.creditCardUserName,
        'creditCardPhone': this.dataForm.creditCardPhone,
        'creditCardEmail': this.dataForm.creditCardEmail,
        'cardCategory': this.dataForm.cardCategory,
        'creditCardInstallment': this.dataForm.creditCardInstallment,
        'captcha': this.dataForm.captcha,
        'uuid': this.dataForm.uuid,
        'saveCard': this.dataForm.saveCard,
        'changeCard': this.dataForm.changeCard,
        'saveCardToken': this.dataForm.saveCardToken,
        // 'orderInfo': this.dataForm.orderInfo
        'invoiceAddress': invoiceAddress,
        'carrierId': this.dataForm.carrierId,
        'buyerIdentifier': this.dataForm.buyerIdentifier,
        'poban': this.dataForm.poban,
        'invoiceStatus': this.payForm.invoice,
        'remark': this.dataForm.desc,
        'orderInfo': this.dataForm.orderInfo,
        'invoicePrintMode': this.dataForm.invoicePrintMode,
        'invoicePaper': this.dataForm.invoicePaper,
        'invoiceHead': this.dataForm.invoiceHead,
        'recipientName': this.dataForm.recipientName,
        'recipientPhoneNumber': this.dataForm.recipientPhoneNumber,
        'recipientEmail': this.dataForm.recipientEmail,
        'pickUpStoreName': this.dataForm.pickUpStoreName,
        'pickUpAddress': this.dataForm.pickUpAddress,
        'pickUpStoreCode': this.dataForm.pickUpStoreCode,
        'shippingMethod': this.dataForm.shippingMethod,
        'logisticsModeCode': this.dataForm.logisticsModeCode,
        'cardholderCellphoneNumber': this.dataForm.cellphoneNumber,
        'smsVerificationCode': this.dataForm.verificationCode,
        'idCardNumber': this.dataForm.idCardNumber,
        'issuanceLocation': this.dataForm.issuanceLocation,
        'issuanceDate': this.dataForm.issuanceDate,
        'issuanceType': this.dataForm.issuanceType,
        'verificationFlag': this.dataForm.verificationFlag,
        'idCardPhotoFrontResourceId': this.dataForm.idCardPhotoFrontResourceId,
        'idCardPhotoBackResourceId': this.dataForm.idCardPhotoBackResourceId,
        'idCardNumberSecurityCode': this.dataForm.idCardNumberSecurityCode
      }
      this.$http({
        url: this.$http.adornUrl(`/order/saveCreditCardData`),
        method: 'post',
        data: this.$http.adornData(form)
      }).then(({data}) => {
        this.preventSubmitFlag = false;
        this.verificationCodeErr = false;
        this.verificationIDErr = false;
        this.verificationIDThreeErr = false;
        if (data && data.resultCode == 200) {
          console.log(data.body)
          if (data.body) {
            let newWin = window.open('', '_self')
            newWin.document.write(data.body)
            newWin.document.close()
          } else {
            // 非3D授權
            // this.$router.push({name: 'success'})
            // 處理支付結果
            if (this.successAllPath) {
              this.noticePaymentResultToMerchant(this.successAllPath)
            } else {
              this.gotoSelfPaymentResult(1)
            }
          }
        } else if (data.resultCode == 98) { //驗證碼錯誤1(或2)次
          this.$message.error(data.resultMessage);
          this.errCount++;
          this.verificationCodeErr = true;
          this.loading = false
          this.mainLoading = false
        } else if (data.resultCode == 97) {  //驗證碼錯誤3次，交易失敗
          this.$message.error(data.resultMessage);
          this.gotoSelfPaymentResult(0)
          this.loading = false
          this.mainLoading = false
        } else if (data.resultCode == 96) {  //身分驗證錯誤1(或2)次
          this.$message.error(data.resultMessage);
          this.verificationIDErr = true;
          this.loading = false
          this.mainLoading = false
        } else if (data.resultCode == 95) {  //身分證末三碼錯誤
          this.$message.error(data.resultMessage);
          this.verificationIDThreeErr = true;
          this.loading = false
          this.mainLoading = false
        } else if (data.resultCode == 94) {  //身分驗證錯誤3次，交易失敗
          this.$message.error(data.resultMessage);
          this.gotoSelfPaymentResult(0)
          this.loading = false
          this.mainLoading = false
        } else {
          this.loading = false
          this.mainLoading = false
          // this.$message.error(data.resultMessage)
          if ('驗證碼錯誤，請重新輸入' == data.resultMessage) {
            // this.getCaptcha()
            this.$message.error(data.resultMessage)
          } else {
            if (this.failAllPath) {
              this.noticePaymentResultToMerchant(this.failAllPath)
            } else {
              // TODO 待處理
              this.gotoSelfPaymentResult(0)
            }
          }
        }
      })
    },
    noticePaymentResultToMerchant(url) {
      this.$http({
        url: this.$http.adornUrl('/receive/return/data'),
        method: 'post',
        data: this.$http.adornData({
          // 訂單ID
          masterId: this.dataForm.masterId
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.sendForm(url, data.body)
        } else {
          this.$message.error(data.resultMessage)
        }
      })
    },
    sendForm: function (url, data) {
      let newWin = window.open('', '_self')
      let text = '<!DOCTYPE HTML>'
      text = text + '<html lang="en">'
      text = text + '<head>'
      text = text + '  <meta charset="UTF-8">'
      text = text + '  <meta name="viewport" content="width=device-width, initila-scale=1.0">'
      text = text + '  <title></title>'
      text = text + '</head>'
      text = text + '<script type="text/javascript">'
      text = text + '    function autoSubmit(){'
      text = text + '      document.getElementById("sendForm").submit();'
      text = text + '    }'
      text = text + ' <\/script>'
      text = text + '<body onload="autoSubmit();">'
      text = text + '<form action="'
      text = text + url
      text = text + '" method="post" id="sendForm">'
      text = text + '  <input type="hidden" id="web" name="web" value="' + data.web + '"/>'
      text = text + '  <input type="hidden" id="send_time" name="send_time" value="' + data.sendTime + '"/>'
      text = text + '  <input type="hidden" id="rsamsg" name="rsamsg" value="' + data.rsaMsg + '"/>'
      text = text + '  <input type="hidden" id="check_value" name="check_value" value="' + data.checkValue + '"/>'
      text = text + '</form>'
      text = text + '</body>'
      text = text + '</html>'
      newWin.document.write(text)
      newWin.document.close()
    },
    setPaymentResult() {
      this.$http({
        url: this.$http.adornUrl('/quickpay/quickpay/allData'),
        method: 'get',
        params: {
          // 訂單ID
          quickPayUrl: this.dataForm.quickPayUrl,
          quickPayPathCode: this.dataForm.quickPayPathCode,
          userQuickPayPathCode: ''
        }
      }).then(({data}) => {
        console.log('merchant/url/info', data)
        if (data && data.resultCode == 200) {
          this.successAllPath = data.body.successAllPath
          this.failAllPath = data.body.failAllPath
        }
      })
    },
    gotoSelfPaymentResult(errorFlag) {
      if (errorFlag == 1) {
        this.$router.push({
          name: 'card-info',
          query: {
            masterId: this.dataForm.masterId,
            transactionAmount: this.dataForm.transactionAmount,
            // orderInfo: this.dataForm.orderInfo,
            uuid: this.dataForm.uuid
          }
        })
      } else {
        this.$router.push({name: 'error', query: {id: this.dataForm.masterId}})
      }
    },
    scrollInto(id) {
      const yOffset = -20
      const element = document.getElementById(id)
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({top: y, behavior: 'smooth'})
    },
    /**
     * 切換付款方式
     */
    changePayMethods(val) {
      this.$nextTick(() => {
        this.$forceUpdate()

        // if (this.$refs['dataForm']) {
        //   this.$refs['dataForm'].resetFields()
        // }
        // if (this.$refs['dataForm1']) {
        //   this.$refs['dataForm1'].resetFields()
        // }
        // 清空信用卡卡號輸入框
        this.creditCardNumber1 = ''
        this.creditCardNumber2 = ''
        this.creditCardNumber3 = ''
        this.creditCardNumber4 = ''

        this.cardNo = ['', '', '', '']
        this.dataForm.effectiveDate1 = ''
        this.dataForm.effectiveDate2 = ''
        this.dataForm.creditCardSecurityCode = ''
        this.dataForm.cardCategory = ''
        this.dataForm.creditCardNumber = ''
        // 付款方式：選擇信用卡
        if (this.payForm.payMethods == 'DOMESTIC_CREDIT_CARD') {
          // 信用卡類別默認  信用卡
          this.$set(this.dataForm, 'cardCategory', '0')
        }
      })
      // 信用卡號是否顯示驗證信息
      this.showCardNoErrorText = false
    },
    /**
     * 切換信用卡類別
     */
    changeBankType(val) {
      this.$nextTick(() => {
        if (this.$refs['dataForm']) {
          this.$refs['dataForm'].resetFields()
        }
        if (this.$refs['effectiveDate1']) {
          this.$refs['effectiveDate1'].resetField()
        }
        if (this.$refs['effectiveDate2']) {
          this.$refs['effectiveDate2'].resetField()
        }
        if (this.$refs['creditCardSecurityCode']) {
          this.$refs['creditCardSecurityCode'].resetField()
        }
        if (this.$refs['creditCardNumber']) {
          this.$refs['creditCardNumber'].resetField()
        }
        this.cardNo = ['', '', '', '']
        this.dataForm.creditCardInstallment = ''
        this.dataForm.effectiveDate1 = ''
        this.dataForm.effectiveDate2 = ''
        this.dataForm.effectiveDate = ''
        this.dataForm.creditCardSecurityCode = ''
        this.dataForm.creditCardNumber = ''
      })
      // 信用卡號是否顯示驗證信息
      this.showCardNoErrorText = false
    },
    changeAddType(val) {
      this.$refs.dataForm1.resetFields("creditCardNumber");
      if (val == '0') {
        this.dataForm.creditCardNumber = this.dataForm.number;
      } else {
        this.dataForm.creditCardNumber = ''
      }
    },
    handleOpen() {
      if (this.global.isEmpty(this.dataForm.logisticsModeCode)) {
        this.modeCodeisEmpty = true;
      } else {
        this.modeCodeisEmpty = false;
        this.getStoreMap();
      }

    },
    handleChange(val) {
      console.log(val)
      this.dataForm.effectiveDate1 = val[0]
      this.dataForm.effectiveDate2 = val[1]
    },
    effectiveDateCheck(val) {
      let jss = val.replace(
          /[^0-9]/g, '' // To allow only numbers
      ).replace(
          /^([2-9])$/g, '0$1' // To handle 3 > 03
      ).replace(
          /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
      ).replace(
          /^0{1,}/g, '0' // To handle 00 > 0
      ).replace(
          /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
      );

      this.dataForm.effectiveDate = jss
      console.log()

      this.EffectiveDateErMsg = ''

      if (val.length < 5) {
        console.log(val.length)
        this.validateEffectiveDate = (rule, value, callback) => {
          this.EffectiveDateErMsg = val.length > 0 ? '請輸入正確有效年月' : '必填欄位不得為空白 Field required'
          callback(new Error())
        }
      } else {
        if (val[0] + val[1] > 12) {
          this.validateEffectiveDate = (rule, value, callback) => {
            this.EffectiveDateErMsg = '請輸入正確有效年月'
            callback(new Error())
          }
        } else {
          this.validateEffectiveDate = (rule, value, callback) => {
            callback()
          }
        }
      }
    },
    checkRecipientPhone() {
      this.recipientPhoneMsg = '必填欄位不得為空白 Field required';
      if (!this.global.isEmpty(this.dataForm.recipientPhoneNumber)) {
        // 台灣手機號碼正則表達式
        const regex = /^09\d{8}$/;
        if (!regex.test(this.dataForm.recipientPhoneNumber)) {
          this.recipientPhoneMsg = '請輸入正確的手機號碼';
          this.recipientPhoneErr = true;
        } else {
          this.recipientPhoneErr = false;
        }
      } else {
        this.recipientPhoneMsg = '必填欄位不得為空白 Field required';
      }
    },
    // 驗證卡號
    creditCardNumberCheck(val) {
      this.creditCardNumberMsg = '必填欄位不得為空白 Field required'
      if (val.indexOf(" ") == -1 && val.length == 16) {
        this.validateCreditCardNumber = (rule, value, callback) => {
          callback()
        }
        return
      }
      if (val.length != 19) {
        this.validateCreditCardNumber = (rule, value, callback) => {
          if (val.length == 0) {
            this.creditCardNumberMsg = '必填欄位不得為空白 Field required'
          } else {
            this.creditCardNumberMsg = '卡號輸入不完整'
          }
          callback(new Error())
        }
      } else {
        this.validateCreditCardNumber = (rule, value, callback) => {
          callback()
        }
      }
    },
    nextOrderName(type) {
      if (this.productNameList.length == 0) {
        return
      }
      let index = this.productNameList.indexOf(this.orderName)
      if (type == 0) {
        if (index > 0) {
          this.orderName = this.productNameList[index - 1]
        }
      }
      if (type == 1) {
        if (index != -1 && index < this.productNameList.length - 1) {
          this.orderName = this.productNameList[index + 1]
        }
      }
      if (type == 2) {
        this.orderName = this.productNameList[0]
      }
    },
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null
      }
      const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        } else if (typeof time === 'string') {
          time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      // 增加20分鐘
      var min = new Date(date.getTime() + 1000 * 60 * 20)
      const formatObj = {
        y: min.getFullYear(),
        m: min.getMonth() + 1,
        d: min.getDate(),
        h: min.getHours(),
        i: min.getMinutes(),
        s: min.getSeconds(),
        a: min.getDay()
      }
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
          return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return time_str
    },
    //縣市名稱
    getCityCodeName() {
      this.$http({
        url: this.$http.adornUrl(`/order/getCityName`),
        method: 'get'
      }).then(({data}) => {
        if (data && data.resultCode === 200) {
          this.cityList = data.body;
        } else {
          this.cityList = [];
        }
      }).catch((e) => {
      })
    },
    //獲取縣市名稱下面區域名
    choseCity(cityId) {
      if (cityId == '') {
        return
      }
      this.$http({
        url: this.$http.adornUrl('/order/getAreaName/' + cityId),
        method: 'get'
      }).then(({data}) => {
        if (data && data.resultCode === 200) {
          this.areaList = data.body;
        } else {
          this.areaList = [];
        }
      }).catch((e) => {
      })
    },
    //清空城市區域下來選
    clearSelect(form, model) {
      this[form][model] = ""
      this.dataForm.city = ''
      this.areaList = []
    },
    //發票radio改變
    changeInvoiceStatus(val) {
      this.dataForm.city = '';
      this.dataForm.area = '';
      this.dataForm.buyerIdentifier = '';
      this.dataForm.carrierId = '';
      this.dataForm.poban = '';
      this.dataForm.address = '';
      this.areaList = [];
      this.dataForm.invoiceHead = '';
    },
    // 獲取捐贈號碼數據列表
    getPobanDataList() {
      this.$http({
        url: this.$http.adornUrl('/sys/donateinvoice/getList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data && data.resultCode === 200) {
          if (data.body) {
            this.pobanList = data.body;
          } else {
            this.pobanList = [];
          }
        }
      })
    },
    getStoreMap() {
      let storeType = '';
      let serviceType = '';
      if (this.dataForm.logisticsModeCode == 'OK_PU_CODE') {
        storeType = '4'
      } else if (this.dataForm.logisticsModeCode == 'LYLFU_PU_CODE') {
        storeType = '3'
      } else if (this.dataForm.logisticsModeCode == 'FAMILY_PU_CODE') {
        storeType = '2'
      } else if (this.dataForm.logisticsModeCode == '7-ELEVEN_PU_CODE') {
        storeType = '1'
      }
      //信用卡和現金（取貨不付款）  超商取貨（取貨付款）
      if ((this.payForm.payMethods == 'DOMESTIC_CREDIT_CARD' || this.payForm.payMethods == 'UNION_CARD'
          || this.payForm.payMethods == 'SUPER_MARKET_CODE' || this.payForm.payMethods == 'SUPER_MARKET_BAR'
          || this.payForm.payMethods == 'VIRTUAL_ACCOUNT')) {
        serviceType = '2'
      } else if (this.payForm.payMethods == 'SUPER_PAY_PICKUP') {
        serviceType = '1'
      }
      let reqData = [
        {key: 'storeType', val: storeType},    //取貨超商 1:7-11 2:全家 3:萊爾富 4:OK
        {key: 'serviceType', val: serviceType}, //付款類型 1：取貨付款 2：取貨不付款
        {key: 'device', val: 'pc'}     //使用裝置 pc OR mobile
      ]
      // 打開新視窗
      var newWindow = window.open("", '_blank', 'width=900,height=700');
      // 在新窗口中生成表單
      var form = newWindow.document.createElement('form');
      form.action = 'https://freight.sunpay.com.tw/web/StoreMap';
      form.method = 'post';
      newWindow.document.body.appendChild(form);
      reqData.forEach((item) => {
        var input = newWindow.document.createElement('input');
        input.type = 'hidden';
        input.name = item.key;
        input.value = item.val;
        form.appendChild(input);
      })
      form.submit();
      //監聽新窗口事件
      var _that = this;
      window.addEventListener('message', function (event) {
        if (event.data["resultCode"] == "00") {
          let resultCodeData = event.data["data"];

          console.log(resultCodeData);
          _that.dataForm.pickUpStoreName = resultCodeData.receiverStoreName;
          _that.dataForm.pickUpAddress = resultCodeData.receiverStoreAddress;
          _that.dataForm.pickUpStoreCode = resultCodeData.receiverStoreid;
          _that.dataForm.pickUpStorePosition = _that.dataForm.pickUpStoreName + " " + _that.dataForm.pickUpAddress;

        } else if (event.data["resultCode"] !== undefined && event.data["resultCode"] !== "00") {
          Swal.fire('發生錯誤', event.data["resultMessage"], 'error')
        }
      });
    },
    icCardFrontSuccess(response, file, fileList) {
      this.dataForm.idCardPhotoFrontResourceId = response.body.resourceId;
      this.global.getBlob(response.body.resourceId, (result) => {
        this.idCard1 = result;
      })
    },
    icCardBackSuccess(response, file, fileList) {
      this.dataForm.idCardPhotoBackResourceId = response.body.resourceId;
      this.global.getBlob(response.body.resourceId, (result) => {
        this.idCard2 = result;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/payment.scss";

.card-form-line.el-divider.el-divider--horizontal {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}

::v-deep .el-input {
  .el-input-group__prepend {
    border: 0px;
    color: #525252;
    font-size: 16px;
    font-weight: 400;
  }
}

::v-deep .el-form--label-top {
  .el-form-item {
    margin-top: 10px !important;

    .el-input__inner {
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #525252;
      background-color: #EDEDED;
      border-color: #EDEDED;
    }

    .el-textarea__inner {
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #525252;
      background-color: #EDEDED;
      border-color: #EDEDED;
    }

    &__label {
      line-height: 23.12px !important;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.9px;
      padding: 0 0 5px !important;
      color: #525252 !important;

    }

    .el-form-item__error {
      color: #FF0000 !important
    }
  }
}

::v-deep .ol-el-input {
  .el-input__inner {
    text-align: center;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }

  .el-textarea__inner {
    text-align: center;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }
}

::v-deep .le-el-input {
  .el-input__inner {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }

  .el-textarea__inner {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }
}


::v-deep .right-image-input {
  .el-input__inner {
    padding-right: 60px;
  }

}

::v-deep .selet-drop {
  .el-input__suffix {
    display: none;
  }

  .el-select-dropdown__item.selected {
    color: #78B689 !important;
    font-weight: 700;
  }
}

::v-deep .el-cascader .el-input .el-icon-arrow-down {
  display: none;
}

.card-image {
  width: 20%;
  //height: 25px;
  max-height: 20px;
  //margin-top: 11px;
  margin-left: 5px;
  cursor: pointer;
  //position: absolute;
  //right: 13px;
  //top: 6px;
}

.right-image {
  width: 38px;
  position: absolute;
  right: 13px;
  top: 2px;
}

.order-txt {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.pay-rad-txt {
  font-weight: 600;
  color: #2A2A2A;
  font-size: 16px;
  line-height: 42px;
}

.box-txt-bl-s {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
}

.box-txt-bl-b {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  line-height: 25px;
}

.box-txt-rd {
  font-weight: 600;
  color: #8F0304;
  font-size: 16px;
  line-height: 20px;
}

.error-msg {
  display: flex;
  flex-direction: row;
  line-height: 20px;
  position: absolute;

  .img {
    width: 16px;
    height: 16px;
    margin-top: 3px;
  }

  .txt {
    margin-left: 5px;
    color: #FF0000;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
  }
}

.pay-form-radio ::v-deep .el-radio__inner {
  border: 2px solid #949494;
  width: 15px;
  height: 15px;
}

.pay-form-radio ::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #1DB145 !important;
  border: 2px solid #1DB145 !important;
  background: #ffffff !important;
}

.pay-form-radio ::v-deep .el-radio__inner::after {
  width: 6px;
  height: 6px;
  background-color: #1DB145 !important;
}

.pay-form-radio ::v-deep .el-radio__input {
  color: #2A2A2A;
}

.pay-form-radio ::v-deep .el-radio__label {
  color: #2A2A2A;
  font-size: 20px !important;
  font-weight: bold;
}

.pay-form-radio ::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #2A2A2A;
  font-size: 20px !important;
  font-weight: bold;
}

/* 同付款人資訊 框框調整 */
.pay-form-check ::v-deep .el-checkbox__input {
  margin-top: -40px;
}

.pay-form-check ::v-deep .el-checkbox {
  color: #2A2A2A !important;
}

.pay-form-check ::v-deep .el-checkbox__label {
  font-size: 16px !important;
  font-weight: bold;
}

.pay-form-check ::v-deep .el-checkbox__inner {
  border: 1px solid #606060 !important;
  width: 16px !important;
  height: 16px !important;
}

.pay-form-check ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #606060 !important;
}

.pay-form-check ::v-deep .el-checkbox__inner:hover {
  // border: 1px solid #606060;
  border-color: #606060 !important;
}

.pay-form-check ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #2A2A2A;
  font-size: 16px !important;
}

.pay-form-check ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #1DB145;
  border-color: #1DB145;
}

.pay-form-check ::v-deep .el-checkbox__inner::after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  border: 2px solid #FFF;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 1px;
  width: 3px;
  -webkit-transition: -webkit-transform .15s ease-in .05s;
  transition: -webkit-transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.information-form-check ::v-deep .el-checkbox {
  color: #8F0304 !important;
}

.information-form-check ::v-deep .el-checkbox__label {
  font-size: 16px !important;
}

.information-form-check ::v-deep .el-checkbox__inner {
  border: 1px solid #8F0304 !important;
  width: 16px !important;
  height: 16px !important;
}

.information-form-check ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #8F0304 !important;
}

.information-form-check ::v-deep .el-checkbox__inner:hover {
  // border: 1px solid #606060;
  border-color: #8F0304 !important;
}

.information-form-check ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #8F0304;
  font-size: 16px !important;
}

.information-form-check ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #8F0304;
  border-color: #8F0304;
}

.information-form-check ::v-deep .el-checkbox__inner::after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  border: 2px solid #FFF;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 1px;
  width: 3px;
  -webkit-transition: -webkit-transform .15s ease-in .05s;
  transition: -webkit-transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.next-btn {
  line-height: 25px;
  background: #8F0304;
  font-weight: 600;
  font-size: 26px;
  height: 64px;
  width: 320px;
}

.next-btn-dis {
  line-height: 25px;
  background: #a6a6a6;
  border: #a6a6a6;
  font-weight: 600;
  font-size: 26px;
  height: 64px;
  width: 320px;
}

.next-btn-dis ::v-deep .el-button--danger.is-disabled, .el-button--danger.is-disabled:active, .el-button--danger.is-disabled:focus, .el-button--danger.is-disabled:hover {
  color: #FFF;
  background-color: #a6a6a6 !important;
  border-color: #a6a6a6 !important;
}

.box-payForm-card {
  margin-left: -15px;

  .txt {
    font-size: 8px;
    font-weight: 400;
    color: #525252;
    width: 200px;
    margin-left: -5px;
    margin-top: 10px
  }

  .img {
    width: 200px;
    margin-left: -5px
  }
}

.box-payForm-card ::v-deep .el-checkbox {
  color: #525252 !important;
}

.box-payForm-card ::v-deep .el-checkbox__inner {
  border: 1px solid #606060 !important;
}

.box-payForm-card ::v-deep .el-checkbox__input {
  margin-top: -45px;
}

.box-payForm-card ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #606060 !important;
}

.box-payForm-card ::v-deep .el-checkbox__inner:hover {
  // border: 1px solid #606060;
  border-color: #606060 !important;
}

.box-payForm-card ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #525252;
}

.box-payForm-card ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #606060;
  border-color: #606060;
}

.g-b {
  padding-top: 50px;
  padding-bottom: 0px;
}

.w-b {
  //padding: 0 140px !important;
  border-radius: 38px 38px 0 0;
}

.top-logo {
  width: 100%;
  //height: 250px;
  //margin-top: -30px;
  text-align: center;
}

.payment-valid-until {
  display: inline-block;
  vertical-align: middle;
  color: #9D2323;
  font-size: 16px;
  font-weight: 600;
}

.clock-img {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin: 1px 5px;
}

.tb-txt {
  display: inline-block;
  vertical-align: middle;
  color: #8F918E;
  font-size: 16px;
  font-weight: 600;
}

.or-information {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .title-24 {
    font-size: 24px;
    font-weight: 600;
    color: #03328D;
  }

  .title-16 {
    font-size: 16px;
    font-weight: 600;
    color: #03328D;
    line-height: 42px;
    margin-left: 10px;
  }

  .bk-img {
    width: 252px;
    height: 24px;
    margin-top: 16px;
    margin-left: 12px;
    position: absolute;
  }

  .mer-name-l {
    color: #8F918E;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    width: 210px;
  }

  .mer-name-r {
    color: #8F918E;
    font-size: 16px;
    font-weight: 400;
    margin-left: -10px;
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 210px)
  }

  .order-name {
    color: #2A2A2A;
    font-weight: 600;
    font-size: 26px;
    margin-top: 10px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    // width: calc(100% - 200px);
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  .order-amount-l {
    color: #8F918E;
    font-weight: 400;
    font-size: 16px;
    padding-top: 18px;
    border-top: 2px dashed #E3E3E3;
    width: 83%;
  }

  .order-amount-r {
    font-weight: 600
  }

  .pay-amount {
    color: #9D2323;
    font-weight: 600;
    font-size: 24px;
    margin-top: 10px;
  }
}

.pay-method {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .tit-area {
    display: flex;
  }

  .tit-cn {
    font-size: 24px;
    font-weight: 600;
    color: #03328D;
  }

  .tit-en {
    font-size: 16px;
    font-weight: 600;
    color: #03328D;
    line-height: 42px;
    margin-left: 10px;
  }

  .tit-bk {
    width: 292px;
    height: 24px;
    margin-top: 16px;
    margin-left: 12px;
    position: absolute;
  }

  .cre-card-img-1 {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    top: 3px;
  }

  .cre-card-img-2 {
    width: 26px;
    height: 25px;
    margin-left: 1px;
    top: 7px;
  }

  .cre-card-img-3 {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    top: 3px;
  }

  .gg-img {
    width: 50px;
    height: 26px;
    margin-left: 3px;
    top: 5px;
  }

  .ap-img {
    width: 47.2px;
    height: 30px;
    margin-left: 3px;
    top: 5px;
  }

  .su-img {
    width: 50px;
    height: 22px;
    margin-left: 3px;
    top: 6px;
  }

  .un-card {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    top: 3px;
  }

  .piiluu {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    top: 3px;
  }

  .n-card {
    width: 140px;
    height: 18px;
    margin-left: 3px;
    top: 3px;
  }
}

.pay-information {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .tit-area {
    display: flex
  }

  .tit-cn {
    font-size: 24px;
    font-weight: 600;
    color: #03328D;
  }

  .tit-en {
    font-size: 16px;
    font-weight: 600;
    color: #03328D;
    line-height: 42px;
    margin-left: 10px;
  }

  .tit-bg {
    width: 275px;
    height: 24px;
    margin-top: 16px;
    margin-left: 12px;
    position: absolute;
  }
}

@font-face {
  font-family: 'myFont';
  src: url('../assets/font/jost-black.ttf');
}

.self-font {
  //font-family: 'myFont', serif;
  font-size: 16px;
}

.tit-no {
  font-size: 16px;
  font-weight: 600;
  color: #C0C0C0;
  margin-top: 10px;
  position: absolute;
  right: 49%;
}

@media(max-width: 991px) {
  .tit-no {
    position: relative;
    right: 0;
    text-align: center;
  }
}


::v-deep .el-radio__label {
  font-size: 14px !important;
}

.paymentMethod {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.order-txt-center {
  width: calc(100% - 80px);
}

@media(max-width: 767px) {
  .paymentMethod {
    display: block;
    width: 100%;
  }
  .or-information .order-name {
    width: calc(100% - 100px) !important;
  }
  .order-txt-center {
    width: calc(100% - 40px);
  }
}

.paymentFormItem {
  display: inline-block;
  vertical-align: top;
  width: 48%;
}

.paymentFormItem2 {
  display: inline-block;
  vertical-align: top;
  width: 48%;
  margin-top: 12px;
}

.paymentFormItem ~ .paymentFormItem {
  margin-left: 2%;
}

@media(max-width: 991px) {
  .paymentFormItem {
    display: block;
    width: 100%;
  }
  .paymentFormItem2 {
    display: block;
    width: 100%;
  }
  .paymentFormItem ~ .paymentFormItem {
    margin-left: 0;
  }
  .recipientInfoChecked {
    margin-top: 20px !important;
  }
}

.information-form-check ::v-deep .box-txt-bl-b {
  //white-space: break-spaces;
  vertical-align: top;
}

.information-form-check ::v-deep .el-checkbox__input {
  vertical-align: top;
  margin-top: 4px;
}

.invoiceItem {
  display: inline-block;
  vertical-align: top;
  width: 48%;
}

.slide-box {
  width: 80%;
  margin: 30px auto;
  text-align: center;
}

.slide-box ::v-deep .el-carousel {
  &__button {
    height: 16px;
    width: 16px;
    border-radius: 10px;
    background-color: #999999;
  }

  &__indicator.is-active {
    .el-carousel__button {
      background-color: #9D2323;
    }
  }

  &__indicator--horizontal {
    padding: 20px 4px 12px 4px;
  }

  &__arrow {
    display: none !important;
  }

  &__item {
    background-color: #dddddd;
  }
}

.order-number-box {
  padding-bottom: 18px;

}

.order-number-title {
  font-size: 16px;
  color: #8F918E;
  margin-top: 10px;

  & .redText {
    color: #FF0000;
  }
}

::v-deep .order-number-input {
  display: inline-block;
  vertical-align: middle;
  width: 180px;
  height: 40px;
  background-color: #EDEDED;
  border-radius: 8px;
  padding: 0px 48px;
  box-sizing: border-box;
  position: relative;
  margin-top: 3px;

  & input {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    background: transparent;
  }

  .el-input .el-input__inner {
    padding: 0;
    border: 0;
    background: transparent;
    line-height: 40px;
    height: 40px !important;
    text-align: center;
  }
}

.num-sub {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 12px;
  top: 8px;
}

.num-add {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 12px;
  top: 8px;
}

.inventory-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: #C0C0C0;
  margin-left: 20px;
  margin-top: 3px;

}

.goods-desc {
  color: #525252;
  font-size: 16px;
  height: 36px;
  overflow: hidden;
}

::v-deep .priceInput {
  display: inline-block;
  width: 200px;

  input {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }
}

.identity-tips {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin: 20px 0px;
  line-height: 25px;
}

.identity-title {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 25px;
}

.identity-title2 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 25px;
}

///////////////

/* 基本樣式 */
.information-form-check {
  position: relative; /* 確保浮動視窗位置正確 */
}

/* 彈跳視窗內容 */
.popup-content {
  padding: 20px;
  font-size: 16px;
  background-color: #E0E0E0;
  color: #000000;
  border-radius: 5px;
  line-height: 1.4em;
}

.details,
.title {
  word-break: normal;
}

.fixed-tabs {
  margin: 15px 0;
}

.title {
  margin: 15px 0;
}

/* 中英文切換按鈕 */
.language-btn {
  width: 80px;
  height: 25px;
  background: #BEBEBE;
  border: #BEBEBE;
  font-size: 12px;
  font-weight: 600;
  border-radius: 25px;
  padding: 3px;
}

.language-btn:hover {
  background-color: #8F0304; /* 鼠標懸停時的背景色 */
}

/* 連結樣式 */
.link {
  color: #4F4F4F;
  text-decoration: underline;
  cursor: pointer;
}

/* 頁腳圖片樣式 */
.footer-img {
  max-width: 200px;
  width: 100%;
}

.numbered-list2 {
  padding-left: 13px; /* 調整編號與內容縮排 */
  text-indent: -0.84em; /* 確保編號與文本對齊 */
}

.cn-numbered-list {
  padding-left: 1.55em; /* 總共空出 1 個半形 + 1 個全形 */
  text-indent: -1.55em; /* 編號向左移動以與其他文本對齊 */
}

.numbered-list {
  padding-left: 1.25em; /* 調整編號與內容縮排 */
  text-indent: -1.25em; /* 確保編號不會被縮排，與其他文本對齊 */
}

@media (max-width: 1024px) {
  .popup-content {
    padding: 10px; /* 修改: 調整內邊距 */
  }
  .fixed-tabs h2 {
  }
  .language-btn {
    margin-bottom: 10px;
  }

  /* 頁腳樣式 */
  td {
    font-size: 14px;
  }
  .footer-img {
    max-width: 175px;
    width: 90%;
  }
  .pay-form-check-txt {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .convenienceStoreRadio {
    margin-top: 10px !important;
  }
  .chooseStore {
    margin-bottom: 30px !important;
  }
  .pay-form-check,
  .recipientInfoChecked2 {
    text-align: left !important;
  }
  .pay-form-check2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .popup-content {
    padding: 18px; /* 修改: 調整內邊距 */
  }
  /* 睜眼/閉眼樣式 */
  .card-image {
    margin-top: 5px;
  }
}


@media (max-width: 414px) {
  .popup-content {
    padding: 15px; /* 修改: 調整內邊距 */
  }
  .pay-form-check-txt {
    font-size: 14px;
  }
  /* 睜眼/閉眼樣式 */
  .card-image {
    margin-top: 12px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .le-el-input {
    max-width: calc(100% - 18%) !important;
  }
}

</style>
<style>
.jko-img {
  width: 80.2px;
  height: 29px;
  top: 12px;
}
.el-tabs .el-tabs__item {
  text-align: center; /* 使文字水平居中 */
  padding: 15px 20px; /* 調整內邊距 */
  line-height: 1; /* 設定行高以垂直居中 */
  font-size: 14px;
  border-radius: 2px;
}

.el-tabs .el-tabs__item.is-active {
  background-color: #8F0304 !important; /* 背景色 */
  color: white !important; /* 白色文字 */
  font-weight: bold; /* 設定文字為粗體 */
}

.el-tabs .el-tabs__item:not(.is-active) {
  color: grey; /* 非活動頁籤的文字顏色 */
}

.el-dialog {
  font-family: 'Roboto', sans-serif;
}

@media (max-width: 1024px) {
  .el-dialog {
    width: 85% !important; /* 當螢幕寬度小於1024px時 */
  }
}

</style>
