import { render, staticRenderFns } from "./google-pay-info.vue?vue&type=template&id=bd2d7c44&scoped=true"
import script from "./google-pay-info.vue?vue&type=script&lang=js"
export * from "./google-pay-info.vue?vue&type=script&lang=js"
import style0 from "./google-pay-info.vue?vue&type=style&index=0&id=bd2d7c44&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd2d7c44",
  null
  
)

export default component.exports